import React from 'react'

import css from './index.module.css'

import { IconNext, IconPrevious } from 'shared/components/Icon';

export function DropdownMenu(props) {
    if (props.menuName !== props.activeMenu) return null;

    const childrenWithProps = React.Children.map(props.children, child => {
        // Checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                calcHeight: props.calcHeight,
                setActiveMenu: props.setActiveMenu
            });
        }

        return child;
    });

    return (
        <div className={css.menu}>
            {childrenWithProps}
        </div>
    );
}

export function DropdownItem(props) {
    const dropdownRef = React.useRef(null);

    React.useEffect(() => {
        if (dropdownRef.current) {
            props.calcHeight(dropdownRef.current);
        }
    }, [props, dropdownRef]);

    return (
        <button className="secondary" onClick={() => props.goToMenu && props.setActiveMenu(props.goToMenu)}>
            {props.children}
            {props.goToMenu === 'main' ? <IconPrevious height={20} widht={20} /> : <IconNext height={20} widht={20} />}
        </button>
    );
}

export default function Dropdown(props) {
    const [activeMenu, setActiveMenu] = React.useState('main');
    const [menuHeight, setMenuHeight] = React.useState(null);
    const dropdownRef = React.useRef(null);

    const childrenWithProps = React.Children.map(props.children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                calcHeight: calcHeight,
                activeMenu: activeMenu,
                setActiveMenu: setActiveMenu
            });
        }

        return child;
    });

    React.useEffect(() => {
        setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
    }, [])

    if (!props.open) return null;

    function calcHeight(el) {
        const height = el.height;
        setMenuHeight(height);
    }

    return (
        <div className={css.dropdown} style={{ height: menuHeight }} ref={dropdownRef}>
            {childrenWithProps}
        </div>
    );
}
