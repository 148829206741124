import React from 'react'

import css from './index.module.css'

import { CloseButton } from 'shared/components/Button'

export default function Slide({ open, children, onClose = null, title = null }) {
  const [stage, setStage] = React.useState(-1);

  if (open && stage !== 1) setStage(1);
  if (!open && stage === 1) { setStage(2); setTimeout(() => { setStage(-1); }, 150) }
  if (!open && stage === -1) return null;

  return (
    <>
      <div className={css.container + ((stage === 2) ? (' ' + css.closing) : '')}>
        <CloseButton onClose={onClose} />
        <div className={css.content}>
            <div className={css.header}>
                <span>{title}</span>
            </div>
            <div className={css.body}>
                {children}
            </div>
        </div>
      </div>
    </>
  )
}

export function SlideWrapper({ children }) {
  return (
    <div className={css.wrapper}>
      {children}
    </div>
  );
}

export function SlideClose(props) {
  if (props.onClose == null) return null;

  return (
    <div className={css.close}>
      <button onClick={props.onClose}>
        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" /></svg>
      </button>
    </div>
  );
}
