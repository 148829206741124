import React from 'react'

import { Message } from 'shared/hooks/Translation'

import Modal, { ModalHeader, ModalBody, ModalFooter } from 'shared/components/Modal'

export const ModalDelete = React.memo((props) => {
    return (
        <Modal modalStyle='small' open={props.open} onClose={() => { props.setOpen(false) }}>
            <ModalHeader><span><Message id="modal.deleteProject" /></span></ModalHeader>
            <ModalBody>
                <div><b>{props.name}</b></div>
                <span><Message id="modal.deleteProject.text" /></span>
            </ModalBody>
            <ModalFooter>
                <button className="primary warning" onClick={props.confirm}><Message id="button.deleteProject" /></button>
                <button className="clear" onClick={() => { props.setOpen(false) }}><Message id="button.cancel" /></button>
            </ModalFooter>
        </Modal>
    );
});
