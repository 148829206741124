export const addSelfDestructingEventListener = (element, eventType, callback) => {
    let handler = () => {
        callback();
        element.removeEventListener(eventType, handler);
    };
    element.addEventListener(eventType, handler);
};

export const addEventListener = (element, eventType, callback) => {
    element.addEventListener(eventType, callback, false);
};

export const removeEventListener = (element, eventType, callback) => {
    element.removeEventListener(eventType, callback, false);
};