import React from 'react'
import ReactDom from 'react-dom'
import { useHistory } from "react-router-dom"

import css from './index.module.css'

import { Message, LanguageContext } from 'shared/hooks/Translation'

import { IconTrash } from 'shared/components/Icon'
import { ConfirmButton } from 'shared/components/Button'

import { subscribeStatusUpdate, callStopRender, callStopSubtitles } from 'shared/hooks/Api'

export default function Monitor({ open, setOpen }) {
  const { userLanguageChange, userLanguage  } = React.useContext(LanguageContext);
  const [stage, setStage] = React.useState(-1);
  if (open && stage !== 1) setStage(1);
  if (!open && stage === 1) { setStage(2); setTimeout(() => { setStage(-1); }, 150) }
  if (!open && stage === -1) return null;

  const changeLanguage = (e) => {
    userLanguageChange(e.target.value);
  }

  return ReactDom.createPortal(
    <>
      <div className={css.wrapper}>
        <div className={css.container + ((stage === 2) ? (' ' + css.closing) : '')}>
          <Queue />
          <select className={css.select} onChange={changeLanguage} value={userLanguage}>
            <option value="de">Deutsch</option>
            <option value="en">English</option>
          </select>
        </div>
      </div>
    </>,
    document.getElementById('root')
  )
}

export class Queue extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          encoder: {
            jobs: [],
            queue: []
          },
          transcriber: {
            jobs: [],
            queue: []
          }
        }
    }

    componentDidMount() {
      this.subscription = subscribeStatusUpdate((report) => {
        this.setState({
          encoder: {
            jobs: (report.encoder && report.encoder.jobs) ?? [],
            queue: (report.encoder && report.encoder.queue) ?? []
          },
          transcriber: {
            jobs: (report.transcriber && report.transcriber.jobs) ?? [],
            queue: (report.transcriber && report.transcriber.queue) ?? []
          }
        });
      });
    }

    componentWillUnmount() {
        if (this.subscription != null) this.subscription.close();
    }

    render() {
        const all = [...this.state.encoder.jobs, ...this.state.encoder.queue, ...this.state.transcriber.jobs, ...this.state.transcriber.queue];

        const encoderJobs = this.state.encoder.jobs.map((job, index) =>
            <QueueItem job={job} key={index} type='encoder'/>
        );
        const encoderQueue = this.state.encoder.queue.map((job, index) =>
            <QueueItem job={job} key={index} type='encoder'/>
        );
        const transcriberJobs = this.state.transcriber.jobs.map((job, index) =>
            <QueueItem job={job} key={index} type='transcriber'/>
        );
        const transcriberQueue = this.state.transcriber.queue.map((job, index) =>
            <QueueItem job={job} key={index} type='transcriber'/>
        );

        if (all.length <= 0) {
            return (
                <div className={css.queueContent}>
                    <div className={css.queueEmpty}>
                        <div className={css.queueEmptyContent}>
                            <span><Message id="folder.background.empty"/></span>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={css.queueContent}>
                <div className={css.queueItems}>
                    {encoderJobs}
                    {encoderQueue}
                    {transcriberJobs}
                    {transcriberQueue}
                </div>
            </div>
        );
    }
}
export const QueueItem = React.memo((props) => {
    const history = useHistory();
    var type = "";

    if (props.type === 'transcriber') type = "Transcribing";
    if (props.type === 'encoder') type = "Encoding";

    const toProject = () => {
      if (props.type === 'transcriber') history.push('/project/' + props.job.name);
      if (props.type === 'encoder') history.push('/download/' + props.job.name);
    }

    const cancelProcess = () => {
      if (props.type === 'transcriber') {
        callStopSubtitles(props.job.name, () => {

        });
      }

      if (props.type === 'encoder') {
        callStopRender(props.job.name, () => {

        });
      }
    }

    return (
        <>
          <div className={css.queueItem}>
                <div className={css.queueLeft}>
                  <div className={css.queueItemName} onClick={toProject}>
                      <span>{props.job.name}</span>
                      <span>{type}</span>
                  </div>
                  <div className={css.queueItemProgress}>
                      <div className={css.queueItemProgressBarWrapper}>
                          <div className={css.queueItemProgressBar + (props.job.percentage >= 100 ? (' ' + css.striped) : ('') )} style={{ width: props.job.percentage + "%" }}></div>
                      </div>
                  </div>
                </div>
                <div className={css.queueRight}>
                  <div className={css.queueItemButton}>
                    <ConfirmButton handleClick={ () => { cancelProcess() }}>
                      <IconTrash />
                    </ConfirmButton>
                  </div>
                </div>
          </div>
        </>
    );
});
