

export const extractLast = (url) => {
  const srcParams = url.split("/");
  return srcParams.length > 0 ? srcParams[srcParams.length - 1] : url;
}

export const replaces = (input, strReplace, strWith) => {
  var esc = strReplace.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
  var reg = new RegExp(esc, 'ig');
  return input.replace(reg, strWith);
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = (c === 'x' ? r : ((r & 0x3) | 0x8));
    return v.toString(16);
  });
}
