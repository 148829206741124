import React from 'react'
import Layout from './Layout'

export const MediaContext = React.createContext();

export default class ProjectMedia extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            media: []
        }

        this.videoRef = React.createRef(null);

        // Project function that can be passed to other components
        this.mediaFunctions = {

        }
    }

    // Load the videos
    componentDidMount() {

    }

    // Unload the videos
    componentWillUnmount() {

    }

    render() {
        const media = this.state.media;
        const mediaFunctions = this.mediaFunctions;

        return (
            <>
                <MediaContext.Provider value={{ media, mediaFunctions }}>
                    <Layout
                        videoRef={this.videoRef} percentages={this.props.percentages} instruction={this.props.instruction} queue={this.props.queue}
                    />
                </MediaContext.Provider>
            </>
        );
    }
}
