import React from 'react'

import css from './Layout.module.css'

import Toolbar from './Toolbar'
import Preview from './Preview'
import PreviewToolbar from './Preview/Toolbar'
import Sheets from './Sheet'
import Timelines from './Timeline'

import { IconSpinner } from 'shared/components/Icon'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from './index'
import { MediaContext } from './Media'

import { ELEMENTS } from 'shared/constants/nav'

const Layout = React.memo((props) => {
    const { options } = React.useContext(ProjectContext);
    const [navigation, setNavigation] = React.useState(ELEMENTS.SUBTITLES);

    const sheet = navigation;
    const setSheet = (sheet) => {
        setNavigation(navigation === sheet ? null : sheet);
        props.options.loopHighlight = (sheet === ELEMENTS.HIGHLIGHT);
    }

    if (options.mode === 'DOCUMENT') {
      return (
          <>
            <div className={css.container}>
                <div className={css.navigation}>
                    <Toolbar
                        sheet={sheet}
                        setSheet={setSheet}
                        navigation={navigation}
                        setNavigation={setNavigation}
                    />
                </div>
                <div className={css.content}>
                    <div className={css.viewport}>
                        <div className={css.preview}>
                            <PreviewToolbar
                              saveRef={props.saveRef}
                            />
                            <ProjectContext.Consumer>
                                {project => (
                                    <MediaContext.Consumer>
                                        {media => (
                                            <Preview
                                                project={project}
                                                media={media}
                                                canvasRef={props.canvasRef}
                                                subtitleRef={props.subtitleRef}
                                                previewRef={props.previewRef}
                                                sheet={sheet}
                                            />
                                        )}
                                    </MediaContext.Consumer>
                                )}
                            </ProjectContext.Consumer>
                        </div>
                    </div>
                    <div className={css.timeline + ' ' + css.timelineAuto}>
                        <Timelines
                            navigation={navigation}
                            setNavigation={setNavigation}
                            timeRef={props.timeRef}
                            timelineRef={props.timelineRef}
                            markerRef={props.markerRef}
                        />
                    </div>
                </div>
            </div>
          </>
      );
    }

    return (
        <>
          <div className={css.container}>
              <div className={css.navigation}>
                  <Toolbar
                      sheet={sheet}
                      setSheet={setSheet}
                      navigation={navigation}
                      setNavigation={setNavigation}
                  />
              </div>
              <div className={css.content}>
                  <div className={css.viewport}>
                      <div className={css.sheet}>
                          <Sheets
                              sheet={sheet}
                              setSheet={setSheet}
                              listRef={props.listRef}
                          />
                      </div>
                      <div className={css.preview}>
                          <PreviewToolbar
                            saveRef={props.saveRef}
                          />
                          <Processor />
                          <ProjectContext.Consumer>
                              {project => (
                                  <MediaContext.Consumer>
                                      {media => (
                                          <Preview
                                              project={project}
                                              media={media}
                                              canvasRef={props.canvasRef}
                                              subtitleRef={props.subtitleRef}
                                              previewRef={props.previewRef}
                                              sheet={sheet}
                                          />
                                      )}
                                  </MediaContext.Consumer>
                              )}
                          </ProjectContext.Consumer>
                      </div>
                  </div>
                  <div className={css.timeline}>
                      <Timelines
                          navigation={navigation}
                          setNavigation={setNavigation}
                          timeRef={props.timeRef}
                          timelineRef={props.timelineRef}
                          markerRef={props.markerRef}
                      />
                  </div>
              </div>
          </div>
        </>
    );
});

const Processor = React.forwardRef((props, ref) => {
  const { project } = React.useContext(ProjectContext);

  if (!project.isInitializing()) return (<div className={css.process}></div>);

  return (<div className={css.process}><IconSpinner height="20" width="20" /><Message id="spinner.processing" /></div>);
});

export default Layout;
