import css from './index.module.css'

export function Spinner({width = 15, height = 15}) {
    return (<svg className={css.spinner} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><circle cx="50" cy="50" r="45"/><circle cx="50" cy="50" r="45"/></svg>);
}

export function Listener({idle = true, width = 15, height = 15}) {
    return (
      <div className={css.listener + ' ' + (idle ? css.waiting : css.working)} width={width} height={height}>
        <div className={css.inner}>
          <div className={css.bar}></div>
          <div className={css.bar}></div>
          <div className={css.bar}></div>
        </div>
      </div>
  );
}
