const DEFAULT_THUMBNAILS = [5, 10, 15, 30, 40, 60, 80];

export const syncProject = (json, newJson) => {
  const copy = {...json};

  if (newJson.subtitles) copy.subtitles = newJson.subtitles;
  if (newJson.text) copy.text = newJson.text;
  if (newJson.elements) copy.elements = newJson.elements;
  if (newJson.connections) copy.connections = newJson.connections;
  if (newJson.status) copy.status = newJson.status;
  if (newJson.renderUrls) copy.renderUrls = newJson.renderUrls;
  if (newJson.thumbnailUrls) copy.thumbnailUrls = newJson.thumbnailUrls;
  if (newJson.transcriber) copy.transcriber = newJson.transcriber;
  if (newJson.renderer) copy.renderer = newJson.renderer;
  if (newJson.highlight) copy.highlight = newJson.highlight;
  if (newJson.thumbnails) copy.thumbnails = newJson.thumbnails;
  if (newJson.alternative) copy.alternative = newJson.alternative;

  if (json.subtitles && newJson.subtitles) {
    for (let i = 0, il = json.subtitles.length; i < il; i++) {
      const sub = json.subtitles[i];

      for (let j = 0, jl = newJson.subtitles.length; j < jl; j++) {
        const newSub = newJson.subtitles[j];

        if (sub.uuid === newSub.uuid) {
          // TODO: Merge
        }
      }
    }
  }

  return copy;
}

export const upsert = (array, item) => {
  const i = array.findIndex(_item => _item.uuid === item.uuid);
  if (i > -1) array[i] = item;
  else array.push(item);
}

export const createProject = (json) => {
  try {
    var thumbnails = [];

    if (json.fileInformation && json.fileInformation.format && json.fileInformation.format.duration) {
      const duration = json.fileInformation.format.duration;

      for (let i = 0, il = DEFAULT_THUMBNAILS.length; i < il; i++) {
        if (DEFAULT_THUMBNAILS[i] < duration) thumbnails.push({time: DEFAULT_THUMBNAILS[i]});
      }
    }

    if (json.thumbnails != null && json.thumbnails.length > 0) {
      thumbnails = json.thumbnails;
    }

    const duration = (json.fileInformation && json.fileInformation.format && json.fileInformation.format.duration) ? json.fileInformation.format.duration : 0;
    const size = (json.fileInformation && json.fileInformation.format && json.fileInformation.format.size) ? json.fileInformation.format.size : 0;
    const streams = json.fileInformation.streams;

    const project = {
      name: json.name || 'N/A',
      nameInfo: extraInformation(json.name || ''),
      upload: [json.file || ''],
      thumbnail: json.thumbnail || '',
      thumbnails: thumbnails,
      thumbnailUrls: json.thumbnailUrls || [],
      subtitles: validateSubtitles(convertToUniqueArray(json.subtitles)),
      text: convertToUniqueArray(json.text),
      elements: convertToUniqueArray(json.elements),
      renderUrls: json.renderUrls || [],
      saved: true,
      status: json.status || 'UNKNOWN',
      statusAt: json.statusAt || 0,
      createAt: json.createAt || 0,
      modifiedAt: json.modifiedAt || 0,
      connections: json.connections || [],
      transcriber: json.transcriber || {},
      renderer: json.renderer || {},
      highlight: json.highlight || { start: 0, end: 0 },
      alternative: json.alternative || "",
      fileInformation: {
        format: {
          duration: duration,
          size: size,
          streams: streams
        }
      },
      isRendered() {
        return this.status === 'RENDERED';
      },
      isProcessing() {
        return this.status.indexOf('ING') !== -1;
      },
      isDraft() {
        return this.status === 'DRAFT';
      },
      isInitializing() {
        return this.status === 'PROCESSING';
      }
    }

    return project;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const calculcateConfidence = (subtitles) => {
  var confidence = 0;

  if (subtitles && subtitles.length > 0) {
    for (let i = 0, il = subtitles.length; i < il; i++) confidence += subtitles[i].confidence;
    confidence /= subtitles.length;
  }

  return confidence;
}

export const extraInformation = (name) => {
  const args = name.split("_");

  const getDate = (args) => {
    for (let i = 0, il = args.length; i < il; i++) {
      const arg = args[i];
      if (parseInt(arg) !== arg) continue;
      if (arg.length !== 8) continue;
      return { arg: arg, year: arg.substring(0, 4), month: arg.substring(4, 6), day: arg.substring(6, 8) }
    }
  }

  const getLogo = (args) => {
    for (let i = 0, il = args.length; i < il; i++) {
      const arg = args[i];
      if (arg.length <= 5) continue;
      if (arg.indexOf('logo-') === -1) continue;
      return { arg: arg, logo: arg.replace('logo-', '') };
    }
  }

  const getDuration = (args) => {
    for (let i = 0, il = args.length; i < il; i++) {
      const arg = args[i];
      if (arg.length <= 2) continue;
      if (arg.substring(0, 1) !== 'd') continue;
      return { arg: arg, seconds: parseInt(arg.substring(1)) };
    }
  }

  var text = name.replaceAll('_', ' ');

  var date = getDate(args);
  if (date != null) text = text.replace(date.arg, '');

  var logo = getLogo(args);
  if (logo != null) text = text.replace(logo.arg, '');

  var duration = getDuration(args);
  if (duration != null) text = text.replace(duration.arg, '');

  text = text.trim();
  return { date, text, logo, duration, name };
}

export const validateSubtitles = (array) => {
  if (array == null || !Array.isArray(array)) return [];
  const subtitles = [...array];

  for (let i = subtitles.length - 1; i >= 0; i--) {
    const subtitle = subtitles[i];
    if (subtitle.text == null || subtitle.start == null || subtitle.end == null) subtitles.splice(i, 1);
  }

  return subtitles;
}

export const convertToUniqueArray = (array) => {
  if (array == null) return [];
  for (let i = 0, il = array.length; i < il; i++) if (array[i].uuid == null) array[i].uuid = uuidv4();
  return array;
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}
