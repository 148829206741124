var y;
export const generateThumbnailId = () => {
    return Math.random().toString(16).slice(-12)
  }

  export const addThumbnail = (thumbnails, index) => {
    const thumbs = [...thumbnails];

    var time = 0;

    if (thumbnails.length > 0) {
        time = parseFloat(thumbnails[thumbnails.length - 1].time) + 1;
    }

    thumbs.push(createThumbnail(time));
    return sortThumbnails(thumbs);
}

export const extractThumbnails = (thumbnails) => {
  try {
    const jsonThumbnails = [];

    for (let i = 0, il = thumbnails.length; i < il; i++) {
      const thumbnail = thumbnails[i];
      jsonThumbnails.push({
        time: thumbnail.time,
      });
    }

    return jsonThumbnails;
  } catch (error) {
    return [];
  }
}

export const validateThumbnail = (thumbnails, index, time, image, yCoordinate) => {
    if (time <= 0.1) time = 0.1;

    if (index > 0 && thumbnails.length > 0) {
        const prev = thumbnails[index - 1];

        if (time <= prev.time) {
            time = prev.time + 0.1;
        }
    }

    if ((index + 1) < thumbnails.length) {
        const next = thumbnails[index + 1];

        if (time >= next.time) {
            time = next.time - 0.1;
        }
    }

    return {time, image, yCoordinate};
}

export const insertThumbnail = (thumbnails, time) => {
    const thumbs = [...thumbnails];
    thumbs.push(createThumbnail(time));
    return sortThumbnails(thumbs);
}

export const removeThumbnail = (thumbnails, index) => {
    const thumbs = [...thumbnails];
    if (index >= 0 && index < thumbnails.length) thumbs.splice(index, 1);
    return sortThumbnails(thumbs);
}

export const createThumbnail = (time = 0, uuid = generateThumbnailId()) => {
    return {
        uuid: uuid,
        time: time,
        y: y
    }
}

export const sortThumbnails = (thumbnails) => {
    const thumbs = [...thumbnails];
    thumbs.sort((a, b) => {
        if (a.time < b.time) return -1;
        if (a.time > b.time) return 1;
        return 0;
    });
    return thumbs;
}


export const getCurrentY = (yCoordinate) => {
    if(yCoordinate !== undefined){
        y=yCoordinate
    }
}
