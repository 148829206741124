import React from 'react'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Project'

import { IconSpinner } from 'shared/components/Icon'

const PreviewToolbar = React.memo((props) => {
  const { project } = React.useContext(ProjectContext);

  return (
    <>
      <div className={css.wrapper}>
        <div className={css.left}>
          <input defaultValue={project.name} disabled></input>
          <Saver ref={props.saveRef}/>
        </div>
      </div>
    </>
  );
});

export default PreviewToolbar;

const Saver = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{opacity: 0}} className={css.saver}>
        <IconSpinner />
        <span><Message id="status.saving" /></span>
    </div>
  );
});
