import React from 'react';

import Layout from './Layout'

export default class Dashboard extends React.Component {
    render() {
        return (
            <>
                <Layout />
            </>
        );
    }
}
