import React from 'react'

import css from './index.module.css'

import { ProjectContext } from 'App/pages/Project'
import { MediaContext } from 'App/pages/Project/Media'
import { WidthContext } from 'App/pages/Project/Timeline'

const TimelineAudio = React.memo((props) => {
    const { project } = React.useContext(ProjectContext);
    const { media } = React.useContext(MediaContext);
    const { width } = React.useContext(WidthContext);
    const canvasRef = React.useRef(null);

    React.useEffect(() => {
        const draw = (context) => {
            context.strokeStyle = "rgb(142, 142, 147)";

            for (let i = 10; i < width - 10; i += 5) {
                //const r = Math.random() * 10;
                //context.beginPath();
                //context.lineWidth = "2";
                //context.moveTo(i, 10 + r);
                //context.lineTo(i, 32 - r);
                //context.stroke();
            }

            /*
            var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
						var audioElement = media[0];
						audioElement.crossOrigin = "anonymous";

						var audioSrc = audioCtx.createMediaElementSource(audioElement);
						var analyser = audioCtx.createAnalyser();
						audioSrc.connect(analyser);
						audioSrc.connect(audioCtx.destination);

						media.onprogress = (e) => {
							var frequencyData = new Uint8Array(800);
							var timeData = new Uint8Array(800);
							analyser.getByteFrequencyData(frequencyData);
							analyser.getByteTimeDomainData(timeData);
							console.log(frequencyData);
						}
            */
        }

        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        context.clearRect(0, 0, canvas.width, canvas.height);
        draw(context);
    }, [width]);

    return (
        <div className={css.video} style={{ width: width + "px" }}>
            <div className={css.videoDuration}>{(media.length > 0 && media[0].duration) ? media[0].duration : 0}s</div>
            <div className={css.videoName}>
                <svg width="16px" height="16px" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M511.99 32.01c0-21.71-21.1-37.01-41.6-30.51L150.4 96c-13.3 4.2-22.4 16.5-22.4 30.5v261.42c-10.05-2.38-20.72-3.92-32-3.92-53.02 0-96 28.65-96 64s42.98 64 96 64 96-28.65 96-64V214.31l256-75.02v184.63c-10.05-2.38-20.72-3.92-32-3.92-53.02 0-96 28.65-96 64s42.98 64 96 64 96-28.65 96-64l-.01-351.99z" /></svg>
                <span>{project.name}</span>
            </div>
            <canvas ref={canvasRef} width={width + "px"} height="40px" />
        </div>
    );
});

export default TimelineAudio;
