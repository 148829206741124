import React from 'react'

import css from './index.module.css'

import { Message, LanguageContext } from 'shared/hooks/Translation'

import { Spinner } from 'shared/components/Button'
import { IconSearch, IconNext, IconPrevious } from 'shared/components/Icon'
import Tab, { TabItem } from 'shared/components/Tab'

import { Dropzone } from './Dropzone'
import { ProjectWrapper, ProjectTile, ProjectBar } from './Project'
import { FolderWrapper, Folder } from './Folder'

import { subscribeLoadProjects } from 'shared/hooks/Api'

export const ELEMENTS = {
    VIDEO: "Videos",
    AUDIO: "Audios",
}

export const PAGE_SIZES = [10, 25, 50, 100, 250, 500];

export default class Browser extends React.Component {
    constructor(props) {
        super(props);

        this.folderRef = React.createRef(null);

        var page = 1;
        var size = 10;

        try {
          const params = new URL(document.location.href).searchParams;
          const paramsPage = params.get('page');
          page = Math.max(1, paramsPage);
          const paramsSize = params.get('size');
          size = Math.max(1, paramsSize);

          // Get the closest valid size
          const closestSize = PAGE_SIZES.reduce((a, b) => {
              return Math.abs(b - size) < Math.abs(a - size) ? b : a;
          });

          // Check if the user input is valid to the closest size
          if (size !== closestSize) {
            size = closestSize; // Correct the invalid size
            window.history.replaceState( {} , 'Dashboard Page', '?page=' + page+ "&size=" + (size)); // Update the url
          }

        } catch (error) {
          console.log(error);
        }

        this.state = {
            sort: 'create',
            search: null,
            projects: null,
            projectsTotal: 0,
            page: page,
            pageSize: size
        }

        this.updateProject = (data) => {
            this.setState(prevState => ({
                ...prevState,
                project: {
                    ...prevState.project,
                    ...data
                }
            }));
        }
        this.setSearch = (search) => {
            this.setState({ search: search });
        }
        this.setSort = (sort) => {
            this.setState({ sort: sort }, () => {
              this.load();
            });
        }
        this.setPageSize = (pageSize) => {
            window.history.replaceState( {} , 'Dashboard Page', '?page=' + this.state.page + "&size=" + (pageSize));
            this.setState({ pageSize: pageSize }, () => {
              this.load();
            });
        }
        this.reload = () => {
            this.load();
        }
    }

    load() {
        this.unload();

        this.subscription = subscribeLoadProjects(this.props.type,
            (json) => {
                if (json == null) return;

                if (json.results != null && Array.isArray(json.results)) {
                    this.setState({ projects: json.results, projectsTotal: json.projects });
                } else {
                    if (this.state.projects == null) return;
                    var projs = this.state.projects;
                    var projReplaced = false;

                    for (var i = 0, il = projs.length; i < il && !projReplaced; i++) {
                        const index = i;
                        if (projs[index].name === json.name) {
                            projs[index] = { ...projs[index], ...json };
                            projReplaced = true;

                            // Only update the project that has been sent
                            const projects = this.state.projects.map((obj, j) => j === index ? { ...projs[index], ...json.data } : obj);
                            this.setState({ projects });
                        }
                    }
                }
            }, this.state.sort, this.state.page, this.state.pageSize
        );
    }

    unload() {
        if (this.subscription != null) this.subscription.close();
    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        this.unload();
    }

    render() {
        const projectList = getProjects(this.state.projects, this.state.sort, this.state.search);
        const project = projectList.map((project, index) =>
            <ProjectTile key={index} project={project} reload={() => this.reload()}></ProjectTile>
        );

        const renderList = getRenders(this.state.projects);
        const render = renderList.map((render, index) =>
            <Folder key={index} render={render}></Folder>
        );

        const left = (ref) => {
            if (ref && ref.current != null) ref.current.scrollLeft -= 290;
        }
        const right = (ref) => {
            if (ref && ref.current != null) ref.current.scrollLeft += 290;
        }

        return (
            <div className={css.content}>
                <Dropzone />
                <div className={css.tabs}>
                    <Tab>
                        <TabItem active={this.props.page === ELEMENTS.VIDEO} handleClick={() => this.props.setPage(ELEMENTS.VIDEO)}>
                            <span><Message id='tab.video' /></span>
                        </TabItem>
                        <TabItem active={this.props.page === ELEMENTS.AUDIO} handleClick={() => this.props.setPage(ELEMENTS.AUDIO)}>
                            <span><Message id='tab.audio' /></span>
                        </TabItem>
                    </Tab>
                </div>
                <div className={css.title}>
                    <div className={css.titleLeft}>
                        <span><Message id='folder.recentExported' /></span>
                        <span className={css.titleInfo}><Message id='folder.recentExported.expire' /></span>
                    </div>
                    <div className={css.titleRight}>
                        <button onClick={() => left(this.folderRef)}><IconPrevious /></button>
                        <button onClick={() => right(this.folderRef)}><IconNext /></button>
                    </div>
                </div>
                <div className={css.listWrapper}>
                    <FolderWrapper folderRef={this.folderRef}>
                        {this.state.projects == null ? <Spinner /> : (renderList.length > 0 ? render : <Placeholder><span><Message id='folder.recentExported.empty' /></span></Placeholder>)}
                    </FolderWrapper>
                </div>
                <div className={css.browserWrapper}>
                    <FilterBar pageSize={this.state.pageSize} setPageSize={this.setPageSize} setSearch={this.setSearch} setSort={this.setSort} />
                    <div className={css.listWrapper}>
                        <ProjectBar />
                        <ProjectWrapper>
                            {this.state.projects == null ? <Spinner /> : (projectList.length > 0 ? project : <Placeholder><span><Message id="folder.projects.empty" /></span></Placeholder>)}
                        </ProjectWrapper>
                        <LoaderBar projects={this.state.projects} projectsTotal={this.state.projectsTotal} pageSize={this.state.pageSize} page={this.state.page} setPage={(page) => { this.setState({page: page}, () => { this.load(); }); }} />
                    </div>
                </div>
            </div>
        );
    }
}

export const LoaderBar = React.memo((props) => {
  const min = props.projects == null ? 0 : props.projects.length;
  const max = props.projectsTotal;
  const percentage = min / max * 100;

  const loadMore = () => {
    window.history.replaceState( {} , 'Dashboard Page', '?page=' + (props.page + 1) + "&size=" + (props.pageSize));
    props.setPage(props.page + 1);
  }

  return (
    <div className={css.loader}>
      <div className={css.loaderCount}> Zeigt {min} von {max}</div>
      <div className={css.loaderProgress}><div className={css.loaderProgressBar} style={{width: percentage + "%"}}></div></div>
      {min === max ? null : <div className={css.loaderButton} onClick={loadMore}>Mehr anzeigen</div>}
    </div>
  )
});

export const FilterBar = ({ children, pageSize, setPageSize, setSearch, setSort }) => {
    const { message } = React.useContext(LanguageContext);

    const pageSizeOptions = PAGE_SIZES.map((size) => {
      return(
        <option key={size} value={size}>{size}</option>
      )
    });

    return (
        <>
            <FilterWrapper>
                <FilterLeft>
                    <div className={css.input}>
                        <IconSearch width={20} height={15} />
                        <input placeholder={message('project.header.search')} onChange={(e) => { setSearch(e.target.value) }}></input>
                    </div>
                </FilterLeft>
                <FilterRight>
                    <select onChange={(e) => { setSort(e.target.value) }}>
                        <option value="create" default>{message('project.header.sort.create')}</option>
                        <option value="newest">{message('project.header.sort.newest')}</option>
                        <option value="alphabet">{message('project.header.sort.alphabet')}</option>
                    </select>
                    <select onChange={(e) => { setPageSize(parseInt(e.target.value)) }} value={pageSize}>
                        {pageSizeOptions}
                    </select>
                </FilterRight>
            </FilterWrapper>
        </>
    )
};


export const Placeholder = ({ children }) => {
    return (<div className={css.placeholder}>{children}</div>)
};

const getRenders = (projectList) => {
    if (projectList == null) return [];
    const projects = [...projectList];

    projects.sort((a, b) => {
        if (a.encoder != null && a.encoder.percentage != null) return -1;
        if (b.encoder != null && b.encoder.percentage != null) return 0;
        if (a.encoder != null && a.encoder.position != null) return -1;
        if (b.encoder != null && b.encoder.position != null) return 0;

        return b.renderAt - a.renderAt;
    });

    for (let i = projects.length - 1; i >= 0; i--) {
        if (projects[i].status && projects[i].status.indexOf("REND") === -1) {
            projects.splice(i, 1);
        }
    }

    return projects;
}

const getProjects = (projectList, sort, search) => {
    if (projectList == null) return [];
    const projects = [...projectList];

    if (search !== null && search.length > 0) {
        for (let i = projects.length - 1; i >= 0; i--) {
            if (projects[i].name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                projects.splice(i, 1);
            }
        }
    }

    return projects;
}

export const FilterWrapper = ({ children }) => {
    return (<div className={css.filterWrapper}>{children}</div>)
};

export const FilterLeft = ({ children }) => {
    return (<div className={css.filterLeft}>{children}</div>)
};

export const FilterRight = ({ children }) => {
    return (<div className={css.filterRight}>{children}</div>)
};
