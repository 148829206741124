import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Project'

import { IconBurnLogo, IconBurnSubtitles, IconRenderLQ, IconRenderSQ, IconRenderHQ, IconMail, IconSetThumbnail, IconHighlighter, IconPlus, IconHighlight, IconFolder } from 'shared/components/Icon'
import { ProcessButton, Box, Group, Check, Collapse, LabeledIcon, Description, AdditionalContent, InputWrapper, Input, FileInput, Select, Option, InputLabel } from 'shared/components/Button'
import Slide from 'shared/components/Slide'

import { ModalWarning } from 'App/pages/Project/modals/ModalWarning'

import { callRenderProject } from 'shared/hooks/Api'

import { bytesToSize } from 'shared/utils/math'
import { formatTime } from 'shared/utils/time'

import { ELEMENTS } from 'shared/constants/nav'

export default function Exports(props) {
    const { project } = React.useContext(ProjectContext);

    useEffect(() => {
        if (project.fileInformation.format.streams && project.fileInformation.format.streams.length > 0) {
            for (let i = 0, il = project.fileInformation.format.streams.length; i < il; i++) {
                let stream = project.fileInformation.format.streams[i];

                let width, height;

                if (stream.side_data_list && Math.abs(stream.side_data_list[0].rotation)) {
                    if (stream.width && stream.height) {
                        width = stream.height;
                        height = stream.width;
                    }
                } else if (stream.width && stream.height) {
                    width = stream.width;
                    height = stream.height;
                }

                if ( width > height ){
                    setOptionLandscape(true)
                } else if ( width < height ){
                    setOptionPortrait(true)
                }
                if ( project.subtitles.length > 0 ){
                    setBurnSubtitle(true)
                } else{
                    setBurnSubtitle(false)
                }
            }
        }
    }, [project.fileInformation.format.streams, project.subtitles.length])

    const [warning, setWarning] = React.useState(false);
    const [warningId, setWarningId] = React.useState(0);
    const [optionLandscape, setOptionLandscape] = React.useState(false);
    const [optionPortrait, setOptionPortrait] = React.useState(false);
    const [optionLQ, setOptionLQ] = React.useState(true);
    const [optionSQ, setOptionSQ] = React.useState(true);
    const [optionHQ, setOptionHQ] = React.useState(true);
    const [burnLogo, setBurnLogo] = React.useState(true);
    const [burnSubtitle, setBurnSubtitle] = React.useState(false);
    const [highlight, setHighlight] = React.useState(false);
    const [thumbnails, setThumbnails] = React.useState(true);
    const [sendMail, setSendMail] = React.useState(true);
    const [normalize, setNormalize] = React.useState(true);
    const [createContentElement, setCreateContentElement] = React.useState(false);

	const stations = {ffh: 'FFH', harmonyfm: 'harmony', planetradio: 'planet'};

	const getCurrentStation = () => {
		// Get the current url and split it up
		const parts = window.location.hostname.split('.');

		// In format 'videoupload.ffh.de' the second item in the array is the station
		let currStation = parts[1];
		// If the station isn't in the stations object (e.g. on development server) take ffh as a default
		if(!Object.keys(stations).includes(currStation)) currStation = 'ffh';
		
		return currStation;
	}

    const [station, setStation] = React.useState(getCurrentStation());

    const videoHeadlineRef = React.useRef(null);
    const videoImageRef = React.useRef(null);


    const history = useHistory();

    const close = () => {
        props.setOpen(!props.open);
    }

    const toDownload = (warning = true) => {
        if (!warning && (project.isRendered() || project.isProcessing())) {
          setWarning(true);
          setWarningId((project.highlight == null || project.highlight.start == null || project.highlight.end == null || project.highlight.end <= project.highlight.start) ? 1 : 0);
          return;
        }

        const data = {
            audio: {
                normalize: normalize,
                volume: 1
            },
            subtitles: {
                burn: burnSubtitle
            },
            logo: {
                burn: burnLogo
            },
            format: {
                ls: optionLandscape,
                pt: optionPortrait
            },
            resolutions: {
                lq: optionLQ,
                sq: optionSQ,
                hq: optionHQ,
            },
            highlight: {
              start: (highlight && project.highlight) ? project.highlight.start : -1,
              end: (highlight && project.highlight) ? project.highlight.end : -1
            },
            thumbnails: thumbnails ? project.thumbnails : [],
            options: {
              email: sendMail
            },
			contentElement: {
				create: createContentElement,
				station: station,
				headline: videoHeadlineRef.current.value,
				image: videoImageRef.current.files[0]
			}
        }

        callRenderProject(project.name, data, () => {
            setTimeout(() => {
                history.push('/download/' + project.name ?? '');
            }, 100);
        });
    }

    const toHighlight = () => {
      props.setNavigation(ELEMENTS.HIGHLIGHT);
      props.setOpen(false);
      setWarning(false);
    }

    const thumbnail = "url(" + (project.thumbnail ?? '') + ")";

	// Create the options for possible stations for the content element creation
	const stationOptions = [];
	for(const [key, name] of Object.entries(stations)) {
		stationOptions.push(<Option key={key} name={name} value={key}></Option>)
	}

    return (
        <>
            <ModalWarning project={project} open={warning} setOpen={setWarning} id={warningId} setId={setWarningId} edit={() => toHighlight()} continue={() => toDownload(true)} />
            <Slide open={props.open} title="Exportieren" onClose={close} parentRef={props.parentRef}>
                <div className={css.details}>
                    <div className={css.thumbnail} style={{ backgroundImage: thumbnail }}></div>
                    <div className={css.info}>
                        <div className={css.infoGroup}>{bytesToSize(project.fileInformation.format.size)}</div>
                        <div className={css.infoGroup}>{formatTime(project.fileInformation.format.duration)}</div>
                    </div>
                </div>
                <Collapse active={false} title={<Message id="menu.options" />}>
                    <Group>
                        <Box>
                            <LabeledIcon iconStyle="gray" icon={<IconRenderLQ />}>
                                <span><Message id="toggle.videoInLandscape" /></span>
                            </LabeledIcon>
                            <Check disabled={false} active={optionLandscape} handleClick={() => { setOptionLandscape(!optionLandscape) }}></Check>
                        </Box>
                        <Box>
                            <LabeledIcon iconStyle="gray" icon={<IconRenderSQ />}>
                                <span><Message id="toggle.videoInPortrait" /></span>
                            </LabeledIcon>
                            <Check disabled={false} active={optionPortrait} handleClick={() => { setOptionPortrait(!optionPortrait) }}></Check>
                        </Box>
                        <Description>
                          <span><Message id="toggle.videoFormat.description" /></span>
                        </Description>
                    </Group>
                    <Group>
                        <Box>
                            <LabeledIcon iconStyle="gray" icon={<IconRenderLQ />}>
                                <span><Message id="toggle.videoWithLowQuality" /></span>
                            </LabeledIcon>
                            <Check disabled={false} active={optionLQ} handleClick={() => { setOptionLQ(!optionLQ) }}></Check>
                        </Box>
                        <Box>
                            <LabeledIcon iconStyle="gray" icon={<IconRenderSQ />}>
                                <span><Message id="toggle.videoWithStandardQuality" /></span>
                            </LabeledIcon>
                            <Check disabled={false} active={optionSQ} handleClick={() => { setOptionSQ(!optionSQ) }}></Check>
                        </Box>
                        <Box>
                            <LabeledIcon iconStyle="gray" icon={<IconRenderHQ />}>
                                <span><Message id="toggle.videoWithHighQuality" /></span>
                            </LabeledIcon>
                            <Check disabled={false} active={optionHQ} handleClick={() => { setOptionHQ(!optionHQ) }}></Check>
                        </Box>
                        <Description>
                          <span><Message id="toggle.videoQualityLS.description" /></span>
                        </Description>
                    </Group>
                    <Group>
                        <Box>
                            <LabeledIcon iconStyle="gray" icon={<IconBurnLogo />}>
                                <span><Message id="toggle.videoWithLogo" /></span>
                            </LabeledIcon>
                            <Check active={burnLogo} handleClick={() => { setBurnLogo(!burnLogo) }}></Check>
                        </Box>
                        <Box>
                            <LabeledIcon iconStyle="gray" icon={<IconBurnSubtitles />}>
                                <span><Message id="toggle.videoWithSubtitles" /></span>
                            </LabeledIcon>
                            <Check active={burnSubtitle && project.subtitles.length > 0} handleClick={() => { setBurnSubtitle(!burnSubtitle) }} disabled={project.subtitles.length <= 0}></Check>
                        </Box>
                        <Description>
                          <span><Message id="toggle.videoBurn.description" /></span>
                        </Description>
                    </Group>
                    <Group>
                        <Box>
                            <LabeledIcon iconStyle="gray" icon={<IconSetThumbnail />}>
                                <span><Message id="toggle.thumbnails" /></span>
                            </LabeledIcon>
                            <Check disabled={false} active={thumbnails} handleClick={() => { setThumbnails(!thumbnails) }}></Check>
                        </Box>
                        <Box>
                            <LabeledIcon iconStyle="gray" icon={<IconHighlighter />}>
                                <span><Message id="toggle.highlight" /></span>
                            </LabeledIcon>
                            <Check disabled={false} active={highlight} handleClick={() => { setHighlight(!highlight) }}></Check>
                        </Box>
                    </Group>
                </Collapse>
                <Group>
                  <Box>
                      <LabeledIcon iconStyle="gray" icon={<IconRenderSQ />}>
                          <span><Message id="toggle.normalizeAudio" /></span>
                      </LabeledIcon>
                      <Check active={normalize} handleClick={() => { setNormalize(!normalize) }}></Check>
                  </Box>
                    <Box>
                        <LabeledIcon iconStyle="gray" icon={<IconMail />}>
                            <span><Message id="toggle.emailNotification" /></span>
                        </LabeledIcon>
                        <Check active={sendMail} handleClick={() => { setSendMail(!sendMail) }}></Check>
                    </Box>
					<Box>
                      <LabeledIcon iconStyle="gray" icon={<IconPlus />}>
                          <span><Message id="toggle.createContentElement" /></span>
                      </LabeledIcon>
                      <Check active={createContentElement} handleClick={() => { setCreateContentElement(!createContentElement) }}></Check>
                  </Box>
				  <AdditionalContent show={createContentElement}>
				 	<InputWrapper>
						<LabeledIcon iconStyle="gray" icon={<IconHighlight />}>
							<InputLabel name="headline" ><Message id='contentElement.headline' /></InputLabel>
						</LabeledIcon>
						<Input ref={videoHeadlineRef} name="headline" type="text" maxLength="50" />
					</InputWrapper>
					<InputWrapper>
						<LabeledIcon iconStyle="gray" icon={<IconFolder />}>
							<InputLabel name="station" ><Message id='contentElement.station' /></InputLabel>
						</LabeledIcon>
						<Select name="station" handleSelect={(e) => setStation(e.target.value)}>
							{stationOptions}
						</Select>
					</InputWrapper>
					<InputWrapper>
						<LabeledIcon iconStyle="gray" icon={<IconSetThumbnail />}>
							<InputLabel name="image" ><Message id='contentElement.image' /></InputLabel>
						</LabeledIcon>
						<FileInput ref={videoImageRef} name="image" accept="image/*" />
					</InputWrapper>
				  </AdditionalContent>
                </Group>
                <ProcessButton buttonStyle="primary" handleClick={() => toDownload(false)}>
                    <span><Message id="button.export" /></span>
                </ProcessButton>
            </Slide>
        </>
    );
}
