import React from 'react'
import { useHistory } from "react-router-dom"

import { Message } from 'shared/hooks/Translation'

import { ConfirmButton, Box, Group, Toggle, LabeledIcon } from 'shared/components/Button'
import { IconTrash, IconAngleDoubleRight, IconAngleDoubleDown, IconBurnLogo } from 'shared/components/Icon'
import { ProjectContext } from 'App/pages/Project'
import { addNotification } from 'shared/components/Notification'
import Dropdown, { DropdownMenu, DropdownItem } from 'shared/components/Dropdown'

import { callDeleteProject } from 'shared/hooks/Api'

export default function SheetOptions(props) {
  const { project, projectFunctions, options } = React.useContext(ProjectContext);
  const history = useHistory();

    const deleteProject = () => {
      callDeleteProject(project.name,
        (json) => {
          history.push("/");
          addNotification("notification.projectDelete");
        }
      );
    }

    const toggleTimelineScroll = () => {
      projectFunctions.setOptions({scrollTimeline: !options.scrollTimeline})
    }
    const toggleSubtitlesScroll = () => {
      projectFunctions.setOptions({scrollSubtitles: !options.scrollSubtitles})
    }
    const toggleLogo = () => {
      projectFunctions.setOptions({logo: !options.logo})
    }

    return (
        <>
        <Dropdown open={props.open}>
            <DropdownMenu menuName="main">
                <ConfirmButton text={<Message id="button.deleteProject" />} handleClick={ () => { deleteProject() }}>
                    <IconTrash />
                </ConfirmButton>
                <Group>
                    <Box>
                      <LabeledIcon iconStyle="gray" icon={<IconBurnLogo />}>
                          <span><Message id="toggle.showLogo" /></span>
                        </LabeledIcon>
                        <Toggle active={options.logo} handleClick={toggleLogo}></Toggle>
                    </Box>
                  </Group>
                <DropdownItem goToMenu="timeline"><Message id="menu.timelines" /></DropdownItem>
                <DropdownItem goToMenu="subtitles"><Message id="menu.subtitles" /></DropdownItem>
            </DropdownMenu>
            <DropdownMenu menuName="timeline">
                <DropdownItem goToMenu="main"><Message id="menu.back" /></DropdownItem>
                <Group>
                  <Box>
                      <LabeledIcon iconStyle="gray" icon={<IconAngleDoubleRight />}>
                          <span><Message id="toggle.autoScrollTimelines" /></span>
                        </LabeledIcon>
                      <Toggle active={options.scrollTimeline} handleClick={toggleTimelineScroll}></Toggle>
                  </Box>
              </Group>
            </DropdownMenu>
            <DropdownMenu menuName="subtitles">
                <DropdownItem goToMenu="main"><Message id="menu.back" /></DropdownItem>
                <Group>
                    <Box>
                      <LabeledIcon iconStyle="gray" icon={<IconAngleDoubleDown />}>
                          <span><Message id="toggle.autoScrollSubtitles" /></span>
                        </LabeledIcon>
                        <Toggle active={options.scrollSubtitles} handleClick={toggleSubtitlesScroll}></Toggle>
                    </Box>
              </Group>
            </DropdownMenu>
        </Dropdown>
        </>
    );
}
