import React from 'react'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'

import { ModalOption } from 'App/pages/Dashboard/modals/ModalOption'

import { useHistory } from "react-router-dom"
import { callUploadProjectMedia, callUploadProjectMediaCheck } from 'shared/hooks/Api'

export const Dropzone = React.memo((props) => {
    const history = useHistory();
    const inputRef = React.createRef();
    const [open, setOpen] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [drag, setDrag] = React.useState(false);
    const [file, setFile] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    const openProject = (id) => {
      history.push('/project/' + id);
    }

    const processFile = (program, editor) => {
        setProgress(0.0001);

        callUploadProjectMedia(file,
          (json) => {
            if (editor) openProject(json.id);
            setOpen(false);
            setProgress(0);
          },
          (progress) => {
            setProgress(progress * 100);
          }
        , program);
    }

    const uploadFile = (file) => {
        setProgress(0);
        setFile(file);

        callUploadProjectMediaCheck(file, (json) => {
          setId((json.status && json.status === 'ALREADY_EXISTS') ? json.id : null);
          setOpen(true);
        });
    }

    const onCreateNewProject = () => {
        inputRef.current.click();
    }

    const onDrop = (event) => {
        event.preventDefault();

        setDrag(false);

        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                if (event.dataTransfer.items[i].kind === 'file') {
                    var file = event.dataTransfer.items[i].getAsFile();
                    uploadFile(file);
                    break;
                }
            }
        } else {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                uploadFile(event.dataTransfer.files[i]);
                break;
            }
        }
    }

    const onFileUpload = (event) => {
        const { files } = event.target;
        uploadFile(files[0]);
        event.target.value = null;
      }

    return (
        <>
            <ModalOption open={open} setOpen={setOpen} id={id} setId={setId} progress={progress} processFile={processFile} openProject={openProject} file={file}/>
            <input
                style={{ display: "none" }}
                accept=".mp4,.mov,.mp3"
                ref={inputRef}
                onChange={onFileUpload}
                type="file"
            />
            <div className={css.dropzoneContent}>
                <div className={css.dropzone + (drag ? (' ' + css.active) : '')}
                    onClick={() => { onCreateNewProject() }}
                    onDrop={(e) => { onDrop(e) }}
                    onDragOver={(e) => { setDrag(true); e.preventDefault(); }}
                    onDragLeave={(e) => { setDrag(false) }} >

                    <div className={css.dropzoneText}>
                        <span><Message id='dropzone.title'/></span>
                        <span><Message id='dropzone.instruction'/></span>
                        <button className="secondary"><Message id='dropzone.button'/></button>
                    </div>
                </div>
            </div>
        </>
    );
});
