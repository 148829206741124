import React from 'react'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Download'

import { ProcessButton } from 'shared/components/Button'
import Tab, { TabItem } from 'shared/components/Tab'
import { IconFilm } from 'shared/components/Icon'
import { addNotification } from 'shared/components/Notification'

import { SidebarLink } from './Link'

import { accessRef } from 'shared/utils/ref'
import { extractLast } from 'shared/utils/string'
import config from 'shared/constants/config'

import { callDownloadProjectMedias } from 'shared/hooks/Api';

export const ELEMENTS = {
    VIDEOS: "Videos",
    THUMBNAILS: "Bilder",
}

const Sidebar = (props) => {
    const { project } = React.useContext(ProjectContext);

    var rUrl = props.videoRef.current != null ? props.videoRef.current.src : "";
    
    const [url, setUrl] = React.useState(rUrl);
    const [playback, setPlayback] = React.useState(null);
    const [tab, setTab] = React.useState(ELEMENTS.VIDEOS);
    const [downloadLinks, setDownloadLinks] = React.useState([]);
    const [request, setRequest] = React.useState(null);

    const loadVideo = (src) => {
        if (src !== url) {
            setUrl(extractLast(src));

            accessRef(props.videoRef, (current) => {
                const path = config.urls.cdn + '/load/render/' + extractLast(src);
                if (current.src !== path) current.src = path;
                current.paused ? current.play() : current.pause();
            });
        }
    }

    const downloadZip = () => {
        if (downloadLinks == null || downloadLinks.length <= 0) {
            addNotification("notification.compress.empty");
        } else {
            setRequest(downloadLinks);
        }
    }

    React.useEffect(() => {
        if (request != null) {
            addNotification("notification.compress.start");
            const xhr = callDownloadProjectMedias(project.name, request, (zip) => {
                addNotification("notification.compress.end");

                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(zip);
                var tag = document.createElement('a');
                tag.href = imageUrl;
                tag.target = '_blank';
                tag.download = project.name + '.zip';
                document.body.appendChild(tag);
                tag.click();
                document.body.removeChild(tag);

                setRequest(null);
            });

            return () => {
                xhr.abort();
            }
        }
    }, [request, setRequest, project.name]);

    const current = props.videoRef.current;

    React.useEffect(() => {
        const play = (e) => {
            setPlayback(extractLast(e.currentTarget.currentSrc));
        }
        const pause = (e) => {
            setPlayback(null);
        }

        if (current) {
            current.addEventListener('play', play);
            current.addEventListener('pause', pause);
        }

        return () => {
          if (current) {
              current.removeEventListener('play', play);
              current.removeEventListener('pause', pause);
          }
        }
    }, [current]);

    if (project.renderUrls.length <= 0 && project.thumbnailUrls.length <= 0) {
        return (
            <>
                <div className={css.content}>
                    <div className={css.header}>
                        <Tab>
                            <TabItem active={tab === ELEMENTS.VIDEOS} handleClick={() => setTab(ELEMENTS.VIDEOS)}>
                                <span><Message id="tab.video" /> ({project.renderUrls.length})</span>
                            </TabItem>
                            <TabItem active={tab === ELEMENTS.THUMBNAILS} handleClick={() => setTab(ELEMENTS.THUMBNAILS)}>
                                <span><Message id="tab.image" /> ({project.thumbnailUrls.length})</span>
                            </TabItem>
                        </Tab>
                    </div>
                    <div className={css.body}>
                        <div className={css.loading}>
                            <div className={css.loadingBox}>
                                <IconFilm width={50} height={50} />
                                <span><Message id="folder.lock" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    var urls = [];

    if (tab === ELEMENTS.VIDEOS && project.renderUrls) {
        urls = project.renderUrls.map((path, index) =>
            <SidebarLink key={path} playback={playback} active={url === path} path={url} url={path} loadVideo={loadVideo} videoRef={props.videoRef} project={project} downloadLinks={downloadLinks} setDownloadLinks={setDownloadLinks} />
        );
    } else if (tab === ELEMENTS.THUMBNAILS && project.thumbnailUrls) {
        urls = project.thumbnailUrls.map((path, index) =>
            <SidebarLink key={path} url={path} project={project} downloadLinks={downloadLinks} setDownloadLinks={setDownloadLinks} />
        );
    }

    return (
        <>
            <div className={css.content}>
                <div className={css.header}>
                    <Tab>
                        <TabItem active={tab === ELEMENTS.VIDEOS} handleClick={() => setTab(ELEMENTS.VIDEOS)}>
                            <span><Message id="tab.video" /> ({project.renderUrls.length})</span>
                        </TabItem>
                        <TabItem active={tab === ELEMENTS.THUMBNAILS} handleClick={() => setTab(ELEMENTS.THUMBNAILS)}>
                            <span><Message id="tab.image" /> ({project.thumbnailUrls.length})</span>
                        </TabItem>
                    </Tab>
                </div>
                <div className={css.body}>
                    {urls.length > 0 ? urls : <div className={css.loading}>
                        <div className={css.loadingBox}>
                            <IconFilm width={50} height={50} />
                            <span><Message id="folder.empty" /></span>
                        </div>
                    </div>}
                </div>
                <div className={css.footer}>
                    <ProcessButton buttonStyle="secondary" processing={request != null} handleClick={downloadZip}><Message id="button.download" /> ({downloadLinks.length})</ProcessButton>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
