const MIN_LENGTH = 3;
const MAX_LENGTH = 12;

export const validateHighlight = (oldHighlight, newHighlight) => {
    // Check if highlight overflows
    if (newHighlight.start <= 0) {
      newHighlight.start = 0;
    }

    // Check if highlight is resized smaller then min. length
    if ((newHighlight.end - newHighlight.start) < MIN_LENGTH) {
      const clone = {...oldHighlight};
      clone.end = clone.start + MIN_LENGTH;
      return { ...clone };
    }

    // Check if highlight is resized smaller then max. length
    if ((newHighlight.end - newHighlight.start) > MAX_LENGTH) {
      const clone = {...oldHighlight};
      return { ...clone };
    }

    newHighlight.start = snap(newHighlight.start);
    newHighlight.end = snap(newHighlight.end);

    return { ...newHighlight };
}

export const extractHighlight = (highlight) => {
  if (highlight == null) {
    return null;
  }

  return ({
    start: highlight.start || 0,
    end: highlight.end || 0,
  });
}

export const createHighlightFrame = (from, to) => {
  return ({
    start: from,
    end: to,
  });
}

export const createHighlight = (media) => {
  return ({
    start: media == null ? 0 : media.currentTime,
    end: media == null ? 10 : Math.min(media.duration, media.currentTime + 10),
  });
}

export const snap = (number) => {
  return Math.round(number * 10) / 10;
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}
