import React from 'react'
import { useHistory } from "react-router-dom"

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'

import { IconTrash, IconFile, IconLink, IconHighlighter } from 'shared/components/Icon'
import { addNotification } from 'shared/components/Notification'

import { ModalDelete } from 'App/pages/Dashboard/modals/ModalDelete'

import { milliToSince } from 'shared/utils/time'
import { bytesToSize } from 'shared/utils/math'
import { formatTime } from 'shared/utils/time'
import { callDeleteProject } from 'shared/hooks/Api'

export const ProjectWrapper = React.memo(({ children }) => {
    return (<div className={css.wrapper}>{children}</div>)
});

export const ProjectBar = React.memo((props) => {
    return (
        <div className={css.tileDummyWrapper}>
            <div className={css.tileDummy}>
                <div className={css.tileBar}>
                    <div className={css.tileThumbnail}><span><Message id='project.header.thumbnail' /></span></div>
                    <div className={css.titleName}></div>
                    <div className={css.tileStatus}><span><Message id='project.header.status' /></span></div>
                    <div className={css.tileFlags}><span><Message id='project.header.flags' /></span></div>
                    <div className={css.tileDetails}><span><Message id='project.header.details' /></span></div>
                    <div className={css.tileDate}><span><Message id='project.header.date' /></span></div>
                </div>
            </div>
        </div>
    )
});

export const ProjectTile = (props) => {
    const [confirm, setConfirm] = React.useState(false);
    const history = useHistory();

    const toDownload = () => {
        history.push('/project/' + props.project.name);
    }

    const [showImage, setShowImage] = React.useState(false);
    const placeHolderRef = React.useRef(null);

    React.useEffect(() => {
        registerObserver(placeHolderRef.current, setShowImage);
    }, []);

    const thumbnail = "url(" + props.project.thumbnail + ")";
    const style = showImage ? { "backgroundImage": thumbnail } : {}

    const status = props.project.status ?? 'UNKNOWN';
    const isProcessing = status === 'QUEUED_FOR_RENDERING' || status === 'RENDERING' || status === 'RENDERED';

    var size = props.project.size;
    var duration = props.project.duration;
    var words = props.project.words;

    const deleteProject = () => {
        setConfirm(false);

        callDeleteProject(props.project.name, () => {
            addNotification("notification.projectDelete");
            props.reload();
        });
    }

    var success = props.project.status === 'RENDERED';
    var render = props.project.status.indexOf('RENDER') !== -1;
    var processing = props.project.status.indexOf('ING') !== -1;
    var highlighted = props.project.highlight !== null && props.project.highlight.start != null && props.project.highlight.end != null && props.project.highlight.end > props.project.highlight.start;

    // Convert that pure capitalized status to camelCase
    var statusId = "project.status." + props.project.status.toLowerCase().replace(/_([a-z])/g, function(g) {
        return g[1].toUpperCase();
    });

    const copyUrl = (url) => {
        let textArea = document.createElement("textarea");
        textArea.value = url;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            document.execCommand('copy') ? res() : rej();
            textArea.remove();

            if (window.opener && window.opener !== window) {
              window.opener.postMessage(url, 'https://typo3.ffh.de');
            } else {
              addNotification("notification.linkCopy");
            }
        });
    }

    return (
        <>
            <ModalDelete name={props.project.name} open={confirm} setOpen={setConfirm} confirm={deleteProject} />
            <div className={css.tile + (isProcessing ? (' ' + css.rendered) : (processing ? (' ' + css.processing) : ''))}>
                <div className={css.tileBar}>
                    <div className={css.tileThumbnailWrapper} onClick={toDownload}>
                        <div className={css.tileThumbnail} ref={placeHolderRef} style={style}></div>
                        <div className={css.tileThumbnailDuration}>
                            <span>{formatTime(duration)}</span>
                        </div>
                    </div>
                    <div className={css.titleName} onClick={toDownload}>
                        <IconFile />
                        <span>{props.project.name}</span>
                    </div>
                    <div className={css.tileFlags}>
                        <span>{words <= 0 ? '-' : <Message id="project.word" count={words} />}</span>
                    </div>
                    <div className={css.tileStatus + ' ' + (success ? css.green : (processing ? (render ? css.orange : css.blue) : css.gray))}>
                        <span className={css.indicator + ' ' + (highlighted ? css.indicatorSet : css.indicatorUnset)}><IconHighlighter width="12px" height="12px" /></span>
                        <span><Message id={statusId} /></span>
                    </div>
                    <div className={css.tileDetails}>
                        <span>{bytesToSize(size)}</span>
                    </div>
                    <div className={css.tileDate}><span>{milliToSince(props.project.modifiedAt)}</span></div>
                    <div className={css.tileButton}>
                      {props.project.fileLink != null ? (<button onClick={(e) => { e.stopPropagation(); copyUrl(props.project.fileLink) }}><IconLink /></button>) : null}
                      <button onClick={(e) => { e.stopPropagation(); setConfirm(true) }}><IconTrash /></button>
                    </div>
                </div>
            </div>
        </>
    );
};

export const registerObserver = (ref, setShowImage) => {
    const observer = new IntersectionObserver((enteries, observer) => {
        enteries.forEach((entry) => {
            if (entry.isIntersecting) {
                setShowImage(true);
                observer.disconnect();
            }
        });
    });

    observer.observe(ref);
};
