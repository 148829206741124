import React from 'react'
import { useHistory } from "react-router-dom"

import { useDebouncedEffect} from 'shared/utils/effect';
import { milliToDate } from 'shared/utils/time';

import css from './index.module.css'

import { Message, LanguageContext } from 'shared/hooks/Translation'

import { IconSearch, IconLink } from 'shared/components/Icon'
import { addNotification } from 'shared/components/Notification'

import { callRequestSearch } from 'shared/hooks/Api'

const Search = React.memo((props) => {
    const [open, setOpen] = React.useState(false);
    const [results, setResults] = React.useState([]);
    const [query, setQuery] = React.useState('');
    const [polling, setPolling] = React.useState('');
    const { message  } = React.useContext(LanguageContext);
    const ref = React.useRef(null);

    useDebouncedEffect(() => {
      if (query == null || query.length <= 0) {
        setResults([]);
        setPolling(query);
      } else {
        callRequestSearch(query, (json) => {
            setResults(json);
            setPolling(query);
        });
      }
    }, [query], 250);

    const openResults = () => setOpen(true);

    const search = (e) => {
        if  (e.target.value.length <= 0) {
            setQuery('');
        } else {
            setQuery(e.target.value);
        }
    }

    React.useEffect(() => {
        const registerShortcuts = (e) => {
            if (e.altKey && e.key === 'm') {
              ref.current.focus();
              e.preventDefault();
            }
        }

        window.addEventListener('keydown', registerShortcuts);

        return (() => {
            window.removeEventListener('keydown', registerShortcuts);
        });
    }, []);

  return (
    <>
      <div className={css.wrapper}>
          <div className={css.left}>
              <IconSearch width={20} height={15}/>
          </div>
          <div className={css.right}>
            <input ref={ref} onFocus={openResults} onChange={search} placeholder={message("project.search") + " (Alt + M)"}></input>
          </div>
          <Results polling={polling} open={open} setOpen={setOpen} results={results}/>
      </div>
    </>
  );
});

export default Search;

const Results = React.memo((props) => {
    const history = useHistory();

    const toProject = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        history.push('/project/' + id);
        props.setOpen(false);
    }

    if (!props.open) return null;

    const copyUrl = (url) => {
        let textArea = document.createElement("textarea");
        textArea.value = url;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            document.execCommand('copy') ? res() : rej();
            textArea.remove();

            if (window.opener && window.opener !== window) {
              window.opener.postMessage(url, 'https://typo3.ffh.de');
            } else {
              addNotification("notification.linkCopy");
            }
        });
    }

    var foundInName = false;
    var foundInTranscript = false;

    if (props.results == null) return null;

    props.results.map((result, index) => {
      if (result.type == null) return null;
      if (result.type === 'NAME') foundInName = true;
      if (result.type === 'TRANSCRIPT') foundInTranscript = true;
      return null;
    });

    const list1 = props.results.map((result, index) => {
      if (result.type == null || result.type !== 'NAME') return null;
      return (
        <li key={index}>
            <div className={css.result} onClick={(e) => { toProject(e, result.project) }}>
                <div className={css.resultIcon} style={{backgroundImage: ("url(" + result.thumbnail + ")")}}></div>
                <div className={css.resultDescription}>
                    <span>{result.project}</span>
                    <span><Message id="project.format" /> ({milliToDate(result.createAt)})</span>
                </div>
                <div className={css.resultButton}>
                  {result.fileLink != null ? (<button onClick={(e) => { e.stopPropagation(); copyUrl(result.fileLink) }}><IconLink /></button>) : null}
                </div>
            </div>
        </li>
      )
    });

    const list2 = props.results.map((result, index) => {
      if (result.type == null || result.type !== 'TRANSCRIPT') return null;
      return (
        <li key={index}>
            <div className={css.result} onClick={(e) => { toProject(e, result.project) }}>
                <div className={css.resultIcon} style={{backgroundImage: ("url(" + result.thumbnail + ")")}}></div>
                <div className={css.resultDescription}>
                    <span>{result.project}</span>
                    <span><Message id="project.format" /> ({milliToDate(result.createAt)})</span>
                </div>
                <div className={css.resultButton}>
                  {result.fileLink != null ? (<button onClick={(e) => { e.stopPropagation(); copyUrl(result.fileLink) }}><IconLink /></button>) : null}
                </div>
            </div>
        </li>
      )
    });

  if (props.polling == null || props.polling.length <= 0) return null;
  if (!foundInName && !foundInTranscript) return (
    <>
      <div className={css.results}>
        <span className={css.resultQuery}><Message id="search.empty" variable={props.polling}/></span>
      </div>
    </>
  );

  return (
    <>
      <div className={css.results}>
        {foundInName ? (<><span className={css.resultQuery}><Message id="search.title" variable={props.polling} /></span><ul>{list1}</ul></>) : null}
        {foundInTranscript ? (<><span className={css.resultQuery}><Message id="search.transcript" variable={props.polling} /></span><ul>{list2}</ul></>) : null}
      </div>
    </>
  );
});
