import React from 'react'

import css from './index.module.css'

export default function Tab({children}) {
    return (<div className={css.tabs}>{children}</div>);
}

export const TabItem = ({active, children, handleClick}) => {
    const style = css.tab + (active ? (' ' + css.active) : '');
    return (<div className={style} onClick={handleClick}>{children}</div>);
};
