import React from 'react'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Project'

import { IconUndo, IconRedo } from 'shared/components/Icon'
import { SlideWrapper } from 'shared/components/Slide'
import Tab, { TabItem } from 'shared/components/Tab'

import Options from './Option'
import Exports from './Export'

import { ModalShortcuts } from 'App/pages/Project/modals/ModalShortcuts'

import { ELEMENTS } from 'shared/constants/nav'

const getName = (input) => {
  if (input != null && input.length > 2) return input.substring(0, 2);
  if (input != null && input.length > 1) return input.substring(0, 1);
  return '??';
}

const Toolbar = (props) => {
    const { project, projectPast, projectFuture, projectFunctions, options } = React.useContext(ProjectContext);

    const exportRef = React.useRef(null);
    const [openExport, setOpenExport] = React.useState(false);
    const [openOptions, setOpenOptions] = React.useState(false);
    const [openShortcuts, setOpenShortcuts] = React.useState(false);

    const clients = [];

    project.connections.forEach((client) => {
      clients.push(getName(client));
    });

    const avatars = clients.map((client, index) =>
      <div key={index} className={css.avatar}><span>{client}</span></div>
    );

    const hasVideo = (options.mode !== 'DOCUMENT');

    if (!hasVideo) {
      return (
          <div className={css.wrapper}>
              <div className={css.left}>

              </div>
              <div className={css.center}>
                  <Tab>
                      <Tabs sheet={props.sheet} setSheet={props.setSheet} hasVideo={hasVideo}/>
                  </Tab>
              </div>
              <div className={css.right} ref={exportRef}>
                  <SlideWrapper>
                      <div className={css.right}>
                          <div className={css.avatars}>
                              {avatars}
                          </div>
                          <button className={css.historyButton} onClick={projectFunctions.undo} disabled={projectPast.length <= 0}>
                              <IconUndo height={15} width={15} />
                          </button>
                          <button className={css.historyButton} onClick={projectFunctions.redo} disabled={projectFuture.length <= 0}>
                              <IconRedo height={15} width={15} />
                          </button>
                      </div>
                  </SlideWrapper>
              </div>
          </div>
      );
    }

    return (
        <div className={css.wrapper}>
            <div className={css.left}>
                <button onClick={() => setOpenOptions(!openOptions)}>
                    <span><Message id="button.settings" /></span>
                </button>
                <Options open={openOptions} />
                <button onClick={() => setOpenShortcuts(!openShortcuts)}>
                    <span><Message id="button.shortcuts" /></span>
                </button>
                <ModalShortcuts open={openShortcuts} setOpen={setOpenShortcuts}/>
            </div>
            <div className={css.center}>
                <Tab>
                    <Tabs sheet={props.sheet} setSheet={props.setSheet} hasVideo={hasVideo}/>
                </Tab>
            </div>
            <div className={css.right} ref={exportRef}>
                <SlideWrapper>
                    <div className={css.right}>
                        <div className={css.avatars}>
                            {avatars}
                        </div>
                        <button className={css.historyButton} onClick={projectFunctions.undo} disabled={projectPast.length <= 0}>
                            <IconUndo height={15} width={15} />
                        </button>
                        <button className={css.historyButton} onClick={projectFunctions.redo} disabled={projectFuture.length <= 0}>
                            <IconRedo height={15} width={15} />
                        </button>
                        <Divider />
                        <button onClick={() => { setOpenExport(!openExport) }}>
                            <span><Message id="button.export" /></span>
                        </button>
                        <Exports parentRef={exportRef} open={openExport} setOpen={setOpenExport} navigation={props.setNavigation} setNavigation={props.setNavigation} />
                    </div>
                </SlideWrapper>
            </div>
        </div>
    );
}

export default Toolbar;

const Tabs = (props) => {
  if (props.hasVideo) {
    return (
      <>
        <TabItem active={props.sheet === ELEMENTS.SUBTITLES} handleClick={() => props.setSheet(ELEMENTS.SUBTITLES)}>
            <span><Message id="tab.subtitles" /></span>
        </TabItem>
        <TabItem active={props.sheet === ELEMENTS.THUMBNAILS} handleClick={() => props.setSheet(ELEMENTS.THUMBNAILS)}>
            <span><Message id="tab.thumbnails" /></span>
        </TabItem>
        <TabItem active={props.sheet === ELEMENTS.HIGHLIGHT} handleClick={() => props.setSheet(ELEMENTS.HIGHLIGHT)}>
            <span><Message id="tab.highlight" /></span>
        </TabItem>
      </>
    );
  }

  return null;
}


const Divider = () => {
    return (<div className={css.divider}></div>);
}
