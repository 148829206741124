import { callLogin } from 'shared/hooks/Api'

export const callEvent = () => {
    window.dispatchEvent(new Event('storage'));
}

export const setItem = (key, value) => {
    localStorage.setItem(key, value);
}

export const getItem = (key) => {
    return localStorage.getItem(key);
}

export const removeItem = (key) => {
    localStorage.removeItem(key);
}

export const login = (name, password, callback) => {
    callLogin(name, password,
        (data) => {
            setItem('user', name);
            callEvent();
            callback(data);
        },
        (error) => {
            removeItem('user');
            callEvent();
            callback(error);
        }
    );
}

export const logout = (callback) => {
    removeItem('user');
    callEvent();
    callback();
}

export const isAuthenticated = () => {
    return getItem('user') != null;
}

export const getName = () => {
    return getItem('user');
}
