import React from 'react'
import { useHistory } from "react-router-dom"

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Download'

const Toolbar = React.memo((props) => {
  const { project } = React.useContext(ProjectContext);
  const history = useHistory();

  const toProject = () => {
    history.push('/project/' + project.name);
  }

  return (
    <div className={css.wrapper}>
        <button onClick={toProject}>
          <span><Message id="button.editorOpen" /></span>
        </button>
    </div>
  );
});

export default Toolbar;
