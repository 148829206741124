import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import './index.css';

import { LanguageProvider } from 'shared/hooks/Translation';

import Notifications from 'shared/components/Notification';

import Login from 'App/pages/Login';
import Dashboard from 'App/pages/Dashboard';
import Download from 'App/pages/Download';
import Project from 'App/pages/Project';

import { AppHeader, AppBody } from './Layout';

import { isAuthenticated } from 'shared/hooks/Auth';

export const APP_NAME = "FFH Video Upload";

class App extends React.Component {
  constructor(props) {
    super(props);
    
    document.title = APP_NAME;

    this.state = {
      store: null,
      authenticated: false
    }

    this.setStore = (store) => {
      this.setState({store: store});
    }

    this.checkAuthentication = () => {
      if (!isAuthenticated()) this.setState({authenticated: false});
    }
  }

  componentDidMount() {
    window.addEventListener('storage', this.checkAuthentication);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.checkAuthentication);
  }

  render() {
    return (
      <>
        <LanguageProvider>
          <div className="app">
            <Notifications />
            <Router>
              <Switch>
                  <Route path="/login" render={(props) => (
                      <Login {...props} />
                  )} />
                  <ProtectedRoute exact path="/project/:id" component={Project} />
                  <ProtectedRoute exact path="/download/:id" component={Download} />
                  <ProtectedRoute exact path="/" component={Dashboard} />
                  <Route path="*" component={() => "404 NOT FOUND"} />
              </Switch>
            </Router>
          </div>
        </LanguageProvider>
      </>
    );
  }
}

export default App;

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {
        if (isAuthenticated()) {
          return (
              <>
                  <AppHeader />
                  <AppBody>
                    <Component {...props}/>
                  </AppBody>
              </>
          );
        } else {
          return (<Redirect to={{ pathname: "/login", state: { from: props.location } }}/>);
        }
      }}
    />
  );
};
