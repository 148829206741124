import React from 'react'

import css from './Layout.module.css'
import Logos from 'App/assets/images/FFH_Background.jpg'

import Form from './Form'

export default function Layout(props) {
  const [showImage, setShowImage] = React.useState(false);
  const placeHolderRef = React.useRef(null);

  React.useEffect(() => {
      registerObserver(placeHolderRef.current, setShowImage);
  }, []);

  const thumbnail = "url(" + Logos+ ")";
  const style = showImage ? { "backgroundImage": thumbnail } : {}

    return (
        <div className={css.container}>
            <div className={css.left}>
                <div className={css.login}>
                    <Form location={props.location}/>
                </div>
            </div>
            <div className={css.right} ref={placeHolderRef} style={style}>

            </div>
        </div>
    );
}


export const registerObserver = (ref, setShowImage) => {
    const observer = new IntersectionObserver((enteries, observer) => {
        enteries.forEach((entry) => {
            if (entry.isIntersecting) {
                setShowImage(true);
                observer.disconnect();
            }
        });
    });

    observer.observe(ref);
};
