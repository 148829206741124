import React from 'react'

import { Spinner } from 'shared/components/Button'

import Media from './Media'

import { subscribeProjectProjects } from 'shared/hooks/Api'
import { createProject, syncProject } from 'shared/utils/project'

export const ProjectContext = React.createContext();

export default class Download extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            project: null
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ project: null }, () => {
                this.load();
            });
        }
    }

    // Load project from the server
    load() {
        const id = this.props.match.params.id;

        this.subscription = subscribeProjectProjects(id, (json) => {
            if (json == null) return;

            const queue = (json.encoder && json.encoder.position != null) ? json.encoder.position : -1;
            const percentages = {
                cur: (json.encoder && json.encoder.percentage != null) ? json.encoder.percentage : -1,
                target: (json.encoder && json.encoder.percentage != null) ? json.encoder.percentage : -1,
            }

            if (percentages.cur >= 100) percentages.cur = -1;
            if (percentages.target >= 100) percentages.target = -1;

            const instruction = (json.encoder && json.encoder.instruction != null) ? json.encoder.instruction : null;

            // Initilize project a creating one
            if (this.state.project == null) {
                this.setState({
                    project: createProject(json),
                    percentages: percentages,
                    queue: queue,
                    instruction: instruction
                });
            } else {
                this.setState(prevState => ({
                    ...this.state,
                    project: {
                        ...this.state.project,
                        ...syncProject(this.state.project, json)
                    },
                    percentages,
                    instruction: instruction,
                    queue
                }));
            }
        });
    }

    unload() {
        if (this.subscription != null) this.subscription.close();
    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        this.unload();
    }

    render() {
        if (this.state.project == null) return <Spinner />;

        const project = this.state.project;

        return (
            <ProjectContext.Provider value={{ project }}>
                <ProjectContext.Consumer>
                    {project => (
                        <Media project={project} percentages={this.state.percentages} instruction={this.state.instruction} queue={this.state.queue} />
                    )}
                </ProjectContext.Consumer>
            </ProjectContext.Provider>
        );
    }
}
