import React from 'react'

import css from './Layout.module.css'

import Sidebar from './Sidebar'
import Preview from './Preview'
import Toolbar from './Toolbar'

export default function Layout(props) {
    return (
        <>
          <Toolbar />
          <div className={css.container}>
              <div className={css.preview}>
                  <Preview videoRef={props.videoRef} percentages={props.percentages} instruction={props.instruction} queue={props.queue}/>
              </div>
              <div className={css.sidebar}>
                  <Sidebar videoRef={props.videoRef} />
              </div>
          </div>
        </>
    );
}
