import React from 'react'

import css from './index.module.css'

import { ProjectContext } from 'App/pages/Project'
import { SizeContext, WidthContext } from 'App/pages/Project/Timeline'

import { addSelfDestructingEventListener, addEventListener, removeEventListener } from 'shared/utils/event';

import { ELEMENTS } from 'shared/constants/nav'

const NULL_FOCUS = {
    index: -1,
    uuid: -1
}

export const SubtitleContext = React.createContext();

export const TimelineSubtitle = React.memo((props) => {;
    const { projectFunctions } = React.useContext(ProjectContext);
    const [focus, setFocus] = React.useState(NULL_FOCUS);
    const [selection, setSelection] = React.useState(-1);
    const { width } = React.useContext(WidthContext);

    React.useEffect(() => {
        const registerShortcuts = (e) => {
            if (e.key === 'Delete' && focus.index !== -1) {
              projectFunctions.removeSubtitle(focus.index);
              setFocus(NULL_FOCUS);
            }
        }

        window.addEventListener('keydown', registerShortcuts);

        return (() => {
            window.removeEventListener('keydown', registerShortcuts);
        });
    }, [focus, projectFunctions]);

    if (props.navigation !== ELEMENTS.SUBTITLES && props.navigation != null) return null;

    const applyFocus = (index, uuid, start, end, altKey) => {
        if (altKey && focus !== null) {
          projectFunctions.mergeSubtitles(focus.index, index);
          setFocus(NULL_FOCUS);
        } else {
          setFocus({index, uuid});
        }
    }

    return (
        <div className={css.subtitlesWrapper} style={{ width: width + "px" }}>
            <VirtualSubtitleList
                scrollRef={props.timeline}
                timelineRef={props.timelineRef}
                selection={selection}
                setSelection={setSelection}
                focus={focus}
                setFocus={(index, uuid, start, end, altKey) => { applyFocus(index, uuid, start, end, altKey) }}
            />
        </div>
    );
});

export default TimelineSubtitle;

export const VirtualSubtitleList = React.memo((props) => {
    const [update, setUpdate] = React.useState(-1);
    const { size } = React.useContext(SizeContext);
    const { project } = React.useContext(ProjectContext);

    const selection = props.selection;
    const focus = props.focus;
    const timeline = props.timelineRef;

    const subtitles = project.subtitles === null ? [] : project.subtitles;
    const subtitleList = calculcateSubtitles(timeline, size, subtitles).map((subtitle, index) =>
        subtitle === null ? null :
            <SubtitleContext.Provider value={{ index, subtitle, focus, timeline }} key={subtitle.uuid} >
                <div className={css.subtitle + (isSelected(focus, selection, index) ? (' ' + css.on) : '') + (focus.uuid === subtitle.uuid ? (' ' + css.active) : '')}
                    style={{ left: ((subtitle.start) * size + "px"), width: ((subtitle.end - subtitle.start) * size + "px"), cursor: "zoom" }}
                    onClick={(e) => { props.setFocus(index, subtitle.uuid, subtitle.start, subtitle.end, e.altKey) }}>
                    <Resizer left="true" setSelection={props.setSelection} />
                    <Mover value={subtitle.text} />
                    <Resizer right="true" setSelection={props.setSelection} />
                </div>
            </SubtitleContext.Provider>
    );

    React.useEffect(() => {
        const load = () => {
            if (timeline == null) return;
            const pos = timeline.current === null ? 0 : timeline.current.scrollLeft;
            if (update !== pos) setUpdate(pos);
        }

        load();

        const id = setInterval(load, 250);

        return () => {
            clearInterval(id);
        }
    }, [timeline, update]);

    return (
        <>{subtitleList}</>
    );
});

const isSelected = (focus, selection, index) => {
  if (selection < 0 || selection === focus.index) {
    return false;
  }

  if (selection < focus.index) {
    if (index >= selection && index < focus.index) {
      return true;
    }
  }
  if (selection > focus.index) {
    if (index <= selection && index > focus.index) {
      return true;
    }
  }

  return false;
}

const calculcateSubtitles = (timeline, size, subtitles) => {
    const visibleSubtitles = [];

    if (timeline !== undefined && timeline.current !== null) {
        const bounding = timeline.current.getBoundingClientRect();
        const from = timeline.current.scrollLeft - bounding.width;
        const to = bounding.width + timeline.current.scrollLeft + bounding.width;

        for (let i = 0, il = subtitles.length; i < il; i++) {
            const left = (subtitles[i].start) * size;
            const right = left + (subtitles[i].end - subtitles[i].start) * size;

            if (left > to) {
                break;
            }

            if (left >= from || right >= from) {
                visibleSubtitles.push({ ...subtitles[i] });
            } else {
                visibleSubtitles.push(null);
            }
        }
    }

    return visibleSubtitles;
}

export const Resizer = React.memo((props) => {
    const { size } = React.useContext(SizeContext);
    const { project, projectFunctions } = React.useContext(ProjectContext);
    const { index, subtitle, focus } = React.useContext(SubtitleContext);

    var last = -19725;
    var time = -19725;
    var timeTick = 0;
    var offset = 0;

    const resize = (e) => {
        e.preventDefault();

        if (last !== -19725) {
            const diff = (e.clientX - last) / size;
            if (props.left) subtitle.start += diff;
            if (props.right) subtitle.end += diff;

            if (time === -19725) {
              if (props.left) {
                time = subtitle.start;
              } else {
                time = subtitle.end;
              }
            }

            time += diff;

            if (timeTick++ % 5 === 0) {
              var offsetTime = (offset / size);

              var to = getOffsetIndex(project, time + offsetTime, index, focus, props.left);

              if (to !== props.selection) {
                props.setSelection(to);
              }
            }

            projectFunctions.modifySubtitle(index, { start: subtitle.start, end: subtitle.end }, false);
        }

        last = e.clientX;
    }

    const registerMove = (e) => {
        if (focus.uuid === subtitle.uuid) {
            const bb = e.target.getBoundingClientRect();
            if (props.left) {
              offset = (e.clientX - bb.left);
            } else {
              offset = (e.clientX - bb.right);
            }

            projectFunctions.modifySubtitle(index, { text: subtitle.text }, true);

            addSelfDestructingEventListener(window, 'mouseup', () => {
              removeEventListener(window, 'mousemove', resize, false);

              var offsetTime = (offset / size);

              var from = index;
              var to = getOffsetIndex(project, time + offsetTime, index, focus, props.left);

              if (from !== to) {
                projectFunctions.mergeSubtitles(from, to);
              }

              props.setSelection(-1);
            });
            addEventListener(window, 'mousemove', resize, false);
        }
    }

    return (
        <div className={css.subtitleMove} style={{ marginLeft: props.left ? '-1px' : '', marginRight: props.right ? '-1px' : '' }} onMouseDown={registerMove}></div>
    );
});


const getOffsetIndex = (project, time, index, focus, left) => {
  var from = index;
  var to = index;

  var range = 10;
  var treshold = 0.5;

  var first = true;

  if (left) {
    for (let i = focus.index - 1, il = Math.max(0, focus.index - 1 - range); i >= il && from === to; i--) {
      const sub = project.subtitles[i];

      if (first && time >= (sub.end - treshold)) {
        continue;
      }

      first = false;

      if (time >= (sub.start - treshold)) {
        to = i;
      }
    }
  } else {
    for (let i = focus.index + 1, il = Math.min(focus.index + 1 + range, project.subtitles.length); i < il && from === to; i++) {
      const sub = project.subtitles[i];

      if (first && time <= (sub.start + treshold)) {
        continue;
      }

      first = false;

      if (time <= (sub.end + treshold)) {
        to = i;
      }
    }
  }

  return to;
}

export const Mover = React.memo((props) => {
    const { size } = React.useContext(SizeContext);
    const { projectFunctions } = React.useContext(ProjectContext);
    const { index, subtitle, focus } = React.useContext(SubtitleContext);

    var last = -19725;

    const resize = (e) => {
        e.preventDefault();

        if (last !== -19725) {
            const diff = (e.clientX - last) / size;
            subtitle.start += diff;
            subtitle.end += diff;

            projectFunctions.modifySubtitle(index, { start: subtitle.start, end: subtitle.end }, false);
        }

        last = e.clientX;
    }

    const registerMove = () => {
        if (focus.uuid === subtitle.uuid) {
            projectFunctions.modifySubtitle(index, { text: subtitle.text }, true);

            addSelfDestructingEventListener(window, 'mouseup', () => { window.removeEventListener('mousemove', resize, false) });
            window.addEventListener('mousemove', resize, false);
        }
    }

    return (
        <textarea autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
            onMouseDown={registerMove}
            key={index}
            onChange={(e) => { projectFunctions.modifySubtitle(index, { text: e.target.value }) }}
            value={props.value}>
        </textarea>
    );
});
