import React from 'react'
import { useHistory } from "react-router-dom"

import css from './Layout.module.css'

import Browser from './Browser'

export const ELEMENTS = {
  VIDEO: "Videos",
  AUDIO: "Audios",
}

export default function Layout(props) {
    const containerRef = React.useRef(null);
    const history = useHistory();
    const historyHash = history.location.hash.length > 0 ? history.location.hash.substring(1) : "";
    const [navigation, setNavigation] = React.useState(historyHash.length > 0 ? historyHash : ELEMENTS.VIDEO);

    const page = navigation;
    const setPage = (page) => {
      history.replace('#' + page);
      if (page !== navigation) setNavigation(page);
    }

    return (
        <div className={css.container} ref={containerRef}>
            <div className={css.browser}>
                {page === ELEMENTS.VIDEO ? <Browser type='video' page={page} setPage={setPage} containerRef={containerRef} /> : null}
                {page === ELEMENTS.AUDIO ? <Browser type='audio' page={page} setPage={setPage} containerRef={containerRef} /> : null}
            </div>
        </div>
    );
}
