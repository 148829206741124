import React from 'react'

import css from './index.module.css'

import { ProjectContext } from 'App/pages/Download'

import { IconPause, IconPlay } from 'shared/components/Icon'
import { addNotification } from 'shared/components/Notification'

import { accessRef } from 'shared/utils/ref'
import { extractLast } from 'shared/utils/string'
import { formatTime } from 'shared/utils/time'

import config from 'shared/constants/config'

const Player = React.memo((props) => {
    const { project } = React.useContext(ProjectContext);

    const [paused, setPaused] = React.useState(props.videoRef.current == null ? true : props.videoRef.current.paused);
    const [loaded, setLoaded] = React.useState(false);

    const bufferRef = React.useRef(null);
    const timeRefLeft = React.useRef(null);
    const timeRefRight = React.useRef(null);
    const seekbarThumb = React.useRef(null);

    const register = (e) => {
        accessRef(bufferRef, (current) => {
            current.style.width = (e.target.buffered.end(0) / e.target.duration * 100) + "%";
        });
    }

    React.useEffect(() => {
        const play = (e) => setPaused(false);
        const pause = (e) => setPaused(true);
        const canplay = (e) => setLoaded(true);
        const abort = (e) => setLoaded(false);

        const timeupdate = (e) => {
            accessRef(timeRefLeft, (current) => {
                current.textContent = formatTime(e.target.currentTime, true);
            });
            accessRef(timeRefRight, (current) => {
                current.textContent = "- " + formatTime(e.target.duration - e.target.currentTime, true);
            });
            accessRef(seekbarThumb, (current) => {
                current.style.width = ((e.target.currentTime / e.target.duration) * 100) + "%";
            });
        }

        accessRef(props.videoRef, (current) => {
            current.addEventListener('play', play);
            current.addEventListener('pause', pause);
            current.addEventListener('canplay', canplay);
            current.addEventListener('abort', abort);
            current.addEventListener('timeupdate', timeupdate);
        });

        return () => {
            accessRef(props.videoRef, (current) => {
                current.removeEventListener('play', play);
                current.removeEventListener('pause', pause);
                current.removeEventListener('canplay', canplay);
                current.removeEventListener('abort', abort);
                current.removeEventListener('timeupdate', timeupdate);
            });
        }
    }, [props.videoRef]);

    React.useEffect(() => {
        accessRef(props.bufferRef, (current) => {
            current.style.width = "0%";
        });

        accessRef(props.videoRef, (current) => {
            current.addEventListener('progress', register);
        });

        return () => {
            accessRef(props.videoRef, (current) => {
                current.removeEventListener('progress', register);
            });
        }
    }, [props.videoRef, props.bufferRef]);

    const togglePlay = (e) => {
        e.stopPropagation();
        accessRef(props.videoRef, (current) => {
            current.paused ? current.play() : current.pause();
        });
    }

    const jump = (e) => {
        e.stopPropagation();
        accessRef(props.videoRef, (current) => {
            current.currentTime = (props.videoRef.current.duration * (e.target.value / 100));
        });
    }

    return (
        <div className={css.wrapper + ' ' + (loaded ? css.loaded : css.unloaded)}>
            <div className={css.container + ' ' + (paused ? css.paused : css.unpaused)}>
                <Video playsInline ref={props.videoRef} renderUrls={project.renderUrls} />
                <div className={css.videoOverlay} ></div>
                <div className={css.videoBadge}><Badge video={props.videoRef.current} renderUrls={project.renderUrls} /></div>
                <div className={css.videoPlay + ' ' + (paused ? css.paused : css.unpaused)} ><IconPause width={30} height={30} /></div>
                <div className={css.videoControlsWrapper}>
                    <div className={css.seekbar}>
                        <div className={css.seekbarBuffer} ref={bufferRef}></div>
                        <div className={css.seekbarThumb} ref={seekbarThumb}></div>
                        <input type="range" className={css.seekbarInput} onChange={jump}></input>
                    </div>
                    <div className={css.videoControls}>
                        <div className={css.left}>
                            <span ref={timeRefLeft}>{formatTime(0.001, true)}</span>
                        </div>
                        <div className={css.center}>
                            <button className={"clear " + css.playback} onClick={togglePlay}>
                                {paused ? <IconPause /> : <IconPlay />}
                            </button>
                        </div>
                        <div className={css.right}>
                            <span ref={timeRefRight}>-{formatTime(0.001, true)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Player;

const Video = React.forwardRef((props, ref) => {
    if (props.renderUrls.length <= 0) {
        addNotification("notification.noneVideos");
        return null;
    } else {
        return (
            <video className={css.video} ref={ref} playsInline>
                <source src={config.urls.cdn + '/load/render/' + extractLast(props.renderUrls[0])}></source>
            </video>
        );
    }
});

export function Badge({ video, renderUrls }) {
    if (video == null) return null;
    var name = video.src;
    if (name == null || name === "") name = extractLast(renderUrls[0])
    if (name.indexOf('_lq_') !== -1) return (<div className={css.badge}><span>426x240</span><span>LQ</span></div>);
    if (name.indexOf('_sd_') !== -1) return (<div className={css.badge}><span>640x360</span><span>SD</span></div>);
    if (name.indexOf('_hd_') !== -1) return (<div className={css.badge}><span>1280x720</span><span>HD</span></div>);
    if (name.indexOf('_sm_') !== -1) return (<div className={css.badge}><span>1920x1080</span><span>HD</span><span>LOGO</span></div>);
    if (name.indexOf('_st_') !== -1) return (<div className={css.badge}><span>1920x1080</span><span>HD</span><span>LOGO</span><span>SUBTITLE</span></div>);
    return (<div className={css.badge}></div>);
}
