import React from 'react'
import { useHistory } from "react-router-dom"

import css from './index.module.css'
import Logos from 'App/assets/images/Logofamilie3_2018_RGB.svg'

import { Message, LanguageContext } from 'shared/hooks/Translation'

import { InputWrapper, Input, InputLabel, InputError, ProcessButton } from 'shared/components/Button'

import { APP_NAME } from 'App'

import { login } from 'shared/hooks/Auth';

export default function Form(props) {
    const [validName, setValidName] = React.useState(true);
    const [validPassword, setValidPassword] = React.useState(true);
    const [processing, setProcessing] = React.useState(false);
    const { message } = React.useContext(LanguageContext);

    const history = useHistory();
    const nameRef = React.useRef(null);
    const passwordRef = React.useRef(null);

    React.useEffect(() => {
      if (props.location.search) {
        const query = new URLSearchParams(props.location.search);

        if (query.has("time") && query.has("token")) {
          const time = query.get("time");
          const token = query.get("token");

          login(time, token, (data) => {
              if (data.status && data.status === 'SUCCESS') {
                  history.push('/');
              }
          });
        }
      }
    }, [history, props.location.search]);

    const handleSubmit = () => {
        setProcessing(true);

        login(nameRef.current.value, passwordRef.current.value, (data) => {
            if (data.status && data.status === 'SUCCESS') {
                history.push('/');
            } else {
                setValidName(false);
                setValidPassword(false);
                nameRef.current.value = "";
                passwordRef.current.value = "";
                setProcessing(false);
            }
        });
    }

    return (
        <div className={css.form}>
            <div className={css.formHeader}>
                <img src={Logos} alt="Logo"></img>
                <span>{APP_NAME}</span>
            </div>
            <form onSubmit={(e) => { e.preventDefault() }}>
                <InputWrapper>
                    <Input ref={nameRef} error={!validName} name="text" type="text" placeholder={message('login.name.placeholder')} />
                    <InputLabel name="text" ><Message id='login.name' /></InputLabel>
                    <InputError error={!validName}><Message id='login.name.invalid' /></InputError>
                </InputWrapper>
                <InputWrapper>
                    <Input ref={passwordRef} error={!validPassword} name="password" type="password" placeholder={message('login.password.placeholder')}/>
                    <InputLabel name="password" ><Message id='login.password' /></InputLabel>
                    <InputError error={!validPassword}><Message id='login.password.invalid' /></InputError>
                </InputWrapper>
                <ProcessButton buttonStyle='secondary' handleClick={handleSubmit} processing={processing}><Message id='button.login' /></ProcessButton>
            </form>
        </div>
    );
}
