import React from 'react'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'

import { IconClose, IconSpinner, IconChevronDown } from 'shared/components/Icon'

export function CloseButton(props) {
    return props.onClose == null ? null : (<div className={css.close}><button onClick={props.onClose}><IconClose /></button></div>);
}

export function Button({ children, handleClick, buttonStyle = 'primary' }) {
    return (<div className={css.buttonWrapper}><button className={buttonStyle} onClick={handleClick}>{children}</button></div>);
}

export function LabeledIcon({ iconStyle, children, icon }) {
    return (<div className={css.toggleIconWrapper}><div className={css.toggleIcon + (iconStyle ? (" " + iconStyle) : "")}>{icon}</div>{children}</div>);
}

export function Toggle({ handleClick, active, disabled }) {
    return (<div className={css.toggle + (active ? (' ' + css.active) : ('')) + (disabled ? (' ' + css.disabled) : '')} onClick={handleClick}><div className={css.toggleBackground}></div><div className={css.toggleHandle}></div></div>);
}

export function Check({ handleClick, active, disabled }) {
    return (<div className={css.check + (active ? (' ' + css.active) : ('')) + (disabled ? (' ' + css.disabled) : '')} onClick={handleClick}><div className={css.checkBackground}></div><div className={css.checkHandle}></div></div>);
}

export function Group({ children }) {
    return (<div className={css.group}>{children}</div>);
}

export function Box({ children }) {
    return (<div className={css.box}>{children}</div>);
}

export function Spinner({ children }) {
    return (<div className={css.spinnerWrapper}><div className={css.spinner}><IconSpinner height="50" width="50" /></div>{children}</div>)
};

export function Loader({ children, width, height }) {
    return (
        <div className={css.loaderWrapper} width={width} height={height}>
            <div className={css.loader}>
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
            {children}
        </div>)
};


export function Description({ children }) {
    return (<div className={css.description}>{children}</div>)
};

export function InputWrapper({ children, error = false }) {
    return (<div className={css.inputWrapper}>{children}</div>)
};

export const Input = React.forwardRef((props, ref) => {
    return (<input ref={ref} type={props.type} value={props.value} maxLength={props.maxLength} placeholder={props.placeholder} name={props.name} className={css.input + (props.error ? (' ' + css.error) : '')}></input>)
});

export function InputLabel({ children, name }) {
    return (<label htmlFor={name} className={css.inputLabel}>{children}</label>)
};

export function InputError({ children, error }) {
    return error ? (<span className={css.inputError}>{children}</span>) : null;
};

export const ConfirmButton = React.memo(({ handleClick, children, text }) => {
    const [state, setState] = React.useState(false);

    return (
        <div className={css.buttonWrapper}>
            <div className={css.button} onClick={() => { setState(!state) }}>
                <div className={css.buttonText}>{text}</div>
                {state ? <div className={css.buttonRoot + ' ' + css.buttonCancel}><Message id="button.cancel" /></div> : <div className={css.buttonRoot}>{children}</div>}
            </div>
            {state ? <div className={css.button} onClick={(e) => { handleClick(e); setState(false) }}><div className={css.buttonRoot}><Message id="button.confirm" /></div></div> : null}
        </div>
    )
});

export const ProcessButton = React.memo(({ handleClick, children, processing }) => {
    return (
        <div className={css.buttonWrapper + (processing ? (' ' + css.active) : '')}>
            <button className={css.button + " primary"} onClick={handleClick}>
                <div className={css.buttonText}>{children}</div>
                {processing ? <div className={css.buttonSpinner}><IconSpinner /></div> : null}
            </button>
        </div>
    )
});

export const Option = React.memo(({ name, value }) => {
    return (<option className={css.option} value={value}>{name}</option>)
});

export const SelectButton = React.memo(({ handleClick, children, text }) => {
    return (
        <div className={css.buttonWrapper}>
            <div className={css.select}>
                <div className={css.selectText}>{children}</div>
                <div className={css.selectRoot}>
                    <div className={css.selectButton} onClick={handleClick}>
                        <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M441.9 250.1l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L250 385.4V44c0-6.6-5.4-12-12-12h-28c-6.6 0-12 5.4-12 12v341.4L42.9 230.3c-4.7-4.7-12.3-4.7-17 0L6.1 250.1c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z" /></svg>
                    </div>
                </div>
            </div>
        </div>
    )
});

export const Select = React.memo(({ children, handleSelect }) => {
    return (<select className={css.select} onChange={handleSelect}>{children}</select>)
});

export const OptionButtonWrapper = React.memo(({ children, optionStyle }) => {
    return (
        <div className={css.optionButtonWrapper}>
            {children}
        </div>
    )
});

export const OptionButtonGroup = React.memo(({ children }) => {
    return (
        <div className={css.optionButtonGroup}>
            {children}
        </div>
    )
});

export const OptionButton = React.memo(({ children, handleClick, optionStyle = '' }) => {
    return (
        <div className={css.optionButton + (optionStyle === 'recommended' ? (' ' + css.recommended) : '')} onClick={handleClick}>
            {children}
        </div>
    )
});

export const Collapse = React.memo(({ children, active, title }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <div className={css.collapse}>
            <div className={css.collapseTitle}>
                <span>{title}</span>
                <span className={css.collapseButton + ' ' + (open ? css.up : css.down)} onClick={() => { setOpen(!open) }}><IconChevronDown /></span>
            </div>
            {open ? (<div className={css.collapseContent}>{children}</div>) : null}
        </div>
    )
});

export const AdditionalContent = React.memo(({ children, show }) => {
    return (
		<div className={css.additionalContent + ' ' + (show ? '' : css.hidden)}>
			{children}
		</div>
    );
});

export const FileInput = React.forwardRef((props, ref) => {
	const [filename, setFilename] = React.useState('');

	const handleClick = () => {
		ref.current.click();
	}

	const handleChange = e => {
		const path = e.target.value.split('\\');

		let name = path.pop();
		if(name && name.length > 25) {
			const start = name.slice(0, 12);
			const end = name.slice(-8);
			name = `${start} ... ${end}`;
		}

		setFilename(name);
	}
	
	return (
		<div className={css.fileInput}>
			<Button buttonStyle='outlined' handleClick={handleClick}>Bild auswählen</Button>
			<input onChange={handleChange} ref={ref} type="file" name={props.name} accept={props.accept} className={css.input + ' ' + css.hidden}></input>
			<p className={css.filename}>{filename || 'Keine Datei ausgewählt'}</p>
		</div>
	);
});

export const ProgressBar = React.memo(({ progress }) => {
    return (
        <div className={css.progressBarWrapper}>
            <div className={css.progressBar} style={{ width: progress + "%" }}></div>
        </div>
    )
});
