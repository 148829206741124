import React from 'react'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'

import Modal, { ModalHeader, ModalBody, ModalFooter } from 'shared/components/Modal'

export const ModalShortcuts = React.memo((props) => {
    return (
        <Modal modalStyle='small' open={props.open} onClose={() => { props.setOpen(false) }}>
            <ModalHeader><span><Message id="modal.shortcuts" /></span></ModalHeader>
            <ModalBody>
              <div className={css.shortcuts}><span><Message id="shortcut.search" /></span><div className={css.shortcutsKeys}><i>alt</i><i>s</i></div></div>
              <br/>
              <div className={css.shortcuts}><span><Message id="shortcut.toggle_playback" /></span><div className={css.shortcutsKeys}><i>Space</i></div></div>
              <div className={css.shortcuts}><span><Message id="shortcut.back" /></span><div className={css.shortcutsKeys}><i>shift</i><i>Space</i></div></div>
              <div className={css.shortcuts}><span><Message id="shortcut.skip_forward" /></span><div className={css.shortcutsKeys}><i>Tab</i></div></div>
              <div className={css.shortcuts}><span><Message id="shortcut.skip_backwards" /></span><div className={css.shortcutsKeys}><i>shift</i><i>Tab</i></div></div>
              <div className={css.shortcuts}><span><Message id="shortcut.skip_forward_10" /></span><div className={css.shortcutsKeys}><i>l</i></div></div>
              <div className={css.shortcuts}><span><Message id="shortcut.skip_backwards_10" /></span><div className={css.shortcutsKeys}><i>j</i></div></div>
              <br/>
              <div className={css.shortcuts}><span><Message id="shortcut.undo" /></span><div className={css.shortcutsKeys}><i>crtl</i><i>z</i></div></div>
              <div className={css.shortcuts}><span><Message id="shortcut.redo" /></span><div className={css.shortcutsKeys}><i>crtl</i><i>y</i></div></div>
              <br/>
              <div className={css.shortcuts}><span><Message id="shortcut.subtitle_delete" /></span><div className={css.shortcutsKeys}><i>del</i></div></div>
              <div className={css.shortcuts}><span><Message id="shortcut.subtitle_add" /></span><div className={css.shortcutsKeys}><i>a</i></div></div>
              <div className={css.shortcuts}><span><Message id="shortcut.subtitle_cut" /></span><div className={css.shortcutsKeys}><i>s</i></div></div>
              <div className={css.shortcuts}><span><Message id="shortcut.subtitle_fit" /></span><div className={css.shortcutsKeys}><i>d</i></div></div>
              <br/>
              <div className={css.shortcuts}><span><Message id="shortcut.timeline_zoom_in" /></span><div className={css.shortcutsKeys}><i>+</i></div></div>
              <div className={css.shortcuts}><span><Message id="shortcut.timeline_zoom_out" /></span><div className={css.shortcutsKeys}><i>-</i></div></div>
            </ModalBody>
            <ModalFooter>
                <button className="clear" onClick={() => { props.setOpen(false) }}><Message id="button.close" /></button>
            </ModalFooter>
        </Modal>
    );
});
