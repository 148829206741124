import React from 'react'

import css from './index.module.css'

import SheetSubtitles from './Subtitles'
import SheetThumbnails from './Thumbnails'
import SheetHighlight from './Highlight'

import { ELEMENTS } from 'shared/constants/nav'

const Sheets = React.memo((props) => {
  return (
    <>
      <Sheet setSheet={props.setSheet} visible={props.sheet === ELEMENTS.SUBTITLES}>
        <SheetSubtitles
          listRef={props.listRef}
          project={props.project}
          projectFunctions={props.projectFunctions}
        />
      </Sheet>
      <Sheet setSheet={props.setSheet} visible={props.sheet === ELEMENTS.THUMBNAILS}>
        <SheetThumbnails
          project={props.project}
          projectFunctions={props.projectFunctions}
        />
      </Sheet>
      <Sheet setSheet={props.setSheet} visible={props.sheet === ELEMENTS.HIGHLIGHT}>
        <SheetHighlight
          project={props.project}
          projectFunctions={props.projectFunctions}
        />
      </Sheet>
    </>
  );
});

export default Sheets;

export function Sheet({setSheet, visible, children}) {
  if (!visible) return null;

  return (
    <>
      <div className={css.close} onClick={() => { setSheet(null) }}>
        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
      </div>
      {children}
    </>
    );
}

export function SheetHeader({children}) {
  return (<div className={css.header}>{children}</div>);
}

export function SheetTabBody({open = true, children, bodyRef}) {
  if (!open) return null;
  return (<div ref={bodyRef} className={css.body}>{children}</div>);
}

export function SheetTitle({children}) {
  return (<div className={css.title}>{children}</div>);
}
