import React from 'react'

import ProjectMedia from './Media'

import { Spinner } from 'shared/components/Button'

import { addNotification } from 'shared/components/Notification'
import { subscribeProjectProjects, callSaveProject } from 'shared/hooks/Api'
import { validateSubtitle, removeSubtitle, addSubtitle, cutSubtitles, insertSubtitle, snapSubtitles, mergeSubtitles, spliceSubtitles } from 'shared/utils/subtitle'
import { validateThumbnail, addThumbnail, insertThumbnail, removeThumbnail, getCurrentY } from 'shared/utils/thumbnail'
import { validateHighlight, createHighlight, createHighlightFrame } from 'shared/utils/highlight'
import { accessRef } from 'shared/utils/ref'
import { createProject, syncProject } from 'shared/utils/project'

const MAX_HISTORY_SIZE = 32;
const AUTO_SAVE_INTERVAL = 2500;

export const ProjectContext = React.createContext();

export default class Project extends React.Component {
    constructor(props) {
        super(props);

        this.saveRef = React.createRef(null);

        // State of the project
        this.state = {
            loading: true,
            modification: [],
            projectPast: [],
            project: null,
            projectFuture: [],
            connections: [],
            options: {
                scrollTimeline: true,
                scrollSubtitles: true,
                smartHighlights: true,
                lockSubtitles: false,
                logo: true,
                loopHighlight: false,
                mode: 'VIDEO'
            }
        }

        var updateSubtitles = false;
        var updateHighlight = false;
        var updateThumbnails = false;
        var updateAlternative = false;

        this.clearHistory = () => {
            // Never let  the history over a given threshold
            while (this.state.projectPast.length > MAX_HISTORY_SIZE) this.state.projectPast.splice(1);
            while (this.state.projectFuture.length > MAX_HISTORY_SIZE) this.state.projectFuture.slice(0, this.state.projectFuture.length - 1);
        }

        this.updateProject = (data, history = true, delay = -1, save = true) => {
            if (data == null) return;

            // Update project history without notifying components
            if (history) {
                this.state.projectPast.push({ ...this.state.project });
                this.state.projectFuture = [];
                // Only trigger send save to server if the changes are put into the history

                if (save) {
                  data.saved = false;
                }
            }

            // Update project
            this.setState(prevState => ({
                ...prevState,
                project: {
                    ...prevState.project,
                    ...data
                }
            }));
        }

        // Project function that can be passed to other components
        this.projectFunctions = {
            createSnapshot: () => {
                const returnedTarget = Object.assign({}, this.state.project);

                this.state.projectPast.push(returnedTarget);
                this.state.projectFuture = [];
            },

            setAlternative: (alternative) => {
                updateAlternative = true;
                this.updateProject({ alternative: alternative }, true, 500, true);
            },

            modifySubtitle: (index, subtitle, history = true, delay = -1) => {
                this.updateProject({ subtitles: this.state.project.subtitles.map((obj, i) => i === index ? validateSubtitle(this.state.project.subtitles, i, subtitle.text ?? obj.text, subtitle.start ?? obj.start, subtitle.end ?? obj.end, obj.uuid) : obj) }, history, delay);
                updateSubtitles = true;
            },
            addSubtitle: (index) => {
                this.updateProject({ subtitles: addSubtitle(this.state.project.subtitles, index) });
                updateSubtitles = true;
            },
            insertSubtitle: (time) => {
                this.updateProject({ subtitles: insertSubtitle(this.state.project.subtitles, time) });
                updateSubtitles = true;
            },
            removeSubtitle: (index) => {
                this.updateProject({ subtitles: removeSubtitle(this.state.project.subtitles, index) });
                updateSubtitles = true;
            },
            cutSubtitles: (time) => {
                this.updateProject({ subtitles: cutSubtitles(this.state.project.subtitles, time) });
                updateSubtitles = true;
            },
            spliceSubtitles: (time, position) => {
                this.updateProject({ subtitles: spliceSubtitles(this.state.project.subtitles, time, position) });
                updateSubtitles = true;
            },
            deleteSubtitles: () => {
                this.updateProject({ subtitles: [] });
                updateSubtitles = true;
            },
            replaceSubtitles: (subtitles) => {
                this.updateProject({ subtitles: Array.isArray(subtitles) ? subtitles : [] });
                updateSubtitles = true;
            },
            snapSubtitles: () => {
                this.updateProject({ subtitles: snapSubtitles(this.state.project.subtitles) });
                updateSubtitles = true;
            },
            mergeSubtitles: (a, b) => {
              this.updateProject({ subtitles: mergeSubtitles(this.state.project.subtitles, a, b) });
              updateSubtitles = true;
            },
            getSubtitles: () => {
              return this.state.project.subtitles;
            },
            setSubtitles: (subtitles) => {
                updateSubtitles = true;
                if (Array.isArray(subtitles)) {
                    this.setState(prevState => ({
                        ...prevState,
                        project: {
                            ...prevState.project,
                            ...{ subtitles: subtitles }
                        }
                    }));
                }
            },

            setOptions: (data) => {
                this.setState(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        ...data
                    }
                }));
            },

            modifyThumbnail: (index, thumbnail, history = true) => {
                updateThumbnails = true;
                this.updateProject({ thumbnails: this.state.project.thumbnails.map((obj, i) => i === index ? validateThumbnail(this.state.project.thumbnails, i, thumbnail.time ?? 0, thumbnail.image ?? null, thumbnail.y ?? null) : obj) }, history);
            },
            addThumbnail: (index, history = true) => {
                updateThumbnails = true;
                this.updateProject({ thumbnails: addThumbnail(this.state.project.thumbnails, index) }, history);
            },
            insertThumbnail: (time, history = true) => {
                updateThumbnails = true;
                this.updateProject({ thumbnails: insertThumbnail(this.state.project.thumbnails, time) }, history);
            },
            removeThumbnail: (index, history = true) => {
                updateThumbnails = true;
                this.updateProject({ thumbnails: removeThumbnail(this.state.project.thumbnails, index) }, history);
            },
            submitCurrentY: (yCoordinate) => {
                getCurrentY(yCoordinate);
            },

            deleteHighlight: (history = true) => {
                updateHighlight = true;
                this.updateProject({ highlight: { start: 0, end: 0 }}, history, -1);
            },
            createHighlight: (media = null, history = true) => {
                updateHighlight = true;
                this.updateProject({ highlight: createHighlight(media) }, history);
            },
            createHighlightFrame: (from = 0, to = 10, history = true) => {
                updateHighlight = true;
                this.updateProject({ highlight: createHighlightFrame(from, to) }, history);
            },
            modifyHighlight: (highlight, history = true) => {
                updateHighlight = true;
                this.updateProject({ highlight: validateHighlight(this.state.project.highlight, highlight) }, history);
            },

            undo: () => {
                if (this.state.projectPast.length > 0) {
                    this.setState({
                        projectPast: this.state.projectPast.slice(0, this.state.projectPast.length - 1),
                        project: this.state.projectPast[this.state.projectPast.length - 1],
                        projectFuture: [this.state.project, ...this.state.projectFuture]
                    }, () => {
                        this.setState(prevState => ({
                            ...prevState,
                            project: {
                                ...prevState.project,
                                ...{ saved: false }
                            }
                        }));
                    });
                }
            },
            redo: () => {
                if (this.state.projectFuture.length > 0) {
                    this.setState({
                        projectPast: [...this.state.projectPast, this.state.project],
                        project: this.state.projectFuture[0],
                        projectFuture: this.state.projectFuture.slice(1)
                    }, () => {
                        this.setState(prevState => ({
                            ...prevState,
                            project: {
                                ...prevState.project,
                                ...{ saved: false }
                            }
                        }));
                    });
                }
            },

            save: (callback, force) => {
                if (this.state.project != null) {
                    if (this.state.project.saved) {
                        if (callback) callback(false);
                    } else {
                        const subtitles = [...this.state.project.subtitles];
                        this.state.project.saved = true;
                        this.state.modification = subtitles;

                        const payload = {};

                        if (updateSubtitles || force) payload.subtitles = subtitles;
                        if (updateThumbnails || force) payload.thumbnails = [...this.state.project.thumbnails];
                        if (updateHighlight || force) payload.highlight = {...this.state.project.highlight};
                        if (updateAlternative || force) payload.alternative = this.state.project.alternative;

                        updateSubtitles = false;
                        updateHighlight = false;
                        updateThumbnails = false;
                        updateAlternative = false;

                        callSaveProject(this.state.project.name, payload,
                            () => { if (callback) callback(false) },
                            () => { if (callback) callback(true) }
                        );
                      }
                }
            }
        }

        this.keydown = (e) => {
    			if ((e.ctrlKey && e.key === 'z')) {
            this.projectFunctions.undo();
    			}
          if ((e.ctrlKey && e.key === 'y') || (e.ctrlKey && e.shiftKey && e.key === 'z')) {
            this.projectFunctions.redo();
    			}
    		}
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ project: null }, () => {
                this.load();
            });
        }
    }

    load() {
        // Just unload if anything was loaded before
        this.unload();

        const id = this.props.match.params.id;

        this.subscription = subscribeProjectProjects(id, (json) => {
            if (json == null) return;

            if (this.state.project == null) {
                var project = createProject(json);
                var projectMode = 'DOCUMENT';

                for (let i = 0, il = project.upload.length; i < il; i++) {
                  if (project.upload[i].indexOf('mp3') === -1) {
                    projectMode = 'VIDEO';
                  }
                }

                this.setState({
                    project: project,
                    connections: json.connections ? json.connections : [],
                    options: {
                        ...this.state.options,
                        mode: projectMode
                    }
                });
            } else if (json != null) {
                if (json.subtitles != null) {
                    // Check if the subtitles matching the last sent subtitles
                    if (JSON.stringify(this.state.modification) === JSON.stringify(json.subtitles)) return;
                }

                var _project = this.state.project;
                var _projectMode = 'DOCUMENT';

                for (let i = 0, il = _project.upload.length; i < il; i++) {
                  if (_project.upload[i].indexOf('mp3') === -1) {
                    _projectMode = 'VIDEO';
                  }
                }

                this.setState(prevState => ({
                    ...this.state,
                    project: {
                        ..._project,
                        ...syncProject(this.state.project, json)
                    },
                    options: {
                        ...this.state.options,
                        mode: _projectMode
                    }
                }));
            }
        });

        this.autoSave = setInterval(() => {
            if (this.state.project != null && !this.state.project.saved) {
                accessRef(this.saveRef, (current) => {
                    current.style.opacity = 1;
                });

                this.projectFunctions.save((failed) => {
                    if (failed) {
                      addNotification("notification.connectionProblem", 'warning');
                    }

                    // Small timeout to show the saving inducator
                    this.autoSaveAnimation = setTimeout(() => {
                        accessRef(this.saveRef, (current) => {
                            current.style.opacity = 0;
                        });
                    }, Math.max(0, AUTO_SAVE_INTERVAL - 500));
                }, false);
            }
        }, AUTO_SAVE_INTERVAL);

        window.addEventListener('keydown', this.keydown);
    }

    unload() {
        if (this.autoSave) clearInterval(this.autoSave);
        if (this.autoSaveAnimation) clearTimeout(this.autoSaveAnimation);
        if (this.subscription != null) this.subscription.close();
        if (this.poll != null) this.poll.close();
        if (this.projectFunctions != null) this.projectFunctions.save(null, true);

        window.removeEventListener('keydown', this.keydown);
    }

    // Load project from the server
    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        this.unload();
    }

    render() {
        // Wait until the project is not null
        if (this.state.project == null) return <><Spinner ></Spinner></>;

        // Build the editor with the project data
        const project = this.state.project;
        const projectFunctions = this.projectFunctions;
        const projectPast = this.state.projectPast;
        const projectFuture = this.state.projectFuture;
        const options = this.state.options;
        const connections = this.state.connections;

        return (
            <>
                <ProjectContext.Provider value={{ projectPast, project, projectFuture, projectFunctions, options }}>
                    <ProjectContext.Consumer>
                        {project => (
                            <ProjectMedia project={project} projectFunctions={projectFunctions} options={options} saveRef={this.saveRef} connections={connections} />
                        )}
                    </ProjectContext.Consumer>
                </ProjectContext.Provider>
            </>
        );
    }
}
