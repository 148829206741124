import React from 'react'

import css from './index.module.css'

import { ProjectContext } from 'App/pages/Project'
import { MediaContext } from 'App/pages/Project/Media'
import { SizeContext, WidthContext } from 'App/pages/Project/Timeline'

import { addSelfDestructingEventListener, addEventListener, removeEventListener } from 'shared/utils/event';

import { ELEMENTS } from 'shared/constants/nav'

export const HighlightContext = React.createContext();

const TimelineHighlight = React.memo((props) => {
    const { projectFunctions, options } = React.useContext(ProjectContext);
    const { width } = React.useContext(WidthContext);

    React.useEffect(() => {
        const registerShortcuts = (e) => {
            if (e.key === 'Delete') {
              projectFunctions.deleteHighlight();
            }
        }

        window.addEventListener('keydown', registerShortcuts);

        return (() => {
            window.removeEventListener('keydown', registerShortcuts);
        });
    }, [projectFunctions]);

    if (props.navigation !== ELEMENTS.HIGHLIGHT) return null;

    return (
        <div className={css.wrapper + (options.loopHighlight ? (' ' + css.loop) : (''))} style={{ width: width + "px" }}>
          <Highlight />
        </div>
    );
});

const Highlight = React.memo((props) => {
  const { project } = React.useContext(ProjectContext);
  const { mediaFunctions } = React.useContext(MediaContext);
  const { size } = React.useContext(SizeContext);

  const highlight = project.highlight;
  if (highlight == null) return null;
  if (highlight.start == null || highlight.end == null) return null;
  if (highlight.end <= highlight.start) return null;

  const duration = Number(highlight.end - highlight.start).toFixed(2);

  return(
    <HighlightContext.Provider value={{ highlight, mediaFunctions }} >
        <div className={css.highlight}
            style={{ left: ((highlight.start) * size + "px"), width: ((highlight.end - highlight.start) * size + "px"), cursor: "zoom" }}>
            <Resizer left="true" />
            <Mover />
            <Resizer right="true" />
            <div className={css.time}>{duration}s</div>
        </div>
    </HighlightContext.Provider>
  );
});

export const Resizer = React.memo((props) => {
    const { size } = React.useContext(SizeContext);
    const { projectFunctions } = React.useContext(ProjectContext);
    const { highlight } = React.useContext(HighlightContext);

    var last = -19725;

    const resize = (e) => {
        e.preventDefault();

        if (last !== -19725) {
            const diff = (e.clientX - last) / size;
            if (props.left) highlight.start += diff;
            if (props.right) highlight.end += diff;

            projectFunctions.modifyHighlight({ start: highlight.start, end: highlight.end }, false);
        }

        last = e.clientX;
    }

    const registerMove = () => {
        projectFunctions.modifyHighlight({ start: highlight.start, end: highlight.end }, true);

        addSelfDestructingEventListener(window, 'mouseup', () => { removeEventListener(window, 'mousemove', resize, false) });
        addEventListener(window, 'mousemove', resize, false);
    }

    return (
        <div className={css.highlightMove} style={{ marginLeft: props.left ? '-1px' : '', marginRight: props.right ? '-1px' : '' }} onMouseDown={registerMove}></div>
    );
});

export const Mover = React.memo((props) => {
    const { size } = React.useContext(SizeContext);
    const { projectFunctions } = React.useContext(ProjectContext);
    const { highlight, mediaFunctions } = React.useContext(HighlightContext);

    if (highlight == null) return null;

    var last = -19725;

    const resize = (e) => {
        e.preventDefault();

        if (last !== -19725) {
            const diff = (e.clientX - last) / size;
            highlight.start += diff;
            highlight.end += diff;

            mediaFunctions.pause();

            projectFunctions.modifyHighlight({ start: highlight.start, end: highlight.end }, false);
        }

        last = e.clientX;
    }

    const registerMove = () => {
        projectFunctions.modifyHighlight({ start: highlight.start, end: highlight.end }, true);

        addSelfDestructingEventListener(window, 'mouseup', () => { window.removeEventListener('mousemove', resize, false) });
        window.addEventListener('mousemove', resize, false);
    }

    return (
        <div
            className={css.mover}
            onMouseDown={registerMove}>
        </div>
    );
});


export default TimelineHighlight;
