import React from 'react'
import { useHistory } from "react-router-dom"

import LandscapeLogo from 'App/assets/images/FFH_Logo.png'
import PortraitLogo from 'App/assets/images/FFH-WHITE-TRANSPARENT.png'

import Modal, { ModalHeader, ModalBody, ModalFooter } from 'shared/components/Modal'
import { Spinner } from 'shared/components/Button'

import Layout from './Layout'

import { formatTime } from 'shared/utils/time'
import { accessRef } from 'shared/utils/ref'

import config from 'shared/constants/config'

export const MediaContext = React.createContext();

const SUBTITLE_CONTAINER_HEIGHT = 160;

var elemsWithBoundingRects = [];

const getBoundingClientRectFast = (element) => {
	if (!element._boundingClientRect) {
		element._boundingClientRect = element.getBoundingClientRect();
		elemsWithBoundingRects.push(element);
	}

	return element._boundingClientRect;
}

const clearClientRects = () => {
	var i;

	for (i = 0; i < elemsWithBoundingRects.length; i++) {
		if (elemsWithBoundingRects[i]) {
			elemsWithBoundingRects[i]._boundingClientRect = null;
		}
	}

	elemsWithBoundingRects = [];
};

export default class ProjectMedia extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			videos: null,
			images: [],
			media: [],
			index: -1,
			subtitle: null,
			portrait: null
		}

		this.generatorCanvas = document.createElement('canvas');
		this.generatorCanvas.width = 0;
		this.generatorCanvas.height = 0;

		this.markerRef = React.createRef(null); // Timeline marker
		this.timelineRef = React.createRef(null); // Timeline
		this.timeRef = React.createRef(null); // Time
		this.canvasRef = React.createRef(null); // Preview canvas
		this.subtitleRef = React.createRef(null); // Preview subtitle
		//this.portraitCanvas = React.createRef(null); // Canvas Format
		this.previewRef = React.createRef(null); // Preview window
		this.listRef = React.createRef(null); // Subtitle list sheet

		// Project function that can be passed to other components
		this.mediaFunctions = {
			play: (play) => {
				play ? this.state.media[0].play() : this.state.media[0].pause(); return !this.state.media[0].paused;
			},
			pause: (play) => {
				play ? this.state.media[0].pause() : this.state.media[0].pause(); return !this.state.media[0].paused;
			},
			togglePlay: () => {
				this.mediaFunctions.play(this.state.media[0].paused); return !this.state.media[0].paused;
			},
			time: (seconds) => {
				this.state.media[0].currentTime = Math.max(0, Math.min(this.state.media[0].duration, seconds)); return this.state.media[0].currentTime;
			},
			skip: (seconds) => {
				this.mediaFunctions.time(this.state.media[0].currentTime + seconds); return this.state.media[0].currentTime;
			},
			volume: (percentage) => {
				this.state.media[0].volume = Math.max(0, Math.min(1, percentage)); return this.state.media[0].volume;
			},
			mute: (muted) => {
				this.state.media[0].muted = muted; return this.state.media[0].muted;
			},
			toggleMute: () => {
				this.state.media[0].muted = !this.state.media[0].muted; return this.state.media[0].muted;
			},
			loop: (loop) => {
				this.state.media[0].loop = loop; return this.state.media[0].loop;
			},
			toggleLoop: (loop) => {
				this.mediaFunctions.loop(!this.state.media[0].loop); return this.state.media[0].loop;
			}
		}

		// Global event that can be used to register shortcuts
		this.keydown = (e) => {
			// Check if the space bar is not used to write sentences
			if (e.target.nodeName !== 'BODY' && e.target.nodeName !== 'BUTTON') return;

			if ((e.code === 'Space' || e.key === 'k') && !e.ctrlKey) this.mediaFunctions.togglePlay();
			if ((e.code === 'Space') && e.shiftKey) this.mediaFunctions.time(0);
			if ((e.key === 'Tab')) this.mediaFunctions.skip(e.shiftKey ? (-1) : 1);

			// Check the the keys for crtl, alt and shift are not pressed!
			if (!e.ctrlKey && !e.altKey && !e.shiftKey) {
				if (e.key === 'l' || e.key === 'j') this.mediaFunctions.skip((e.key === 'j' || e.key === 'ArrowLeft') ? (-10) : 10);
				if (e.key === 'a') props.projectFunctions.insertSubtitle(this.state.media[0].currentTime);
				if (e.key === 's') props.projectFunctions.cutSubtitles(this.state.media[0].currentTime);
				if (e.key === 'd') props.projectFunctions.snapSubtitles();
			}
		}
	}

	// Load the videos
	componentDidMount() {
		const videos = [];
		const images = [];

		const project = this.props.project.project;

		var extensions = ["ogm","wmv","mpg","webm","ogv","mov","asx","mpeg","mp4","m4v","avi","mp3"];

		for (let i = 0, il = project.upload.length; i < il; i++) {
			const upload = project.upload[i];
			const uploadParams = upload.split("/");
			const uploadName = (uploadParams.length > 0 ? uploadParams[uploadParams.length - 1] : upload);

			for (var j = 0, ji = extensions.length; j < ji; j++) {
				if (upload.indexOf('.' + extensions[j]) !== -1) {
					const video = document.createElement('video');
					video.setAttribute('webkit-playsinline', true);
					video.setAttribute('playsinline', true);
					video.playsInline = true;
					video.loop = false;
					video.autoplay = false;
					video.src = config.urls.cdn + '/load/' + uploadName;
					video.currentTime = 0.001;

					// Add video without publishing changes
					videos.push(video);
					break;
				}
			}

			if (upload.indexOf('.jpg') !== -1) {
				const image = document.createElement('image');
				image.src = config.urls.cdn + '/load/' + uploadName;

				// Add video without publishing changes
				images.push(image);
			}
		}

		if (videos.length > 0) {
			const mainVideo = videos[0];

			// Wait untill the video can be played and the set the state
			mainVideo.oncanplay = () => {
				mainVideo.oncanplay = null;

				const secondVideo = document.createElement('video');
				secondVideo.setAttribute('webkit-playsinline', true);
				secondVideo.setAttribute('playsinline', true);
				secondVideo.playsInline = true;
				secondVideo.crossOrigin = "anonymous";
				secondVideo.src = mainVideo.src;
				secondVideo.loop = false;
				secondVideo.currentTime = 0.001;

				this.setState({ videos: videos, images: images, media: [mainVideo, secondVideo] })

				const logoLandscape = new Image();
				const logoPortrait = new Image();

				logoLandscape.onload = () => {
					images.push(logoLandscape);
				}
				logoPortrait.onload = () => {
					images.push(logoPortrait);
				}

				logoLandscape.src = LandscapeLogo;
				logoPortrait.src = PortraitLogo;
			}
		} else {
			this.setState({ videos: videos, images: images });
		}

		// Start a timeout that will triggere if the video is not loaded within a time
		this.timeout = setTimeout(() => {
			if (this.state.videos === null) {
				this.setState({ videos: [] });
			}
		}, 5000);

		var time = 0;
		var timeTick = 0;

		this.requestId = -1;

        /*
        * Renderer
        * Render & resize canvas
        * Move marker
        * Scroll timeline and list
        */
  		var isFirstLoop = true;

		this.drawFrame = () => {
			if (this.state.media && this.state.media.length > 0) {
				const media = this.state.media[0];

				if (time !== media.currentTime) {
					const px = (media.currentTime * this.markerRef.current.getAttribute('size'));

					if (this.markerRef.current != null) {
						this.markerRef.current.style.left = px + "px";
					}

					if (this.timelineRef.current != null && this.props.options.scrollTimeline) {
						const bounding = getBoundingClientRectFast(this.timelineRef.current);
						const scrollLeft = this.timelineRef.current.scrollLeft;
						const from = scrollLeft;
						const to = bounding.width + scrollLeft;
						const px = parseInt(this.markerRef.current.style.left);

						if ((px <= from || px >= to)) {
							this.timelineRef.current.scrollLeft = px;
						}
					}
				}

				if (timeTick % 5 === 0) {
					if (this.timeRef.current != null) {
						const time = formatTime(media.currentTime);

						if (this.timeRef.current.textContent !== time) {
							this.timeRef.current.textContent = time;
						}
					}

					const subtitles = this.props.project.project.subtitles;
					const index = calculcateCurrentSubtitle(subtitles, media.currentTime, this.state.index);

					if (this.state.index !== index) {
						this.setState({
							index: index,
							subtitle: {
								text: subtitles[index] != null ? subtitles[index].text : "",
								x: 0,
								y: 30
							}
						});

						if (this.listRef.current != null && index >= 0 && this.props.options.scrollSubtitles && !this.props.options.lockSubtitles) {
							const scrollTopMin = index * SUBTITLE_CONTAINER_HEIGHT;
							const scrollTopMax = scrollTopMin + SUBTITLE_CONTAINER_HEIGHT;
							const viewMin = this.listRef.current.scrollTop;
							const viewHeight = getBoundingClientRectFast(this.listRef.current).height;
							const viewMax = viewMin + viewHeight;

							if (scrollTopMin <= viewMin || scrollTopMax >= viewMax) {
								// Check if there is enough leave space for the previous subtitle
								if (viewHeight > SUBTITLE_CONTAINER_HEIGHT * 2) {
									this.listRef.current.scrollTop = scrollTopMin - SUBTITLE_CONTAINER_HEIGHT;
								} else {
									this.listRef.current.scrollTop = scrollTopMin;
								}
							}
						}
					}

					const subtitle = this.state.index >= 0 ? subtitles[this.state.index].text : "";

					if (this.subtitleRef.current != null && this.subtitleRef.current.textContent !== subtitle) {
						this.subtitleRef.current.textContent = subtitle;
					}

					// Resize the preview elements
					if (this.previewRef.current != null) {
						const rect = getBoundingClientRectFast(this.previewRef.current.parentElement);

						accessRef(this.canvasRef, (current) => {
							var height;
							var width = (rect.height / media.videoHeight) * media.videoWidth;

							if (width < rect.width) {
								height = rect.height;
								if (current.height !== height) current.height = height;
								if (current.width !== width) current.width = width;
								if (parseInt(current.parentElement.style.width) !== current.width) current.parentElement.style.width = current.width + "px";
								if (parseInt(current.parentElement.style.height) !== current.height) current.parentElement.style.height = current.height + "px"
							} else {
								height = (rect.width / media.videoWidth) * media.videoHeight;
								if (current.width !== rect.width) current.width = rect.width;
								if (current.height !== height) current.height = height;
								if (parseInt(current.parentElement.style.width) !== current.width) current.parentElement.style.width = current.width + "px";
								if (parseInt(current.parentElement.style.height) !== current.height) current.parentElement.style.height = current.height + "px";
							}

							const highlight = this.props.project.project.highlight;

							if (highlight != null && highlight.start != null && highlight.end != null && highlight.end > highlight.start) {
								if (this.props.options.loopHighlight && !media.paused) {
									if (media.currentTime > highlight.end) {
										media.pause();
										media.currentTime = highlight.start + 0.001;
										media.play();
									}
									if (media.currentTime < highlight.start) {
										media.pause();
										media.currentTime = highlight.start + 0.001;
										media.play();
									}
								}

								if (media.currentTime >= highlight.start && media.currentTime <= highlight.end) {
									current.classList.add('highlighted');
								} else {
									current.classList.remove('highlighted');
								}
							} else {
								current.classList.remove('highlighted');
							}
						});
					}
				}

				// Draw the preview image
				if (this.canvasRef.current !== null) {
					const canvas = this.canvasRef.current;
					const ctx = canvas.getContext('2d');
					const height = canvas.height;
					const width = canvas.width;
				
					if (isFirstLoop){
						isFirstLoop = false;
						if(height > width){
						
						}
					}
					
				

					this.state.videos.forEach((file) => {
						ctx.drawImage(file, 0, 0, (height / file.videoHeight) * file.videoWidth, height);
					});

					if(height > width && this.canvasRef !== null){
						this.setState({
							portrait: true
						});
					}
					if(height < width && this.canvasRef !== null){
						this.setState({
							portrait: false
						});
					}

					if (this.props.options.logo && this.state.images[1] !== undefined) {
							this.state.images.forEach(() => {
								if (height > width){
									ctx.drawImage(this.state.images[1], width-width/3.5, height/10, (height / this.state.images[1].height) * this.state.images[1].width/8, height/8);
								} else {
									ctx.drawImage(this.state.images[0], 0, 0, (height / this.state.images[0].height) * this.state.images[0].width, height);
								}
							});
					}
					
					/*if (this.props.options.logo) {
						this.state.images.forEach((file) => {
							ctx.drawImage(file, 0, 0, (height / file.height) * file.width, height);
						});
					}
					/*if (this.props.options.logo && whiteLogo) {
						this.state.images.forEach(() => {
							ctx.drawImage(this.state.images[1], width-100, 100, (height / this.state.images[1].height) * this.state.images[1].width/8, height/8);
						});
					}

					/*
					if (this.state.subtitle != null) {
						ctx.font = "28px Arial";
						ctx.textAlign = 'center';
						ctx.fillText(this.state.subtitle.text, (width / 2) + this.state.subtitle.x, height - this.state.subtitle.y);
					}
					*/
					
				}

				timeTick++;
				time = media.currentTime;
			}

			this.requestId = requestAnimationFrame(this.drawFrame);
		}

		this.drawFrame();

		this.generator = setInterval(() => {
			// Check if there is a second media to seek in
			if (this.state.media.length <= 1) return;
			// When canvas is in use then the dimension will be changed
			if (this.generatorCanvas.width !== 0) return;
			if (this.generatorCanvas.height !== 0) return;

			if (this.props.project != null && this.props.project.project != null && Array.isArray(this.props.project.project.thumbnails)) {
				for (let i = 0, il = this.props.project.project.thumbnails.length; i < il; i++) {
					const thumbnail = this.props.project.project.thumbnails[i];

					// Load thumbnail!
					if (thumbnail.image == null) {
						this.generatorCanvas.width = 160;
						this.generatorCanvas.height = 90;

						const video = this.state.media[1];
						video.currentTime = thumbnail.time;

						video.oncanplay = () => {
							this.generatorCanvas.getContext('2d').drawImage(video, 0, 0, 160, 90);
							thumbnail.image = this.generatorCanvas.toDataURL("image/jpeg", 1.0);

							this.props.projectFunctions.modifyThumbnail(i, thumbnail, false);

							this.generatorCanvas.width = 0;
							this.generatorCanvas.height = 0;
						}
						break;
					}
				}
			}
		}, 250);

		window.addEventListener('resize', clearClientRects);
		window.addEventListener('keydown', this.keydown);
	}

	// Unload the videos
	componentWillUnmount() {
		if (this.requestId != null) cancelAnimationFrame(this.requestId);
		if (this.timeout != null) clearTimeout(this.timeout);
		if (this.generator != null) clearInterval(this.generator);

		window.removeEventListener('resize', clearClientRects);
		window.removeEventListener('keydown', this.keydown);

		// Unload all videos
		if (this.state.videos !== null) {
			this.state.videos.forEach((video) => {
				video.pause();
				video.removeAttribute('src');
				video.load();
				video.remove();
			});
		}

		// Unload all images
		if (this.state.images !== null) {
			this.state.images.forEach((image) => {
				image.removeAttribute('src');
			});
		}
	}

	render() {
		if (this.state.videos === null)  return <><Spinner ></Spinner></>;

		if (this.state.videos.length === 0) {
			return (
				<>
					<ModalError />
				</>
			);
		} else {
			const media = this.state.media;
			const mediaFunctions = this.mediaFunctions;
			const mediaIndex = this.state.index;

			return (
				<>
					<MediaContext.Provider value={{ media, mediaFunctions, mediaIndex }}>
						<Layout
							connections={this.props.connections}
							saveRef={this.props.saveRef}
							timelineRef={this.timelineRef}
							markerRef={this.markerRef}
							timeRef={this.timeRef}
							canvasRef={this.canvasRef}
							subtitleRef={this.subtitleRef}
							previewRef={this.previewRef}
							listRef={this.listRef}
							options={this.props.options}
						/>
					</MediaContext.Provider>
				</>
			);
		}
	}
}

export const ModalError = React.memo((props) => {
	const history = useHistory();

	const toDashboard = () => {
		history.push('/');
	}

	return (
		<Modal open={true} onClose={toDashboard}>
			<ModalHeader><span>Oops</span></ModalHeader>
			<ModalBody><span>Es ist ein Fehler beim Laden des Projekts aufgetreten.</span></ModalBody>
			<ModalFooter>
				<button className="clear" onClick={toDashboard}>Zurück</button>
			</ModalFooter>
		</Modal>
	);
});

const calculcateCurrentSubtitle = (subtitles, time, index, setIndex) => {
	if (subtitles == null || subtitles.length <= 0) {
		return -1;
	}
	var newIndex = -1;

	if (subtitles !== null) {
		var newLength = subtitles.length;
		var newSubtitle = (index >= 0 && index < newLength) ? subtitles[index] : null;

		if (newSubtitle != null && time >= newSubtitle.start && time <= newSubtitle.end) {
			newIndex = index;
		} else {
			// Search forward
			if (newSubtitle == null || time > newSubtitle.end) {
				for (let i = index + 1, il = newLength; i < il && newIndex === -1; i++) {
					const sub = subtitles[i];
					if (sub == null) continue;
					if (time >= sub.start && time <= sub.end) newIndex = i;
				}
			}

			// Search backward
			if (newSubtitle == null || time < newSubtitle.start) {
				for (let i = index - 1; i >= 0 && newIndex === -1; i--) {
					const sub = subtitles[i];
					if (sub == null) continue;
					if (time >= sub.start && time <= sub.end) newIndex = i;
				}
			}
		}
	}

	return newIndex;
}
