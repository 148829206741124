import React from 'react'
import { useHistory } from "react-router-dom"

import { Message } from 'shared/hooks/Translation'

import Modal, { ModalHeader, ModalBody, ModalFooter } from 'shared/components/Modal'

export const ModalWarning = React.memo((props) => {
  const history = useHistory();

  const toFolder = () => {
    history.push("/download/" + props.project.name);
  }

  if (props.id === 1) {
      return (
        <Modal modalStyle='small' open={props.open} onClose={() => { props.setOpen(false) }}>
            <ModalHeader><span><Message id="modal.invalidHighlight" /></span></ModalHeader>
            <ModalBody><span><Message id="modal.invalidHighlight.text" /></span></ModalBody>
            <ModalFooter>
                <button className="primary" onClick={() => { props.edit(0) }}><Message id="button.editHighlight" /></button>
                <button className="clear" onClick={() => { props.setId(0)  }}><Message id="button.ignore" /></button>
            </ModalFooter>
        </Modal>
      );
  }

    return (
        <Modal modalStyle='small' open={props.open} onClose={() => { props.setOpen(false) }}>
            <ModalHeader><span><Message id="modal.overrideExport" /></span></ModalHeader>
            <ModalBody><span><Message id="modal.overrideExport.text" /></span></ModalBody>
            <ModalFooter>
                <button className="primary warning" onClick={() => { props.continue() }}><Message id="button.exportOverride" /></button>
                <button className="clear" onClick={toFolder}><Message id="button.exportView" /></button>
            </ModalFooter>
        </Modal>
    );
});
