import { addNotification } from 'shared/components/Notification';

const MIN_LENGTH = 1;
const PLACEHOLDER = "Neuer Untertitel";

export const validateSubtitle = (subtitles, index, text, start, end, uuid) => {
    if (subtitles == null || !Array.isArray(subtitles)) return [];
    if (start < 0) start = 0;

    // Check if the subtitle has a min length
    if ((end - start) < MIN_LENGTH) {
        return { ...subtitles[index] };
    }

    if (index < subtitles.length) {
        if (end !== null && index + 1 < subtitles.length) {
            const subNext = subtitles[index + 1];

            if (end > subNext.start) {
                end = subNext.start;

                if (end - start < MIN_LENGTH) {
                    end = subNext.start;
                    start = end - MIN_LENGTH;
                }
            }
        }

        if (start !== null && index - 1 >= 0) {
            const subPrev = subtitles[index - 1];

            if (start < subPrev.end) {
                start = subPrev.end;

                if (end - start < MIN_LENGTH) {
                    start = subPrev.end;
                    end = start + MIN_LENGTH;
                }
            }
        }
    }

    return { ...subtitles[index], text, start, end, uuid };
}

export const removeSubtitle = (subtitles, index) => {
    if (subtitles == null) return [];
    const subs = [...subtitles];
    if (index >= 0 && index < subtitles.length) subs.splice(index, 1);
    return subs;
}

export const addSubtitle = (subtitles, index) => {
    if (subtitles == null) return [];
    const subs = [...subtitles];

    if (index == null) {
        return subs;
    }

    // Create new subtitle
    const subNew = createSubtitle();

    if (index < 0) {
        index = subs.length;
    }

    // Get left and right subtitle
    var subNext = getSubtitleAtIndex(subs, index);
    var subPrev = getSubtitleAtIndex(subs, index - 1);

    if (subNext !== undefined) {
        subNext = {...subNext};
        subs[index] = subNext;

        if ((subNext === undefined)) {
            addNotification("notification.subtitleInvalid");
            return subs;
        }

        var start = subPrev !== undefined ? subPrev.end : 0;

        // Check if there is space before the element
        if (subNext.start - start >= MIN_LENGTH) {
            subNext[index] = {...subNext};
            subNew.start = subNext.start - MIN_LENGTH;
            subNew.end = subNext.start;
        } else {
            // Not enought space before and the element cannot be resized
            if ((subNext.end - subNext.start) <= MIN_LENGTH) {
                addNotification("notification.subtitleInvalid");
                return subs;
            }

            // Resize the element to fit a new one in
            subNew.start = subNext.start;
            subNew.end = subNext.start + MIN_LENGTH;
            subNext.start = subNext.start + MIN_LENGTH;
        }
    } else if (subPrev !== undefined) {
        subPrev = {...subPrev};
        subs[index - 1] = subPrev;
        subNew.start = subPrev.end;
        subNew.end = subNew.start + MIN_LENGTH;

        if ((subPrev.end - subPrev.start) < MIN_LENGTH) {
            addNotification("notification.subtitleInvalid");
            return subs;
        }
    }

    if ((subNew.end - subNew.start) < MIN_LENGTH || subNew.end < subNew.start) {
        addNotification("notification.subtitleInvalid");
        return subs;
    }

    subs.splice(Math.max(0, Math.min(subs.length, index)), 0, subNew);
    return subs;
}

export const getReadabilityScore = (subtitle) => {
    if (subtitle == null) return 0;
    const words = subtitle.text.split(" ").length; // Count the words
    const maxLength = parseFloat(words * 0.3); // Default score for readable subtitle per word
    const duration = parseFloat(subtitle.end - subtitle.start); // Calculcate the duration
    return duration - maxLength;
}

export const generateReport = (subtitles) => {
    if (subtitles == null) return [];
    const subs = [...subtitles];
    var subtitlesWithSmallGabs = 0;
    var subtitlesToShort = [];

    for (let i = 1, il = subs.length; i < il; i++) {
        const left = subs[i - 1];
        const right = subs[i];
        const distance = parseFloat(right.start - left.end);
        if (distance > 0 && distance < 0.5) subtitlesWithSmallGabs ++;

        const score = getReadabilityScore(left);
        if (score < 0) subtitlesToShort.push({
            subtitle: left,
            distance: distance
        });
    }

    return {subtitlesWithSmallGabs, subtitlesToShort};
}

export const snapSubtitles = (subtitles) => {
    if (subtitles == null) return [];
    const subs = [...subtitles];

    for (let i = 1, il = subs.length; i < il; i++) {
        const left = subs[i - 1];
        const right = subs[i];
        const distance = parseFloat(right.start - left.end);

        if (distance > 0 && distance < 0.5) {
            const center = distance / 2;
            subs[i - 1] = {...left};
            subs[i - 1].end = Math.min(left.end + center, parseFloat(right.start) + 0.001);

            subs[i] = {...right};
            subs[i].start = Math.max(right.start - center, parseFloat(left.end) + 0.001);
        }
    }

    return subs;
}

export const insertSubtitle = (subtitles, time) => {
    if (subtitles == null) return [];
    const subs = [...subtitles];
    const from = time;
    const to = time + MIN_LENGTH;
    var inserted = false;

    // Check if there are subtitles colliding with the inserted subtitle
    for (let i = subs.length - 1; i >= 0; i--) {
        var sub = subs[i];

        const collidesWithFrom = (sub.start - from) < 0 && (sub.end - from) > 0;
        const collidesWithTo = (sub.start - to) < 0 && (sub.end - to) > 0;

        // New subtitle is inside a other subtitle
        if (collidesWithFrom && collidesWithTo) {
            const distFrom = from - sub.start;
            const distTo = sub.end - to;

            subs[i] = {...sub}; // Clone object before modifing to remove reference
            distFrom > distTo ? subs[i].end = from : subs[i].start = to;
            inserted = true;
        } else if (collidesWithFrom) {
            subs[i] = {...sub}; // Clone object before modifing to remove reference
            subs[i].end = from;
            inserted = true;
        } else if (collidesWithTo) {
            subs[i] = {...sub}; // Clone object before modifing to remove reference
            subs[i].start = to;
            inserted = true;
        }
    }

    if (!inserted) {
        for (let i = subs.length - 1; i >= 0; i--) {
            if (time >= subs[i].start && time <= subs[i].end) {
                addNotification("notification.subtitleInvalid");
                return subtitles;
            }
        }
    }

    subs.push(createSubtitle(PLACEHOLDER, Math.max(0, from), to));
    return sortSubtitles(subs);
}

export const mergeSubtitles = (subtitles, a, b) => {
  if (subtitles == null) return [];
  const subs = [];

  if (a < 0 || b < 0) return subtitles;
  if (a >= subtitles.length && b >= subtitles.length) return subtitles;

  const min = Math.min(a, b);
  const max = Math.max(a, b);

  var start = 0;
  var end = 0;
  var text = "";

  for (let i = 0, il = subtitles.length; i < il; i++) {
    if (i >= min && i <= max) {
      if (i === min) start = subtitles[i].start;
      if (i === max) end = subtitles[i].end;
      text += " " + subtitles[i].text;
    } else {
      subs.push({...subtitles[i]});
    }
  }

  subs.push(createSubtitle(text.trim(), start, end));
  return sortSubtitles(subs);
}

export const spliceSubtitles = (subtitles, time, position) => {
  if (subtitles == null || time == null || position == null) return [...subtitles];
  const subs = [...subtitles];

  for (let i = subs.length - 1; i >= 0; i--) {
      const sub = subs[i];

      if (time >= sub.start && time <= sub.end && i < sub.text.length) {
          const words = [sub.text.substring(0, position), sub.text.substring(position)];
          
          // There is not enough time to insert an new subtitle
          if ((time - sub.start) < 0.25 || (sub.end - time) < 0.25) {
              addNotification("notification.subtitleInvalid");
              return subs;
          }

          subs.splice(i, 1);
          subs.push(createSubtitle(words[0].trim(), sub.start, time));
          subs.push(createSubtitle(words[1].trim(), time, sub.end));
      }
  }

  return sortSubtitles(subs);
}

export const cutSubtitles = (subtitles, time) => {
    if (subtitles == null) return [];
    const subs = [...subtitles];

    for (let i = subs.length - 1; i >= 0; i--) {
        const sub = subs[i];

        if (time >= sub.start && time <= sub.end) {
            const words = sub.text.split(" ");
            const wordsPercentage = (time - sub.start) / (sub.end - sub.start);
            const wordsCutIndex = Math.min(words.length, parseInt(words.length * wordsPercentage));

            // There is not enough time to insert an new subtitle
            if ((time - sub.start) < 0.25 || (sub.end - time) < 0.25) {
                addNotification("notification.subtitleInvalid");
                return subs;
            }

            subs.splice(i, 1);
            subs.push(createSubtitle(words.slice(0, wordsCutIndex).join(" "), sub.start, time));
            subs.push(createSubtitle(words.slice(wordsCutIndex, words.length).join(" "), time, sub.end));
        }
    }

    return sortSubtitles(subs);
}

export const generateSubtitleId = () => {
    return Math.random().toString(16).slice(-12)
}

export const createSubtitle = (text = PLACEHOLDER, start = 0, end = 1, uuid = generateSubtitleId()) => {
    if (text == null) text = PLACEHOLDER;
    return {
        uuid: uuid,
        confidence: 0,
        lines: [""],
        content: "",
        text: text,
        type: "caption",
        start: start,
        end: end,
        words: 0
    }
}

export const sortSubtitles = (subtitles) => {
    if (subtitles == null) return [];
    const subs = [...subtitles];
    subs.sort((a, b) => {
        if (a.start < b.start) return -1;
        if (a.start > b.start) return 1;
        return 0;
    });
    return subs;
}

export const getSubtitleAtIndex = (subtitles, index) => {
    if (subtitles == null) return [];
    if (index >= 0 && index < subtitles.length) return subtitles[index];
}
