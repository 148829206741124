import React from 'react'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Project'

import Modal, { ModalHeader, ModalBody, ModalFooter } from 'shared/components/Modal'

import { generateReport } from 'shared/utils/subtitle'

export const ModalReport = React.memo((props) => {
    const { project, projectFunctions } = React.useContext(ProjectContext);
    const report = generateReport(project.subtitles);
    const { subtitlesWithSmallGabs } = report;

    const subs = report.subtitlesToShort.map((r, index) =>
        <li key={index}>{r.subtitle.content}</li>
    );

    return (
        <Modal modalStyle='small' open={props.open} onClose={() => { props.setOpen(false) }}>
            <ModalHeader><span><Message id="modal.report" /></span></ModalHeader>
            {report.subtitlesToShort.length ? <ModalBody>
                <div>
                  <div><b><Message id="modal.report.readability" /></b></div>
                  <div><Message id="modal.report.readability.text" /></div>
                </div>
                <ol>
                {subs}
                </ol>
            </ModalBody> : null }
            {subtitlesWithSmallGabs > 0 ? <ModalBody>
                <div>
                  <div><b><Message id="modal.report.gab" /></b></div>
                  <div><Message id="modal.report.gab.text" /></div>
                </div>
            </ModalBody> : null }
            {(subtitlesWithSmallGabs <= 0 && report.subtitlesToShort.length <= 0) ? <ModalBody>
                <div>
                  <div><Message id="modal.report.none" /></div>
                </div>
            </ModalBody> : null }
            <ModalFooter>
                {subtitlesWithSmallGabs > 0 ? <button className="primary" onClick={() => { projectFunctions.snapSubtitles() }}><Message id="button.subtitleSnap" /></button> : null}
                <button className="secondary" onClick={() => { props.setOpen(false) }}><Message id="button.continue" /></button>
            </ModalFooter>
        </Modal>
    );
});
