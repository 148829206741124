import React from 'react'

import css from './index.module.css'

import { ProjectContext } from 'App/pages/Project'
import { SizeContext, WidthContext } from 'App/pages/Project/Timeline'

import { ELEMENTS } from 'shared/constants/nav'

const TimelineThumbnails = React.memo((props) => {
    const { project } = React.useContext(ProjectContext);
    const { size } = React.useContext(SizeContext);
    const { width } = React.useContext(WidthContext);

    if (props.navigation !== ELEMENTS.THUMBNAILS) return null;

    const indicatorList = project.thumbnails.map((t, index) =>
        <div className={css.thumbnail} key={index} style={{ left: (((t.time) * size) - 1 + "px"), backgroundImage: ("url(" + t.image + ")") }}>
            <svg width="15px" height="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z" /></svg>
        </div>
    );

    return (
        <div className={css.thumbnailsWrapper} style={{ width: width + "px" }}>
            <div className={css.thumbnails}>
                {indicatorList}
            </div>
        </div>
    );
});

export default TimelineThumbnails;
