import React, { useState } from 'react'
import { useHistory } from "react-router-dom"

//import Cropper from 'cropperjs';
//import 'cropperjs/dist/cropper.css';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Project'
import { MediaContext } from 'App/pages/Project/Media'

import { OptionButtonWrapper, OptionButtonGroup, OptionButton, CloseButton } from 'shared/components/Button'
import { IconSpinner, IconCut, IconSubtitleGenerate } from 'shared/components/Icon'
import { Listener } from 'shared/components/Spinner'

import { formatTime } from 'shared/utils/time'

import { callRequestSubtitles, callStopSubtitles } from 'shared/hooks/Api'

const Preview = React.memo((props) => {
  const { media } = React.useContext(MediaContext);
  const { options } = React.useContext(ProjectContext);
  const [buffering, setBuffering] = React.useState(false);

  React.useEffect(() => {
    if (media !== null && media.length > 0) {
      const buffer = () => {
        setBuffering(true);
      }
      const unbuffer = () => {
        setBuffering(false);
      }

      media[0].addEventListener('waiting', buffer);
      media[0].addEventListener('canplay', unbuffer);

      return () => {
        media[0].removeEventListener('waiting', buffer);
        media[0].removeEventListener('canplay', unbuffer);
      }
    }
  });

  if (options.mode === 'DOCUMENT') {
    return <Document />;
  }

  return (
    <div className={css.wrapper}>
      <div className={css.canvas + (options.loopHighlight ? (' ' + css.highlighted) : '')} ref={props.previewRef}>
        <PreviewCanvasMemo ref={props.canvasRef} sheet={props.sheet}/>
        <PreviewSubtitleMemo ref={props.subtitleRef} format={props.format} />
        <Buffer active={buffering} ><IconSpinner height="30" width="30" />Buffering...</Buffer>
      </div>
    </div>
  );
});

export default Preview;

const Document = React.memo((props) => {
  const { project, projectFunctions } = React.useContext(ProjectContext);
  const [creating, setCreating] = React.useState(project.status.indexOf("TRANS") !== -1);

  const history = useHistory();

  const toDashboard = () => {
      history.push('/');
  }

  React.useEffect(() => {
    setCreating(project.status.indexOf("TRANS") !== -1);
  }, [project.status]);

  function onSubtitleRequest() {
    if (creating) return;
    setCreating(true);

    callRequestSubtitles(project.name, (subtitles) => {
      if (subtitles != null) projectFunctions.setSubtitles(subtitles);
    });
  }

  function cancelSubtitleRequest() {
    if (!creating) return;
    setCreating(false);

    callStopSubtitles(project.name, () => {
      // TODO: Nothing...
    });
  }

  if (creating) {
      const idle = project.status !== 'TRANSCRIBING';

      return (
          <>
            <div className={css.document}>
              <div className={css.documentHeader}>
                <div className={css.documentName}>{project.name}</div>
                <div className={css.documentClose}><CloseButton onClose={toDashboard} /></div>
              </div>
              <div className={css.documentBody}>
                <div className={css.placeholder}>
                    <Listener idle={idle} height={50} width={50} />
                    <span>{idle ? <Message id="status.transcript.queue" /> : <Message id="status.transcript.process" />}</span>
                    <span>{idle ? <Message id="status.transcript.queue.description" /> : <Message id="status.transcript.process.description" />}</span>
                    <button className="secondary" onClick={cancelSubtitleRequest}><Message id="button.cancel" /></button>
                  </div>
                </div>
            </div>
          </>
      );
  }

  if (project.subtitles == null || project.subtitles.length <= 0) {
    return (
      <div className={css.document}>
        <div className={css.documentHeader}>
          <div className={css.documentName}>{project.name}</div>
          <div className={css.documentClose}><CloseButton onClose={toDashboard} /></div>
        </div>
        <div className={css.documentBody}>
          <OptionButtonWrapper>
              <OptionButtonGroup>
                  <OptionButton optionStyle='recommended' handleClick={() => onSubtitleRequest() }>
                      <IconSubtitleGenerate width={50} height={50} />
                      <span><Message id="box.transcribe"/></span>
                      <span><Message id="box.transcribe.description"/></span>
                  </OptionButton>
              </OptionButtonGroup>
          </OptionButtonWrapper>
        </div>
      </div>
    );
  }

  var alternative = project.alternative;
  var alternativeBlocks = 0;
  var alternativeCache = "";
  
  if (alternative == null || typeof alternative !== 'string' || alternative.replace(/<[^>]*>?/gm, '').length <= 1) {
    alternative = "";

    var last = 0;

    var from = 0;
    var to = 0;

    for (let i = 0, il = project.subtitles.length; i < il ;i++) {
      const subtitle = project.subtitles[i];

      var t = "";
      var words = subtitle.text.split(" ");

      if (subtitle.words != null) {
         for (let i = 0, il = words.length; i < il; i++) {
            if (i < subtitle.words.length) {
              const word = subtitle.words[i];

              if (alternativeBlocks === 0) {
                from = word.startTime;
              }

              to = word.endTime;

              if (word.word === words[i] && word.confidence < 0.8) {
                t += (`<mark title="${Math.round(word.confidence * 100)}%">${words[i]}</mark> `);
              } else {
                t += (words[i] + " ");
              }
            } else {
              t += words[i] + " ";
            }
         }
      }

      alternativeCache += ` ${t}`;
      alternativeBlocks ++;

      if (i === 0 || alternativeBlocks > 10 || (subtitle.start - last) > 0.5) {
        if (i !== 0) {
          alternative += '<div><br></div>';
        }

        alternative += `<i>${formatTime(from)} - ${formatTime(to)}</i><br>`;
        alternative += ` ${alternativeCache}`;

        alternativeCache = "";
        alternativeBlocks = 0;
      }

      last = subtitle.end;
    }
  }

  return (
    <div className={css.document}>
      <div className={css.documentHeader}>
        <div className={css.documentName}>{project.name}</div>
        <div className={css.documentClose}><CloseButton onClose={toDashboard} /></div>
      </div>
      <div className={css.documentBody}>
        <TextEditor
          id="mediaIndex"
          key="subtitle.uuid"
          className={css.text}
          enabled={true}
          onClick={(e) => { }}
          onChange={(e) => { projectFunctions.setAlternative(e.html) }}
          text={alternative}
          value={alternative}
          >
        </TextEditor>
      </div>
    </div>
  );
});

const Buffer = ({active, children}) => {
  return (<div className={css.buffer + (active ? (' ' + css.active) : '')}>{children}</div>);
};

const PreviewCanvas = React.forwardRef((props, ref) => {
  const { project, projectFunctions } = React.useContext(ProjectContext);
  //const [ currentHeight, setCurrentHeight ] = useState();
  const [cropper, setCropper] = useState(false)
  const [crop, setCrop] = useState({ unit: '%',
  x: 0,
  y: 0,
  width: 100,
  height: 33,
})
  
  React.useEffect(() => {
    let width, height;
    if (props.sheet === 'thumbnails' && project.fileInformation.format.streams && project.fileInformation.format.streams.length > 0) {
      for (let i = 0, il = project.fileInformation.format.streams.length; i < il; i++) {
        let stream = project.fileInformation.format.streams[i];
        
        if (stream.side_data_list && Math.abs(stream.side_data_list[0].rotation)) {
          if (stream.width && stream.height) {
              width = stream.height;
              height = stream.width;
          }
      } else if (stream.width && stream.height) {
          width = stream.width;
          height = stream.height;
      }

      if (width < height){
        setCropper(true)
      } 
    }} else {
      setCropper(false) 
    }
    //setCurrentHeight(ref.current.height)

    projectFunctions.submitCurrentY(crop.y/ref.current.height)
  }, [projectFunctions, props.sheet, project.fileInformation.format.streams, crop, ref]);

  return (
    cropper ? (
      <ReactCrop crop={crop} onChange={newCrop => setCrop(newCrop)} locked aspect="16/9" >
        <canvas id="canvas" ref={ref}></canvas>
      </ReactCrop>
    ) : (
      <canvas id="canvas" ref={ref}></canvas>
    )
  );
});

const PreviewCanvasMemo = React.memo(PreviewCanvas);


// TODO: This was copied from /Sheet/Subtitles/index.js... Create one component that is used by both classes
class TextEditor extends React.Component {
  constructor(props) {
      super(props);
      this.el = React.createRef();
      this.ref = React.createRef();
      this.onChange = this.onChange.bind(this);
      this.onClick = this.onClick.bind(this);

      this.normalizeHtml = (str) => {
        return str && str.replace(/(<([^>]+)>)/gi, "");
      }
  }
  

  onChange(){
        var html = this.ref.current.innerHTML;

        if (this.props.onChange && html !== this.lastHtml) {
            this.props.onChange({html: this.ref.current.innerHTML, text: this.ref.current.textContent});
        }

        this.lastHtml = html;
    }

    onClick() {
      if (this.props.onClick) this.props.onClick();
    }

    shouldComponentUpdate(nextProps){
      if (document.activeElement && document.activeElement.id && document.activeElement.id === this.props.id) return false;
      return (this.normalizeHtml(nextProps.text) !== this.normalizeHtml(this.ref.current.textContent)) ? true : false;
    }

     componentDidUpdate() {
        if (this.props.value !== this.ref.current.textContent) {
           this.ref.current.innerHTML = this.props.text;
        }
    }

  render() {
      const { enabled, id, style, className, value, splice } = this.props;

      return (
          <div className={css.subtitlePreview}>
              <div
                  autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                  contentEditable={enabled}
                  id={id}
                  style={style}
                  className={className}
                  dangerouslySetInnerHTML={{ __html: value }}
                  ref={this.ref}
                  onInput={this.onChange}
                  onClick={this.onClick}
              />
              <div className={css.subtitleCut}>
                <div className={css.subtitleCutButton} onClick={() => { splice(this.ref) }}><IconCut width="15px" height="15px" /></div>
              </div>
          </div>
      )
    }
  }

const PreviewSubtitle = React.forwardRef((props, ref) => {
  const { media, mediaFunctions, mediaIndex } = React.useContext(MediaContext);
  const { projectFunctions } = React.useContext(ProjectContext);
  const { project } = React.useContext(ProjectContext);

  const subtitles = projectFunctions.getSubtitles();
  const subtitle = subtitles[mediaIndex];

  const splice = React.useCallback(() => {
    const position = window.getSelection().anchorOffset;
    if (position < 0) return;

    const time = media[0].currentTime;
    projectFunctions.spliceSubtitles(time, position);
  }, [media, projectFunctions]);

  const [isPortrait, setIsPortrait] = useState('portraitStyles');
  
  React.useEffect(() => {
    if (project.fileInformation.format.streams && project.fileInformation.format.streams.length > 0) {
      for (let i = 0, il = project.fileInformation.format.streams.length; i < il; i++) {
        let stream = project.fileInformation.format.streams[i];
        let width, height;

        if (stream.side_data_list && Math.abs(stream.side_data_list[0].rotation)) {
          if (stream.width && stream.height) {
              width = stream.height;
              height = stream.width;
          }
      } else if (stream.width && stream.height) {
          width = stream.width;
          height = stream.height;
      }

      if ( width > height ){
        setIsPortrait('landscapeStyles')
    } else if ( width < height ){
      setIsPortrait('portraitStyles')
    }
      }}
  },[project.fileInformation.format.streams])

  React.useEffect(() => {
      const registerShortcuts = (e) => {
          if (e.altKey && e.key === 's' && window.getSelection().anchorNode.className === undefined) {
              splice();
          }
      }

      window.addEventListener('keydown', registerShortcuts);

      return (() => {
          window.removeEventListener('keydown', registerShortcuts);
      });
  }, [splice, media, projectFunctions]);

  if (subtitle == null) return null;

  const applyHighlights = (text) => {
    var t = "";
    var words = subtitle.text.split(" ");

     if (subtitle.words != null) {
       for (let i = 0, il = words.length; i < il; i++) {
         if (i < subtitle.words.length) {
           const word = subtitle.words[i];

           if (word.word === words[i] && word.confidence < 0.8) {
             t += (`<mark title="${Math.round(word.confidence * 100)}%">${words[i]}</mark> `);
           } else {
             t += (words[i] + " ");
           }
         } else {
           t += words[i] + " ";
         }
       }
     }

    return t.trim();
  }

  return (
    <div className={css.subtitle}>
      <TextEditor
        id={mediaIndex}
        key={isPortrait}
        className={`${css.textArea} ${css[isPortrait]}`}
        enabled={true}
        onClick={() => { mediaFunctions.pause() }}
        onChange={(e) => { projectFunctions.modifySubtitle(mediaIndex, { text: e.text }, true, 500) }}
        text={subtitle.text}
        value={applyHighlights(subtitle.text)}
        splice={splice}
        ref={ref}
        >
      </TextEditor>
    </div>
  );
});

const PreviewSubtitleMemo = React.memo(PreviewSubtitle);
