import React from 'react'

import { Message } from 'shared/hooks/Translation'

import { IconManuell, IconDirectUpload, IconSubtitleGenerate } from 'shared/components/Icon'
import { OptionButtonWrapper, OptionButtonGroup, OptionButton, ProgressBar } from 'shared/components/Button'
import Modal, { ModalHeader, ModalBody, ModalFooter, ModalMessage } from 'shared/components/Modal'

export const ModalOption = React.memo((props) => {
    const isAudio = (props.file != null && props.file.type != null && props.file.type.toLowerCase().indexOf('audio') !== -1);

    if (props.id && props.id.length > 0) {
      return (
        <Modal modalStyle="large" open={props.open} onClose={() => { props.setOpen(false) }}>
            <ModalHeader><span><Message id="modal.quickUpload" /></span></ModalHeader>
            <ModalBody>
                <ModalMessage><span><Message id="modal.quickUpload.override" /></span></ModalMessage>
                <OptionButtonWrapper>
                    <OptionButtonGroup>
                        <OptionButton handleClick={() => { props.openProject(props.id) }}>
                            <IconDirectUpload width={50} height={50} />
                            <span><Message id="box.uploadLoad" /></span>
                            <span><Message id="box.uploadLoad.description" /></span>
                        </OptionButton>
                        <OptionButton handleClick={() => { props.setId("") }}>
                            <IconDirectUpload width={50} height={50} />
                            <span><Message id="box.uploadOverride" /></span>
                            <span><Message id="box.uploadOverride.description" /></span>
                        </OptionButton>
                    </OptionButtonGroup>
                </OptionButtonWrapper>
            </ModalBody>
            <ModalFooter>
                <button className="clear" onClick={() => { props.setOpen(false) }}><Message id="button.close"/></button>
            </ModalFooter>
        </Modal>
      );
    }

    if (props.progress > 0) {
        return (
            <Modal modalStyle="large" open={props.open} onClose={() => { props.setOpen(false) }}>
                <ModalHeader>
                  <span>{parseInt(props.progress) + "%"}</span>
                </ModalHeader>
                <ModalBody>
                  <ProgressBar progress={props.progress}/>
                </ModalBody>
                <ModalFooter>
                    <button className="clear" onClick={() => { props.setOpen(false) }}><Message id="button.cancel" /></button>
                </ModalFooter>
            </Modal>
        );
    }

    if (isAudio) {
      // Skip the modal by instant processing the file without waiting for the click
      if (props.open) {
        props.processFile(2, true);
      }

      return (
          <Modal modalStyle="large" open={props.open} onClose={() => { props.setOpen(false) }}>
              <ModalHeader><span><Message id="modal.quickUpload" /></span></ModalHeader>
              <ModalBody>
                  <OptionButtonWrapper>
                      <OptionButtonGroup>
                          <OptionButton optionStyle='recommended' handleClick={() => { props.processFile(2, true) }}>
                              <IconSubtitleGenerate width={50} height={50} />
                              <span><Message id="box.transcribe" /></span>
                              <span><Message id="box.transcribe.description" /></span>
                          </OptionButton>
                      </OptionButtonGroup>
                  </OptionButtonWrapper>
              </ModalBody>
              <ModalFooter>
                  <button className="clear" onClick={() => { props.setOpen(false) }}><Message id="button.close"/></button>
              </ModalFooter>
          </Modal>
      );
    }

    return (
        <Modal modalStyle="large" open={props.open} onClose={() => { props.setOpen(false) }}>
            <ModalHeader><span><Message id="modal.quickUpload" /></span></ModalHeader>
            <ModalBody>
                <OptionButtonWrapper>
                    <OptionButtonGroup>
                        <OptionButton optionStyle='recommended' handleClick={() => { props.processFile(2, true) }}>
                            <IconSubtitleGenerate width={50} height={50} />
                            <span><Message id="box.uploadHalf" /></span>
                            <span><Message id="box.uploadHalf.description" /></span>
                        </OptionButton>
                    </OptionButtonGroup>
                    <OptionButtonGroup>
                        <OptionButton handleClick={() => { props.processFile(3, false) }}>
                            <IconDirectUpload width={50} height={50} />
                            <span><Message id="box.uploadPublish" /></span>
                            <span><Message id="box.uploadPublish.description" /></span>
                        </OptionButton>
                        <OptionButton handleClick={() => { props.processFile(0, true) }}>
                            <IconManuell width={50} height={50} />
                            <span><Message id="box.upload" /></span>
                            <span><Message id="box.upload.description" /></span>
                        </OptionButton>
                    </OptionButtonGroup>
                </OptionButtonWrapper>
            </ModalBody>
            <ModalFooter>
                <button className="clear" onClick={() => { props.setOpen(false) }}><Message id="button.close"/></button>
            </ModalFooter>
        </Modal>
    );
});
