import React from 'react'
import ReactDom from 'react-dom'

import css from './index.module.css'

import { CloseButton } from 'shared/components/Button'

export default function Modal({ open, children, onClose = null, modalStyle = 'default' }) {
    const [stage, setStage] = React.useState(-1);
    if (open && stage !== 1) setStage(1);
    if (!open && stage === 1) { setStage(2); setTimeout(() => { setStage(-1); }, 250) }
    if (!open && stage === -1) return null;

    return ReactDom.createPortal(
        <>
            <div className={css.overlay + ((stage === 2) ? (' ' + css.closing) : '')} />
            <div className={css.wrapper + (modalStyle === 'large' ? (' ' + css.large) : (modalStyle === 'small' ? (' ' + css.small) : ''))}>
                <div className={css.container + ((stage === 2) ? (' ' + css.closing) : '')}>
                    <CloseButton onClose={onClose} />
                    <div className={css.content}>
                        {children}
                    </div>
                </div>
            </div>
        </>,
        document.getElementById('root')
    )
}

export function ModalMessage({ children }) {
    return (<div className={css.message}>{children}</div>);
}

export function ModalFeature({ children, title, text }) {
    return (
        <div className={css.feature}>
            {children}
        </div>
    );
}

export function ModalFeatureRight({ children }) {
    return (<div className={css.featureRight}>{children}</div>);
}

export function ModalFeatureLeft({ children }) {
    return (<div className={css.featureLeft}>{children}</div>);
}

export function ModalHeader({ children }) {
    return (<div className={css.header}>{children}</div>);
}

export function ModalBody({ children }) {
    return (<div className={css.body}>{children}</div>);
}

export function ModalFooter({ children }) {
    return (<div className={css.footer}>{children}</div>);
}
