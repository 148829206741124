import React from 'react'
import { useHistory } from "react-router-dom"

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Download'

import Modal, { ModalHeader, ModalBody, ModalFooter } from 'shared/components/Modal'

import Player from '../Player'

import { milliToDate } from 'shared/utils/time'
import { callStopRender } from 'shared/hooks/Api'

const Preview = React.memo((props) => {
  const { project } = React.useContext(ProjectContext);
  const history = useHistory();

  const stop = () => {
    callStopRender(project.name, (json) => {
      history.push('/');
    });
  }

  const rendered = project.status === 'RENDERED' && project.renderUrls.length > 0;

  return (
    <>
      <div className={css.header}></div>
      <div className={css.body}>
        {rendered ? <Player videoRef={props.videoRef}/> : <Progress percentages={props.percentages} instruction={props.instruction} queue={props.queue}/>}
      </div>
      <div className={css.footer}>
        <div className={css.detailName}>
          <span>{project.name || 'N/A'}</span>
          <span>{milliToDate(project.createAt || 0)}</span>
        </div>
        {project.status === "RENDERING" ? (<div className={css.detailButton}><button className="secondary" onClick={stop}><Message id="button.cancel" /></button></div>) : null}
      </div>
    </>
  );
});

export default Preview;

const Progress = (props) => {
  const { project } = React.useContext(ProjectContext);

  var percentage = props.percentages.cur;
  var instruction = props.instruction;
  var thumbnail = "url(" + (project.thumbnail ?? '') + ")";

  if (instruction == null) {
    instruction = {
      cur: 0,
      total: 0,
      percentage: 0
    }
  }
  
  var files = 0;
  files += (project.renderUrls) ? project.renderUrls.length : 0;
  files += (project.thumbnailUrls) ? project.thumbnailUrls.length : 0;

  var noFiles = project.status === 'RENDERED' && files <= 0;

  return (
    <>
      <ModalWarning open={noFiles}/>
      <div className={css.progress} style={{backgroundImage: thumbnail}}>
        <div className={css.progressOverlay}></div>
        <div className={css.progressElements}>
          {!noFiles && instruction.total >= 0 ? <div className={css.steps}><span>{instruction.cur} / {instruction.total}</span></div> : null}
          <div className={css.progressText}>
            <span>{(!noFiles && props.queue >= 0) ? <Message id="status.rendering" count={props.queue + 1} /> : ((noFiles || instruction.percentage < 0) ? "" : (instruction.percentage + "%"))}</span>
            <span>{(!noFiles && props.queue >= 0) ? <Message id="status.rendering.description" /> : (percentage < 0 ? "" : <Message id="status.rendering.description" />)}</span>
          </div>
          {!noFiles && percentage >= 0 ? <div className={css.instruction}><span>{percentage}%</span></div> : null}
          {project.status === 'RENDERED' ?
            null :
            <div className={css.progressBarWrapper}>
              <div className={css.progressBar + (percentage > 100 ? (' ' + css.striped) : ('') )} style={{width: percentage + "%"}}></div>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export const ModalWarning = React.memo((props) => {
    const { project } = React.useContext(ProjectContext);
    const history = useHistory();

    const toDashboard = () => {
        history.push('/');
    }
    const toProject = () => {
        history.push('/project/' + project.name);
    }

    return (
        <Modal modalStyle="small" open={props.open} onClose={toDashboard}>
            <ModalHeader><span><Message id="modal.corruptedRender" /></span></ModalHeader>
            <ModalBody><span><Message id="modal.corruptedRender.text" /></span></ModalBody>
            <ModalFooter>
                <button className="primary" onClick={toProject}><Message id="button.editorOpen" /></button>
                <button className="clear" onClick={toDashboard}><Message id="button.back" /></button>
            </ModalFooter>
        </Modal>
    );
});
