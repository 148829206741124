import React from 'react'
import de from './de.json';
import en from './en.json';

export const dictionaryList = { de, en };
export const languageOptions = {
    de: 'Deutsch',
    en: 'English'
}

export function Message({ id, count, variable }) {
    const languageContext = React.useContext(LanguageContext);

    // If the input is not a string, then return it! It could be message component on its own
    if (typeof id !== 'string') {
        return id;
    }

    // Check if there is a count that
    if (count != null && (Number.isInteger(count) || Number.isFloat(count))) {
        return (languageContext.dictionary[id + ((count === 1) ? '' : '_plural')] || id).replaceAll('{{count}}', count);
    }
    if (variable != null && (typeof (variable) === 'string')) {
        return (languageContext.dictionary[id] || id).replaceAll('{{variable}}', variable);
    }

    // Get message from dictionary
    return languageContext.dictionary[id] || id;
}

export const LanguageContext = React.createContext({
    userLanguage: 'de',
    dictionary: dictionaryList.de
});

export function LanguageProvider({ children }) {
    const defaultLanguage = window.localStorage.getItem('rcml-lang');
    const [userLanguage, setUserLanguage] = React.useState(defaultLanguage || 'de');

    const provider = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        message: id => {
            return dictionaryList[userLanguage][id] || id;
        },
        userLanguageChange: selected => {
            const newLanguage = languageOptions[selected] ? selected : 'de'
            setUserLanguage(newLanguage);
            window.localStorage.setItem('rcml-lang', newLanguage);
        }
    }

    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );
}
