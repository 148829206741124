import { addNotification } from 'shared/components/Notification';

import { logout } from 'shared/hooks/Auth';

import config from 'shared/constants/config'

export var URL_API = config.urls.api;
export var URL_AUTH = config.urls.auth;
export var URL_CDN = config.urls.cdn;

const pendingRequests = [];

export const isPending = (method = "") => {
  const date = pendingRequests['callHighlightDetection'];

  if (date == null) {
    return false;
  }

  // Difference is within 1 minute (60000 milliseconds)
  return (new Date() - date) < 60000;
}


export const getKey = () => {
    // TODO: Not used anymore
    return true;
}

// Middleware that checks the response of a request
const checkResponse = (response) => {
    if (response.status === 401) {
        logout(() => {
            addNotification('notification.connectionLost');
        });
        console.log(`Logged out due to an 401 status`);
    }

    return response;
}

// Middleware that checks the data of a request
const checkData = (data, callback) => {
    if (data.type && data.type === 'INVALID_KEY') {
        logout(() => {
            addNotification('notification.connectionLost');
        });
        console.log(`Logged out due to an invalid key`);
    }

    if (callback != null) callback(data);
    return data;
}

// Middleware that checks the error of a request
const checkError = (error, callback) => {
    if (callback != null) callback(error);
}

const secureParseJson = (data) => {
    try {
        return JSON.parse(data);
    } catch (error) {
        console.log(error);
        return {};
    }
}

const connections = [];

// Check if the tab is visible or not due to limited connection at once for each browser
document.addEventListener('visibilitychange', () => {
  if (document.hidden) {
    // Close all connection when hidden
    for (let i = 0, il = connections.length; i < il; i++) connections[i].close(false);
  } else {
    // Open all connection when visible
    for (let i = 0, il = connections.length; i < il; i++) connections[i].open();
  }
});

const subscribe = (url, onMessage) => {
    const eventSource = {
      sse: null,
      // Close will called when subscription is closed by a component
      close: (unregister = true) => {
        if (eventSource.sse == null) return;
        eventSource.sse.close();
        eventSource.sse = null;

        if (unregister) {
          const index = connections.indexOf(eventSource);
          if (index !== -1) connections.splice(index, 1);
        }
      },
      open: () => {
        eventSource.sse = new EventSource(URL_API + url, { withCredentials: true });
        eventSource.sse.onmessage = (e) => {
          onMessage(checkData(secureParseJson(e.data)));
        }
        eventSource.sse.onerror = (e) => checkError(e, () => {
          // Closing will prevent reconnecting
          // sse.close();
          console.log(e);

          // Event source could not handle error to provide enough informations about validation
          fetch(URL_API + '/', {
              method: "GET",
              credentials: 'include',
              headers: {
                  "x-ffh-key": getKey()
              }
          })
          .then(response => {
            // Check if access to the api is denied and then log out
            if (response.status === 401) {
              logout(() => {
                  addNotification('notification.connectionLost');
              });
            }
          });
        });
      }
    }

    // Open the connection
    eventSource.open();
    // Save event source to connections array
    connections.push(eventSource);

    return eventSource;
}

export const callLogout = (onLoad, onError) => {
    fetch(URL_AUTH + '/logout', {
        method: "POST",
        credentials: 'include',
        headers: {
            "x-ffh-key": getKey(),
            'Content-Type': 'application/json'
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error, onError));
}

export const callLogin = (name, password, onLoad, onError) => {
    fetch(URL_AUTH + '/login', {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify({
            name: name,
            password: password
        }),
        headers: {
            "x-ffh-key": getKey(),
            'Content-Type': 'application/json'
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error, onError));
}

export const callSaveProject = (name, data, onLoad, onError) => {
    // fetch(URL_API + '/project/' + name, {
    //     method: "PUT",
    //     body: JSON.stringify(data),
    //     credentials: 'include',
    //     headers: {
    //         "x-ffh-key": getKey(),
    //         'Content-Type': 'application/json'
    //     }
    // })
    // .then(response => checkResponse(response.json()))
    // .then(data => checkData(data, onLoad))
    // .catch(error => checkError(error, onError));

    var xhrTimeout = 0;
    var xhr = new XMLHttpRequest();

    xhr.onload = (e) => {
      checkData(JSON.parse(xhr.response), onLoad);
      clearTimeout(xhrTimeout);
    }

    xhr.open("PUT", URL_API + '/project/' + name);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.setRequestHeader("x-ffh-key", getKey());
    xhr.withCredentials = true;
    xhr.send(JSON.stringify(data));

    xhrTimeout = setTimeout(() => {
        if (xhr.status !== 200) {
          xhr.abort();
          onError(xhr.status);
        }
    }, 2000);
}

export const subscribeStatusNotification = (onMessage) => {
    return subscribe('/status/notification', onMessage);
}

export const subscribeStatusUpdate = (onMessage) => {
    return subscribe('/status/update', onMessage);
}

export const callDeleteProject = (name, onLoad) => {
    fetch(URL_API + '/project/' + name, {
        method: "DELETE",
        credentials: 'include',
        headers: {
            "x-ffh-key": getKey()
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const callRemoteRemoveSubtitles = (file, onLoad) => {
    fetch(URL_API + '/subtitle/remoteRemoveSubtitles', {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify({
          id: file
        }),
        headers: {
            "x-ffh-key": getKey(),
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.status !== 200 ? false : true)
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const callDownloadSubtitleFormat = (file, format, onLoad) => {
    fetch(URL_API + '/subtitle/' + file + '/' + format, {
        method: "GET",
        credentials: 'include',
        headers: {
            "x-ffh-key": getKey()
        }
    })
    .then(response => response.status !== 200 ? "" : response.text())
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const callStopSubtitles = (name, onLoad) => {
    fetch(URL_API + '/transcribe/' + name + '/stop', {
        method: "GET",
        credentials: 'include',
        headers: {
            "x-ffh-key": getKey()
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const callRequestSubtitles = (file, onLoad) => {
    const payload = {
        id: file
    }

    fetch(URL_API + '/transcribe', {
        method: "POST",
        body: JSON.stringify(payload),
        credentials: 'include',
        headers: {
            "x-ffh-key": getKey(),
            'Content-Type': 'application/json'
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const callRequestSearch = (query, onLoad) => {
    fetch(URL_API + '/search/' + query, {
        method: "GET",
        credentials: 'include',
        headers: {
            "x-ffh-key": getKey()
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const subscribeLoadProject = (project, onMessage) => {
    return subscribe('/project/' + project, onMessage);
}

export const callLoadProject = (project, onLoad) => {
    fetch(URL_API + '/project/' + project, {
        method: "GET",
        credentials: 'include',
        headers: {
            "x-ffh-key": getKey(),
            'Content-Type': 'application/json'
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const subscribeProjectProjects = (type, onMessage) => {
    return subscribe('/project/' + type, onMessage);
}

export const subscribeLoadProjects = (type, onMessage, sort, page, pageSize) => {
    if (page == null || sort == null) {
      return subscribe('/workspace/' + type, onMessage);
    } else if (pageSize == null) {
      return subscribe('/workspace/' + type + '/' + sort + '/' + page, onMessage);
    }

    return subscribe('/workspace/' + type + '/' + sort + '/' + page + '/' + pageSize, onMessage);
}

export const callLoadProjects = (type, onLoad) => {
    fetch(URL_API + '/workspace/' + type, {
        method: "GET",
        credentials: 'include',
        headers: {
            "x-ffh-key": getKey(),
            'Content-Type': 'application/json'
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const callStopRender = (name, onLoad) => {
    fetch(URL_API + '/render/' + name + '/stop', {
        method: "GET",
        credentials: 'include',
        headers: {
            "x-ffh-key": getKey()
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const callStatusRender = (name, onLoad) => {
    fetch(URL_API + '/render/' + name, {
        method: "GET",
        credentials: 'include',
        headers: {
            "x-ffh-key": getKey()
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const callRenderProject = async (project, data, onLoad) => {
    let payload = {
        elements: []
    }

    const thumbnails = [];

	// Delete all content element properties if no content element should be created
	if(!data.contentElement.create) {
		data.contentElement = { create: false }
	}

	// Check after possible content element cleanup if there is an uploaded image
	if(data.contentElement.image) {
		const fr = new FileReader();

		// The following asynchronous process is converted into an synchronous one via await and promise 
		const encodedPortraitSliderImage = await new Promise((resolve, reject) => {
			fr.onload = () => resolve(fr.result);
			fr.onerror = () => reject();

			// Base64 encode the given image
			fr.readAsDataURL(data.contentElement.image);
		});

		// Override the image value with the base64 encoded image
		data.contentElement.image = encodedPortraitSliderImage;
	}

    if (data.thumbnails && Array.isArray(data.thumbnails)) {
        for (let i = 0, il = data.thumbnails.length; i < il; i++) {
            const { time, yCoordinate } = data.thumbnails[i];
            thumbnails.push({ time, yCoordinate });
        }
    }

    payload.elements.push({
        type: "project",
        params: {
            source: {
                name: project
            },
            audio: {
                normalize: data.audio.normalize,
                volume: data.audio.volume
            },
            subtitles: {
                burn: data.subtitles.burn,
            },
            logo: {
                burn: data.logo.burn,
            },
            format: {
                ls: data.format.ls,
                pt: data.format.pt
            },
            resolutions: {
                lq: data.resolutions.lq,
                sq: data.resolutions.sq,
                hq: data.resolutions.hq,
            },
            highlight: {
              start: data.highlight ? data.highlight.start : -1,
              end: data.highlight ? data.highlight.end : -1
            },
            thumbnails: thumbnails,
            options: {
              email: data.options.email
            },
			contentElement: data.contentElement
        }
    });

    fetch(URL_API + '/render', {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(payload),
        headers: {
            "x-ffh-key": getKey(),
            'Content-Type': 'application/json'
        }
    })
    .then(response => checkResponse(response.json()))
    .then(data => checkData(data, onLoad))
    .catch(error => checkError(error));
}

export const callUploadSubtitleFormat = (name, file, onLoad, onProgress) => {
    let data = new FormData();
    data.append('name', name);
    data.append('file', file);

    const xhr = new XMLHttpRequest();

    xhr.onprogress = (e) => {
        onProgress(e.loaded / e.total);
    }

    xhr.onload = (e) => {
        onLoad(checkData(JSON.parse(xhr.response)));
    }

    xhr.onerror = (e) => {
        checkError(e);
    }

    xhr.open("POST", URL_API + '/subtitle');
    xhr.setRequestHeader("x-ffh-key", getKey());
    xhr.withCredentials = true;
    xhr.send(data);
}

export const callUploadProjectUrl = (url, onLoad, onProgress) => {
    let data = new FormData();
    data.append('url', url);

    const xhr = new XMLHttpRequest();

    xhr.onprogress = (e) => {
        onProgress(e.loaded / e.total);
    }

    xhr.onload = (e) => {
        onLoad(checkData(JSON.parse(xhr.response)));
    }

    xhr.onerror = (e) => {
        checkError(e);
    }

    xhr.open("POST", URL_API + '/create');
    xhr.setRequestHeader("x-ffh-key", getKey());
    xhr.withCredentials = true;
    xhr.send(data);
}

export const callUploadProjectMediaCheck = (file, onLoad) => {
  let data = new FormData();
  data.append('name', file.name);

  const xhr = new XMLHttpRequest();

  xhr.onload = (e) => {
      onLoad(checkData(JSON.parse(xhr.response)));
  }

  xhr.onerror = (e) => {
      checkError(e);
  }

  xhr.open("POST", URL_API + '/create');
  xhr.setRequestHeader("x-ffh-key", getKey());
  xhr.withCredentials = true;
  xhr.send(data);
}

export const callHighlightDetection = (name, onLoad) => {
  pendingRequests['callHighlightDetection'] = new Date();

  let data = new FormData();
  data.append('name', name);

  const xhr = new XMLHttpRequest();

  xhr.onload = (e) => {
      delete pendingRequests['callHighlightDetection'];
      onLoad(checkData(JSON.parse(xhr.response)));
  }

  xhr.onerror = (e) => {
      delete pendingRequests['callHighlightDetection'];
      checkError(e);
  }

  xhr.open("POST", URL_API + '/create/highlight');
  xhr.setRequestHeader("x-ffh-key", getKey());
  xhr.withCredentials = true;
  xhr.send(data);
}

export const callUploadProjectMedia = (file, onLoad, onProgress, mode = 0) => {
    let data = new FormData();
    data.append('file', file);
    data.append('mode', mode);

    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (e) => {
        onProgress(e.loaded / e.total);
    }

    xhr.onload = (e) => {
        onLoad(checkData(JSON.parse(xhr.response)));
    }

    xhr.onerror = (e) => {
        checkError(e);
    }

    xhr.open("PUT", URL_API + '/create');
    xhr.setRequestHeader("x-ffh-key", getKey());
    xhr.withCredentials = true;
    xhr.send(data);
}

export const callDownloadProjectMedia = (project, onLoad, onProgress) => {
    let data = new FormData();
    data.append('name', project);

    const xhr = new XMLHttpRequest();

    if (onProgress) {
        xhr.onprogress = (e) => {
            onProgress(e.loaded / e.total);
        }
    }

    xhr.onload = (e) => {
        onLoad(new File([e.target.response], project, { type: e.target.response.type }));
    }

    xhr.onerror = (e) => {
        checkError(e);
    }

    xhr.open("POST", URL_API + '/load');
    xhr.setRequestHeader("x-ffh-key", getKey());
    xhr.responseType = 'blob';
    xhr.withCredentials = true;
    xhr.send(data);
}

export const callDownloadProjectMedias = (project, urls, onLoad, onProgress) => {
    let data = new FormData();
    data.append('urls', urls);

    const xhr = new XMLHttpRequest();

    if (onProgress) {
        xhr.onprogress = (e) => {
            onProgress(e.loaded / e.total);
        }
    }

    xhr.onload = (e) => {
        onLoad(new File([e.target.response], project, { type: e.target.response.type }));
    }

    xhr.onerror = (e) => {
        checkError(e);
    }

    xhr.open("POST", URL_CDN + '/load/download');
    xhr.setRequestHeader("x-ffh-key", getKey());
    xhr.responseType = 'blob';
    xhr.withCredentials = true;
    xhr.send(data);
    return xhr;
}

export const poll = (url, onMessage) => {
    return new Poller(url, onMessage);
}

class Poller {
    constructor(url, callback) {
        this.url = url;
        this.callback = callback;
        this.xhr = null;
        this.timeout = null;
        this.time = 0;
        this.uuid = this.uuidv4();

        this.poll();
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = (c === 'x' ? r : ((r & 0x3) | 0x8));
            return v.toString(16);
        });
    }

    poll() {
        let data = new FormData();
        data.append('time', this.time);
        data.append('uuid', this.uuid);

        this.xhr = new XMLHttpRequest();
        this.xhr.onload = (e) => {
            const json = secureParseJson(this.xhr.response);
            if (json.time) this.time = json.time;

            this.callback(json);

            this.timeout = setTimeout(() => {
                this.poll();
            }, 1000);
        }
        this.xhr.onabort = (e) => {
            if (this.timeout) clearTimeout(this.timeout);
        }
        this.xhr.onerror = (e) => {
            if (this.timeout) clearTimeout(this.timeout);
        }
        this.xhr.open("GET", URL_API + this.URL_API + '?uuid=' + encodeURIComponent(this.uuid) + '&time=' + encodeURIComponent(this.time));
        this.xhr.setRequestHeader("x-ffh-key", getKey());
        this.xhr.responseType = 'text';
        this.xhr.withCredentials = true;
        this.xhr.send(data);
    }

    close() {
        if (this.timeout) clearTimeout(this.timeout);
        if (this.xhr) this.xhr.abort();
    }
}
