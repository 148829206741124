import React from 'react'

import css from './index.module.css'

import { ProjectContext } from 'App/pages/Project'
import { MediaContext } from 'App/pages/Project/Media'

import TimelineControls from './Controls'
import TimelineSubtitle from './Subtitles'
import TimelineThumbnails from './Thumbnails'
import TimelineHighlight from './Highlight'
import TimelineAudio from './Audios'
import TimelineIndicator from './Indicators'

export const SizeContext = React.createContext();
export const WidthContext = React.createContext();

const Timelines = React.memo((props) => {
  const { options } = React.useContext(ProjectContext);
  const { media } = React.useContext(MediaContext);
  const [size, setSize] = React.useState(50);

  React.useEffect(() => {
      const registerShortcuts = (e) => {
          if (!e.ctrlKey && !e.altKey && !e.shiftKey) {
              if (e.target.nodeName !== 'BODY' && e.target.nodeName !== 'BUTTON') return;
              if (e.key === '+') setSize(Math.min(500, size + 1));
              if (e.key === '-') setSize(Math.max(25, size - 1));
          }
      }

      window.addEventListener('keydown', registerShortcuts);

      return (() => {
          window.removeEventListener('keydown', registerShortcuts);
      });
  }, [size, setSize]);

  const width = ((media.length > 0 && media[0].duration) * size);

  if (options.mode === 'DOCUMENT') {
    return (
      <>
        <SizeContext.Provider value={{size, setSize}}>
          <TimelineControls navigation={props.navigation} timeRef={props.timeRef} timelineRef={props.timelineRef} />
          <Timeline timelineRef={props.timelineRef} size={size} setSize={setSize}>
            <Marker markerRef={props.markerRef} />
            <WidthContext.Provider value={{ width: width }}>
              <TimelineIndicator navigation={props.navigation} setNavigation={props.setNavigation}/>
              <TimelineAudio media={media}/>
            </WidthContext.Provider>
          </Timeline>
        </ SizeContext.Provider>
      </>
    );
  }

  return (
    <>
      <SizeContext.Provider value={{size, setSize}}>
        <TimelineControls navigation={props.navigation} timeRef={props.timeRef} timelineRef={props.timelineRef} />
        <Timeline timelineRef={props.timelineRef} size={size} setSize={setSize}>
          <Marker markerRef={props.markerRef} />
          <WidthContext.Provider value={{ width: width }}>
            <TimelineIndicator navigation={props.navigation} setNavigation={props.setNavigation}/>
            <TimelineThumbnails navigation={props.navigation} />
            <TimelineHighlight navigation={props.navigation} />
            <TimelineSubtitle navigation={props.navigation} timelineRef={props.timelineRef} />
            <TimelineAudio media={media}/>
          </WidthContext.Provider>
        </Timeline>
      </ SizeContext.Provider>
    </>
  );
});

export default Timelines;

const Timeline = React.memo((props) => {
  const { size } = React.useContext(SizeContext);

  return (<div ref={props.timelineRef} className={css.footer} size={size}>{props.children}</div>);
});

const Marker = React.memo((props) => {
  const { media } = React.useContext(MediaContext);
  const { size } = React.useContext(SizeContext);
  const px = (media != null && media[0] != null) ? (media[0].currentTime * size) : 0;

  return (<div ref={props.markerRef} className={css.marker} size={size} style={{ left: px + "px" }}></div>);
});
