import React from 'react'
import { useHistory } from "react-router-dom"

import css from './Layout.module.css'

import { Message } from 'shared/hooks/Translation'

import { IconApp, IconSignOut, IconSettings } from 'shared/components/Icon';
import { addNotification } from 'shared/components/Notification';

import { APP_NAME } from 'App'
import Launcher from './Launcher'
import Monitor from './Monitor'
import Search from './Search'

import { subscribeStatusUpdate, callLogout } from 'shared/hooks/Api';
import { logout } from 'shared/hooks/Auth';

export function Logout() {
  const history = useHistory();

  const signOut = () => {
      logout(() => {
          history.push('/login');
          callLogout();
      });
  }

  return (
    <div className={css.icon} onClick={() => signOut()}>
        <IconSignOut width={15} height={15}/>
    </div>
  );
}

export function Dashboard() {
  const history = useHistory();

  const toDashboard = () => {
      history.push('/');
  }

  return (
    <div className={css.icon} onClick={toDashboard}>
        <IconApp width={25} height={25}/>
    </div>
  );
}

export class AppHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      monitor: false,
      launcher: false,
      notifications: 0
    }

    this.setMonitor = (monitor) => {
      this.setState({monitor : monitor});
    }
    this.setLauncher = (launcher) => {
      this.setState({launcher : launcher});
    }
  }

  componentDidMount() {
    this.subscription = subscribeStatusUpdate((report) => {
        if (report.status != null) {
            switch (report.status) {
                case 'START_ENCODING':
                    addNotification(<Message id='notification.encodingStart' variable={report.project}></Message>); break;
                case 'CANCEL_ENCODING':
                    addNotification(<Message id='notification.encodingCancel' variable={report.project}></Message>, 'danger'); break;
                case 'FINISH_ENCODING':
                    addNotification(<Message id='notification.encodingFinish' variable={report.project}></Message>, 'success'); break;
                case 'START_TRANSCRIBING':
                    addNotification(<Message id='notification.transcribingStart' variable={report.project}></Message>); break;
                case 'CANCEL_TRANSCRIBING':
                    addNotification(<Message id='notification.transcribingCancel' variable={report.project}></Message>, 'danger'); break;
                case 'FINISH_TRANSCRIBING':
                    addNotification(<Message id='notification.transcribingFinish' variable={report.project}></Message>, 'success'); break;
                default: break;
            }
        } else {
            var amount = 0

            try {
              amount += report.encoder.jobs.length;
              amount += report.encoder.queue.length;
              amount += report.transcriber.jobs.length;
              amount += report.transcriber.queue.length;

              this.setState({notifications : amount});
            } catch (error) {
              console.log(error);
            }
        }
    });
  }

  componentWillUnmount() {
      if (this.subscription != null) this.subscription.close();
  }

  render() {
    return (
      <>
        <Launcher open={this.state.launcher} setOpen={this.setLauncher}/>
        <Monitor open={this.state.monitor} setOpen={this.setMonitor}/>
        <div className={css.header}>
          <div className={css.left}>
              <Dashboard />
              <div className={css.name}>
                  <span>{APP_NAME}</span>
              </div>
          </div>
          <div className={css.center}>
              <Search />
          </div>
          <div className={css.right}>
              <div className={css.icon} onClick={() => this.setMonitor(!this.state.monitor)}>
                  <div className={css.notification + ' ' + (this.state.notifications > 0 ? css.active : css.inactive)}>
                    <IconSettings width={15} height={15}/>
                    { this.state.notifications >0 ? <span className={css.badge}><span>{this.state.notifications}</span></span> : null }
                  </div>
              </div>
              <Logout />
          </div>
        </div>
      </>
    );
  }
}

export function AppBody({children}) {
  return (
    <>
      <div className={css.body}>
          <div className={css.content}>
            {children}
          </div>
      </div>
    </>
  )
}
