import React from 'react';

import css from './index.module.css'

import { Message, LanguageContext } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Project'
import { MediaContext } from 'App/pages/Project/Media'

import { Button, ConfirmButton, OptionButtonWrapper, OptionButtonGroup, OptionButton, SelectButton, Select, Option } from 'shared/components/Button'
import { IconTrash, IconSubtitleCreate, IconSubtitleGenerate, IconSubtitleImport, IconPlus, IconStar } from 'shared/components/Icon'
import { Listener } from 'shared/components/Spinner'
import Tab, { TabItem } from 'shared/components/Tab'
import { addNotification } from 'shared/components/Notification'

import { ModalReport } from 'App/pages/Project/modals/ModalReport'

import { SheetHeader, SheetTabBody, SheetTitle } from '../../Sheet'

import { callDownloadSubtitleFormat, callUploadSubtitleFormat, callRequestSubtitles, callStopSubtitles, callRemoteRemoveSubtitles } from 'shared/hooks/Api'

import { convertToUniqueArray } from 'shared/utils/project';
import { getReadabilityScore } from 'shared/utils/subtitle';

export const TABS = [
    { title: "list" },
    { title: "search" },
    { title: "option" }
]

export const SubtitleContext = React.createContext();

export default function SheetSubtitles(props) {
    const { project, projectFunctions } = React.useContext(ProjectContext);
    const [tab, setTab] = React.useState(TABS[0]);
    const [creating, setCreating] = React.useState(project.status.indexOf("TRANS") !== -1);

    const inputRef = React.createRef();

    function onUploadSubtitle() {
        inputRef.current.click();
    }

    // Listen if the status change and then change the creating indicator
    React.useEffect(() => {
      setCreating(project.status.indexOf("TRANS") !== -1);
    }, [project.status]);

    function uploadFile(file) {
      if (creating) return;

      callUploadSubtitleFormat(project.name, file,
        (subtitles) => {
          setTab(TABS[0]);
          if (subtitles == null) return;

          try {
            if (Array.isArray(subtitles)) {
              projectFunctions.setSubtitles(convertToUniqueArray(subtitles));
              addNotification("notification.subtitleUpload");
            } else {
              addNotification("notification.subtitleUploadError", "danger");
            }
          } catch (error) {
            addNotification("notification.subtitleUploadException", "danger");
            console.log(error);
          }
        },
        (progress) => {
          // TODO: Nothing...
        }
      );
    }

    function onSubtitleUpload(event) {
        const { files } = event.target;
        if (files && files.length) uploadFile(files[0]);
    }

    function onSubtitleRequest() {
      if (creating) return;
      setCreating(true);

      callRequestSubtitles(project.name, (subtitles) => {
        if (subtitles != null) projectFunctions.setSubtitles(subtitles);
        setTab(TABS[0]);
      });
    }

    function cancelSubtitleRequest() {
      if (!creating) return;
      setCreating(false);

      callStopSubtitles(project.name, () => {
        // TODO: Nothing...
      });
    }

    if (creating) {
        const idle = project.status !== 'TRANSCRIBING';

        /*
        TODO: Implement ETA in the future. The speech service needs 50% of the files duration to process it

        const duration = parseInt((project.fileInformation.format.duration * 1000) * 0.6);
        const time = (project.statusAt + duration) - Date.now();
        const status = formatTime(time / 1000);
        */

        return (
            <>
                <SheetTabBody>
                    <div className={css.placeholder}>
                        <Listener idle={idle} height={50} width={50} />
                        <span>{idle ? <Message id="status.subtitles.queue" /> : <Message id="status.subtitles.process" />}</span>
                        <span>{idle ? <Message id="status.subtitles.queue.description" /> : <Message id="status.subtitles.process.description" />}</span>
                        <button className="secondary" onClick={cancelSubtitleRequest}><Message id="button.cancel" /></button>
                    </div>
                </SheetTabBody>
            </>
        );
    }

    if (project.subtitles.length <= 0) {
        return (
            <>
                <input
                    style={{ display: "none" }}
                    accept=".srt,.ass,.lrc,.sbv,.smi,.ssa,.sub,.vtt"
                    ref={inputRef}
                    onChange={onSubtitleUpload}
                    type="file"
                    id="dropzone"
                />
                <SheetTabBody>
                    <OptionButtonWrapper>
                        <OptionButtonGroup>
                            <OptionButton optionStyle='recommended' handleClick={() => onSubtitleRequest() }>
                                <IconSubtitleGenerate width={50} height={50} />
                                <span><Message id="box.subtitleGenerate"/></span>
                                <span><Message id="box.subtitleGenerate.description"/></span>
                            </OptionButton>
                        </OptionButtonGroup>
                        <OptionButtonGroup>
                            <OptionButton handleClick={() => projectFunctions.addSubtitle(0) }>
                                <IconSubtitleCreate width={50} height={50} />
                                <span><Message id="box.subtitleCreate"/></span>
                                <span><Message id="box.subtitleCreate.description"/></span>
                            </OptionButton>
                            <OptionButton handleClick={() => onUploadSubtitle() }>
                                <IconSubtitleImport width={50} height={50} />
                                <span><Message id="box.subtitleUpload"/></span>
                                <span><Message id="box.subtitleUpload.description"/></span>
                            </OptionButton>
                        </OptionButtonGroup>
                    </OptionButtonWrapper>
                </SheetTabBody>
            </>
        );
    }

    const tabs = TABS.map((obj, index) => {
        return (
            <TabItem key={index} active={obj === tab} handleClick={() => setTab(obj)}>
                <span><Message id={"tab." + obj.title} /></span>
            </TabItem>
        )
    })

    return (
        <>
            <SheetHeader>
                <Tab>
                    {tabs}
                </Tab>
            </SheetHeader>
            <SheetTabBody open={tab === TABS[0]} bodyRef={props.listRef}>
                <Subtitles bodyRef={props.listRef} />
            </SheetTabBody>
            <SheetTabBody open={tab === TABS[1]}>
                <Search />
            </SheetTabBody>
            <SheetTabBody open={tab === TABS[2]}>
                <Options />
            </SheetTabBody>
        </>
    );
}

export const Options = React.memo((props) => {
    const { project, projectFunctions } = React.useContext(ProjectContext);
    const extensions = ["srt", "ass", "lrc", "sbv", "smi", "ssa", "sub", "vtt"];
    const [format, setFormat] = React.useState(0);
    const [processing, setProcessing] = React.useState(false);
    const [openAnalyse, setOpenAnalyse] = React.useState(false);

    const formats = extensions.map((f, index) =>
        <Option key={index} name={f.toUpperCase()} value={index}></Option>
    );

    const download = (type) => {
      if (extensions[format] != null) {
        if (processing) {
          addNotification("notification.downloadAlready", "warning");

        } else {
          setProcessing(true);

          projectFunctions.save(() => {
            callDownloadSubtitleFormat(project.name, extensions[format], (data) => {
              setProcessing(false);

              if (data == null || data.length <= 0) {
                addNotification("notification.downloadError", "danger");

              } else {
                addNotification("notification.download");

                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
                element.setAttribute('download', project.name + '.de_DE.' + extensions[format]);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
              }
            });
          });
        }
      } else {
          addNotification("notification.downloadException", "danger");
      }
    }

    const deleteSubtitles = () => {
      projectFunctions.deleteSubtitles();

      addNotification("notification.subtitleDelete", "danger");
    }

    const remoteRemoveSubtitles = () => {
      callRemoteRemoveSubtitles(project.name, (success) => {
        console.log(success);
        if (success) {
          addNotification("notification.subtitleRemoteDelete");
        } else {
          addNotification("notification.subtitleRemoteDelete.fail", "danger");
        }
      });
    }

    return (
        <>
            <ModalReport open={openAnalyse} setOpen={setOpenAnalyse}/>
            <ConfirmButton text={<Message id="button.deleteSubtitles"/>} handleClick={() => { deleteSubtitles() }}>
                <IconTrash />
            </ConfirmButton>
            <ConfirmButton text={<Message id="button.remoteRemoveSubtitles"/>} handleClick={() => { remoteRemoveSubtitles() }}>
                <IconTrash />
            </ConfirmButton>
            <SheetTitle><span><Message id="menu.subtitles"/></span></SheetTitle>
            <Button buttonStyle="secondary" text={<Message id="button.deleteSubtitles"/>} handleClick={() => { setOpenAnalyse(!openAnalyse) }}>
                <Message id="button.check" />
                <IconStar />
            </Button>
            <SelectButton handleClick={() => { download(format) }}>
                <Select handleSelect={(e) => setFormat(e.target.value)}>
                    {formats}
                </Select>
            </SelectButton>
        </>
    );
});

export const Search = React.memo((props) => {
    const [search, setSearch] = React.useState("");
    const replaceRef = React.useRef(null);
    const { project, projectFunctions } = React.useContext(ProjectContext);
    const { message } = React.useContext(LanguageContext);

    const results = [];

    if (project.subtitles !== null && search.length > 1) {
        const subtitles = [...project.subtitles];

        subtitles.forEach((subtitle) => {
            if (subtitle.text.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                const sub = {...subtitle};
                let re = new RegExp(search, "gi")
                sub.text = sub.text.replace(re, `<mark>${search}</mark>`);

                results.push(sub);
            }
        });
    }

    const searchResults = results.map((result, index) => {
        return (
            <div key={index} className={css.searchResult} dangerouslySetInnerHTML={{ __html: result.text }}></div>
        );
    });

    const replace = () => {
      if (replaceRef == null || replaceRef.current == null) return;
      const from = search;
      const to = replaceRef.current.value;

      let re = new RegExp(from, "gi");

      const subtitles = [...project.subtitles];

      for (let i = 0, il = subtitles.length; i < il; i++) {
        const subtitle = subtitles[i];

        if (subtitle.text.toLowerCase().indexOf(from.toLowerCase()) !== -1) {
            const sub = {...subtitle};
            subtitles[i] = sub;
            sub.text = sub.text.replace(re, to);
        }
      }

      projectFunctions.replaceSubtitles(subtitles);
    }

    return (
        <>
            <div className={css.search}>
              <input placeholder={message('input.search')} onChange={(e) => { setSearch(e.target.value) }}></input>
              <input placeholder={message('input.replace')} ref={replaceRef}></input>
              <button onClick={replace}><Message id="button.replace" /></button>
            </div>
            <div className={css.searchResults}>
                {results.length > 0 ? searchResults : <div className={css.placeholder + ' ' + css.top}><span><Message id="status.search" /></span><span><Message id="status.search.description.2" /></span><span><Message id="status.search.description.1" /></span></div>}
            </div>
        </>
    );
});

const Subtitles = React.memo((props) => {
    return (
        <div className={css.subtitlesWrapper}>
            <VirtualSubtitleList bodyRef={props.bodyRef} />
        </div>
    );
});

export const VirtualSubtitleList = React.memo((props) => {
    const { project, projectFunctions } = React.useContext(ProjectContext);
    const [update, setUpdate] = React.useState(-1);

    const subtitleList = calculcateSubtitles(props.bodyRef, project.subtitles).map((subtitle, index) =>
        <Subtitle
            key={index}
            index={index}
            active={index === props.index}
            subtitle={subtitle}
            files={props.files}
            bodyRef={props.bodyRef}
        />
    );

    React.useEffect(() => {
        const load = () => {
            if (props.bodyRef == null) return;
            const pos = props.bodyRef.current === null ? 0 : props.bodyRef.current.scrollTop;
            if (update !== pos) setUpdate(pos);
        }

        load();

        const id = setInterval(load, 150);

        return () => {
            clearInterval(id);
        }
    }, [props.bodyRef, update]);

    return (
        <>
            <div style={{ height: ((project.subtitles.length * 160) + 10) }}>
                {subtitleList}
            </div>
            <button className="secondary" onClick={() => projectFunctions.addSubtitle(-1)}>
              <span><Message id="button.subtitleCreate" /></span>
              <IconPlus />
            </button>
        </>
    );
});

const calculcateSubtitles = (box, subtitles) => {
    const visibleSubtitles = [];

    if (box !== undefined && box.current !== null) {
        const bounding = box.current.getBoundingClientRect();
        const from = box.current.scrollTop - bounding.height;
        const to = bounding.height + box.current.scrollTop + bounding.height;

        for (let i = 0, il = subtitles.length; i < il; i++) {
            const min = i * 160;
            const max = min + 160;

            if (((min >= from) && (min <= to)) || ((max >= from) && (max <= to))) {
                visibleSubtitles.push(subtitles[i]);
            } else {
                visibleSubtitles.push(null);
            }
        }
    }

    return visibleSubtitles;
}

export const Subtitle = React.memo((props) => {
    const { mediaIndex } = React.useContext(MediaContext);

    if (props.subtitle === null) {
        return null;
    }

    const index = props.index;
    const subtitle = props.subtitle;

    return (
        <div className={css.subtitleWrapper} style={{ top: (props.index * 160) + "px" }}>
            <SubtitleContext.Provider value={{ index, subtitle }}>
                <SubtitleAdd />
                <div className={css.subtitle + ((index === mediaIndex) ? (' ' + css.active) : '')}>
                    <SubtitleText />
                    <div className={css.subtitleTools}>
                        <ToolGroup>
                            <SubtitleTimeFrom />
                            <ToolDivider />
                            <SubtitleTimeTo />
                        </ToolGroup>
                        <ToolGroup>
                            <SubtitleCounter />
                            <ToolDivider />
                            <SubtitleRemove />
                        </ToolGroup>
                    </div>
                </div>
            </SubtitleContext.Provider>
        </div>
    )
});

export const ToolGroup = ({ children }) => {
    return <div className={css.subtitleToolGroup}>{children}</div>
}

export const ToolDivider = () => {
    return <div className={css.subtitleDivider}></div>
}

class TextEditor extends React.Component {
  constructor(props) {
      super(props);
      this.el = React.createRef();
      this.ref = React.createRef();
      this.onChange = this.onChange.bind(this);
      this.onClick = this.onClick.bind(this);

      this.normalizeHtml = (str) => {
        return str && str.replace(/(<([^>]+)>)/gi, "");
      }
  }

  onChange(){
        var html = this.ref.current.innerHTML;

        if (this.props.onChange && html !== this.lastHtml) {
            this.props.onChange({html: this.ref.current.innerHTML, text: this.ref.current.textContent});
        }

        this.lastHtml = html;
    }

    onClick() {
      if (this.props.onClick) this.props.onClick();
    }

    shouldComponentUpdate(nextProps){
      if (document.activeElement && document.activeElement.id && document.activeElement.id === this.props.id) return false;
      return (this.normalizeHtml(nextProps.text) !== this.normalizeHtml(this.ref.current.textContent)) ? true : false;
    }

     componentDidUpdate() {
        if (this.props.value !== this.ref.current.textContent) {
           this.ref.current.innerHTML = this.props.text;
        }
    }

  render() {
      const { enabled, id, style, className, value } = this.props;
      return (
          <div>
              <div
                  autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                  contentEditable={enabled}
                  id={id}
                  style={style}
                  className={className}
                  dangerouslySetInnerHTML={{ __html: value }}
                  ref={this.ref}
                  onInput={this.onChange}
                  onClick={this.onClick}
                  onFocus={this.props.onFocus}
                  onBlur={this.props.onBlur}
              />
          </div>
      )
    }
  }

export const SubtitleText = () => {
    const { index, subtitle } = React.useContext(SubtitleContext);
    const { mediaFunctions } = React.useContext(MediaContext);
    const { options, projectFunctions } = React.useContext(ProjectContext);

    const applyHighlights = (text) => {
      var t = "";
      var words = text.split(" ");

       if (options.smartHighlights && subtitle.words != null) {
         for (let i = 0, il = words.length; i < il; i++) {
           if (i < subtitle.words.length) {
             const word = subtitle.words[i];

             if (word.word === words[i] && word.confidence < 0.8) {
               t += (`<mark title="${Math.round(word.confidence * 100)}%">${words[i]}</mark> `);
             } else {
               t += (words[i] + " ");
             }
           } else {
             t += words[i] + " ";
           }
         }
       }

      return t.trim();
    }

    // This can be used to lock the subtitle scrolling when textfield is active. Currently not used due to bad visual understanding
    const onFocus = () => {
      //options.lockSubtitles = true;
    }
    const onBlur = () => {
      //options.lockSubtitles = false;
    }

    return (
      <TextEditor
        id={subtitle.uuid}
        key={subtitle.uuid}
        className={css.textArea}
        enabled={true}
        onClick={() => { mediaFunctions.time(subtitle.start + 0.001) }}
        onChange={(e) => { projectFunctions.modifySubtitle(index, { text: e.text }, true, 500) }}
        onFocus={onFocus}
        onBlur={onBlur}
        text={subtitle.text}
        value={applyHighlights(subtitle.text)}
      />
      );
}

export const SubtitleTimeFrom = () => {
    const { index, subtitle } = React.useContext(SubtitleContext);
    const { media } = React.useContext(MediaContext);
    const { projectFunctions } = React.useContext(ProjectContext);

    return <>
        <button onClick={(e) => { if (media && media.length > 0) projectFunctions.modifySubtitle(index, { start: media[0].currentTime }); }}>
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M247.9 412.5l-148.4-148c-4.7-4.7-4.7-12.3 0-17l148.4-148c4.7-4.7 12.3-4.7 17 0l19.6 19.6c4.8 4.8 4.7 12.5-.2 17.1L187.2 230H436c6.6 0 12 5.4 12 12v28c0 6.6-5.4 12-12 12H187.2l97.1 93.7c4.8 4.7 4.9 12.4.2 17.1l-19.6 19.6c-4.7 4.8-12.3 4.8-17 .1zM52 436V76c0-6.6-5.4-12-12-12H12C5.4 64 0 69.4 0 76v360c0 6.6 5.4 12 12 12h28c6.6 0 12-5.4 12-12z" /></svg>
        </button>
        <input type="number" step="0.1"
            onChange={(e) => { projectFunctions.modifySubtitle(index, { start: e.target.value }) }}
            key={index}
            value={subtitle.start}>
        </input>
    </>
}

export const SubtitleTimeTo = () => {
    const { index, subtitle } = React.useContext(SubtitleContext);
    const { media } = React.useContext(MediaContext);
    const { projectFunctions } = React.useContext(ProjectContext);

    return <>
        <input type="number" step="0.1"
            onChange={(e) => { projectFunctions.modifySubtitle(index, { end: e.target.value }) }}
            key={index}
            value={subtitle.end}>
        </input>
        <button onClick={(e) => { if (media && media.length > 0) projectFunctions.modifySubtitle(index, { end: media[0].currentTime }); }}>
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M200.1 99.5l148.4 148c4.7 4.7 4.7 12.3 0 17l-148.4 148c-4.7 4.7-12.3 4.7-17 0l-19.6-19.6c-4.8-4.8-4.7-12.5.2-17.1l97.1-93.7H12c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h248.8l-97.1-93.7c-4.8-4.7-4.9-12.4-.2-17.1l19.6-19.6c4.7-4.9 12.3-4.9 17-.2zM396 76v360c0 6.6 5.4 12 12 12h28c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12h-28c-6.6 0-12 5.4-12 12z" /></svg>
        </button>
    </>
}

export const SubtitleCounter = () => {
    const { index, subtitle } = React.useContext(SubtitleContext);
    const words = subtitle.text.split(" ").length;
    const score = getReadabilityScore(subtitle);
    return <div className={css.subtitleCounter + ' ' + (score < 0 ? css.bad : css.good)} key={index}>{words} W.</div>
}

export const SubtitleAdd = () => {
    const { index } = React.useContext(SubtitleContext);
    const { projectFunctions } = React.useContext(ProjectContext);

    const createSubtitle = (e) => {
        e.Handled = true;
        projectFunctions.addSubtitle(index, e.dataTransfer.getData("text"));
    }

    return (
        <button className={css.subtitleButtonAdd} key={index}
            onClick={() => { projectFunctions.addSubtitle(index) }}
            onDrop={(e) => { createSubtitle(e) }}
            onDragOver={allowDrop} >
            <span></span>
        </button>
    )
}

export const SubtitleRemove = () => {
    const { index } = React.useContext(SubtitleContext);
    const { projectFunctions } = React.useContext(ProjectContext);

    return <button key={index} onClick={() => { projectFunctions.removeSubtitle(index) }}>
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 80h-82.4l-34-56.7A48 48 0 0 0 274.4 0H173.6a48 48 0 0 0-41.2 23.3L98.4 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16l21.2 339a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM173.6 48h100.8l19.2 32H154.4zm173.3 416H101.11l-21-336h287.8z" /></svg>
    </button>
}

const allowDrop = (e) => {
    e.preventDefault();
}
