import { Message } from 'shared/hooks/Translation';

const periods = {
    MONTH: 30 * 24 * 60 * 60 * 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000,
    DAY: 24 * 60 * 60 * 1000,
    HOUR: 60 * 60 * 1000,
    MINUTE: 60 * 1000
  };

export const formatTime = (time, ms = true) => {
    if (time == null) return "";
    var milliseconds = time.toString().indexOf('.') !== -1 ? (time.toString().split('.')[1][0]) : 0;
    var minutes = Math.floor(time / 60);
    minutes = (minutes >= 10) ? minutes : minutes;
    var hours = Math.floor(minutes / 60);
    hours = (minutes >= 10) ? hours : hours;
    var seconds = Math.floor(time % 60);
    seconds = (seconds >= 10) ? seconds : seconds;

    return formatZero(hours) + ":" + formatZero(minutes) + ":" + formatZero(seconds) + (ms ? ("." + milliseconds + "0") : "");
}

export const formatZero = (time) => {
    return time < 10 ? ('0' + time) : time;
}

export function milliToDate(time) {
  return new Date(time).toLocaleString('de-DE');
}

export function milliToSince(time) {
  var diff = Date.now() - time;

  if (diff > periods.MONTH) {
    return <Message id="time.prior.month" count={Math.floor(diff / periods.MONTH)} />
  } else if (diff > periods.WEEK) {
    return <Message id="time.prior.week" count={Math.floor(diff / periods.WEEK)} />
  } else if (diff > periods.DAY) {
    return <Message id="time.prior.day" count={Math.floor(diff / periods.DAY)} />
  } else if (diff > periods.HOUR) {
    return <Message id="time.prior.hour" count={parseInt(Math.floor(diff / periods.HOUR))} />
  } else if (diff > periods.MINUTE) {
    return <Message id="time.prior.minute" count={Math.floor(diff / periods.MINUTE)} />
  }

  return <Message id="time.prior.recent" />
}
