import React from 'react'
import { useHistory } from "react-router-dom"

import css from './index.module.css'
import { IconFolder, IconPause } from 'shared/components/Icon'

import { Message } from 'shared/hooks/Translation'

import { milliToSince } from 'shared/utils/time'

export const FolderWrapper = React.memo(({ children, folderRef }) => {
    return (<div ref={folderRef} className={css.wrapper}>{children}</div>)
});

export const Folder = React.memo((props) => {
    const history = useHistory();

    const [showImage, setShowImage] = React.useState(false);
    const placeHolderRef = React.useRef(null);

    React.useEffect(() => {
        registerObserver(placeHolderRef.current, setShowImage);
    }, []);

    const thumbnail = "url(" + props.render.thumbnail + ")";
    const style = showImage ? { "backgroundImage": thumbnail } : {}

    const toRender = () => {
        history.push('/download/' + props.render.name);
    }

    const rendering = (props.render.status === "RENDERING" || props.render.status === "QUEUED_FOR_RENDERING");

    var files = 0;
    files += (props.render.renderUrls) ? props.render.renderUrls.length : 0;
    files += (props.render.thumbnailUrls) ? props.render.thumbnailUrls.length : 0;

    return (
        <div className={css.cell} onClick={toRender}>
            <div className={css.cellContent}>
                <div className={css.cellTop}>
                    <div className={css.cellFolderWrapper}>
                        <div className={css.cellFolder + (rendering ? (' ' + css.rendering) : '')}>
                            <span className={css.cellCover} ref={placeHolderRef} style={style}></span>
                            <span className={css.cellCoverPlay}><IconPause width={15} height={15}/></span>
                            <div className={css.cellFolderMeta}>
                                {rendering ? <Progress encoder={props.render.encoder} /> : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css.cellBottom}>
                    <div className={css.cellBottomGroup}>
                        <IconFolder width={10} height={10}/>
                        <span>{props.render.name}</span>
                    </div>
                    <div className={css.cellBottomGroup}>
                        <span><Message id="folder.file" count={files}/></span>
                        <span>{milliToSince(props.render.renderAt)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
});

export const Progress = React.memo((props) => {
    if (props.encoder == null || props.encoder.percentage == null) return null;

    return (
        <div className={css.progress}>
            <div className={css.progressBar} style={{ width: props.encoder.percentage + "%" }}></div>
        </div>
    );
});

export const registerObserver = (ref, setShowImage) => {
    const observer = new IntersectionObserver((enteries, observer) => {
        enteries.forEach((entry) => {
            if (entry.isIntersecting) {
                setShowImage(true);
                observer.disconnect();
            }
        });
    });

    observer.observe(ref);
};
