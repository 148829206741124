import React from 'react'
import ReactDom from 'react-dom'

import css from './index.module.css'

import { CloseButton } from 'shared/components/Button'

export default function Lightroom({ src, open, setOpen, children, modalStyle = 'default' }) {
    const [stage, setStage] = React.useState(-1);
    if (open && stage !== 1) setStage(1);
    if (!open && stage === 1) { setStage(2); setTimeout(() => { setStage(-1); }, 450) }
    if (!open && stage === -1) return null;

    return ReactDom.createPortal(
        <>
            <div className={css.overlay + ((stage === 2) ? (' ' + css.closing) : '')} />
            <div className={css.wrapper}>
                <div className={css.container + ((stage === 2) ? (' ' + css.closing) : '')}>
                    <CloseButton onClose={() => { setOpen(false) }} />
                    <div className={css.content}>
                        <img alt="Lightroom" src={src}/>
                    </div>
                </div>
            </div>
        </>,
        document.getElementById('root')
    )
}
