import React from 'react'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Project'
import { MediaContext } from 'App/pages/Project/Media'
import { SizeContext } from 'App/pages/Project/Timeline'

import { IconJumpBack, IconJumpBackTotal, IconJumpForward, IconLoop, IconLoopActive, IconPause, IconPlay, IconSubtitleAdd, IconSubtitleCut, IconSubtitleSnap, IconSetThumbnail, IconVolume, IconZoomIn, IconZoomOut, IconVolumeMuted } from 'shared/components/Icon'

import { SIZE_MIN, SIZE_MAX } from 'shared/constants/timeline'
import { ELEMENTS } from 'shared/constants/nav'

import { accessRef } from 'shared/utils/ref'

const TimelineControls = React.memo((props) => {
  return (
    <div className={css.controls}>
      <Left navigation={props.navigation}/>
      <Center timeRef={props.timeRef}/>
      <Right timelineRef={props.timelineRef}/>
    </div>
  );
});

export default TimelineControls;

const Left = React.memo((props) => {
  const { projectFunctions, options } = React.useContext(ProjectContext);
  const { media } = React.useContext(MediaContext);

  if (options.mode === 'DOCUMENT') {
    return <div className={css.controlsLeft}></div>;
  }

  return (
    <div className={css.controlsLeft}>
      { (props.navigation === ELEMENTS.SUBTITLES) ?
      <button onClick={() => { projectFunctions.insertSubtitle(media[0].currentTime) }}>
        <IconSubtitleAdd />
        <span><Message id="button.subtitleAdd" /></span><span className={css.shortkey}>(A)</span>
      </button> : null }
      { (props.navigation === ELEMENTS.SUBTITLES) ?
      <button onClick={() => { projectFunctions.cutSubtitles(media[0].currentTime) }}>
        <IconSubtitleCut />
        <span><Message id="button.subtitleCut" /></span><span className={css.shortkey}>(S)</span>
      </button> : null }
      { (props.navigation === ELEMENTS.SUBTITLES) ?
      <button onClick={() => { projectFunctions.snapSubtitles() }}>
        <IconSubtitleSnap />
        <span><Message id="button.subtitleSnap" /></span><span className={css.shortkey}>(D)</span>
      </button> : null }
      { (props.navigation === ELEMENTS.THUMBNAILS) ?
      <button onClick={() => { projectFunctions.insertThumbnail(media[0].currentTime) }}>
        <IconSetThumbnail />
        <span><Message id="button.thumbnailAdd" /></span>
      </button> : null }
    </div>
  );
});

const Center = React.memo((props) => {
  const { media, mediaFunctions } = React.useContext(MediaContext);
  const [paused, setPaused] = React.useState(true);
  const [loop, setLoop] = React.useState(false);

  const toggleLoop = () => {
    setLoop(mediaFunctions.toggleLoop());
  }

  const pause = () => {
    setPaused(true);
  }
  const play = () => {
    setPaused(false);
  }

  React.useEffect(() => {
    media[0].addEventListener('pause', pause);
    media[0].addEventListener('play', play);

    return () => {
      media[0].removeEventListener('pause', pause);
      media[0].removeEventListener('play', play);
    }
  });

  return (
    <div className={css.controlsCenter}>
      <div className={css.controlsCenterUpper}>
        <span className={css.time} ref={props.timeRef}>00:00:00.00</span>
      </div>
      <div className={css.controlsCenterLower}>
        <button onClick={() => mediaFunctions.time(0)}>
          <IconJumpBackTotal />
        </button>
        <button onClick={() => mediaFunctions.skip(-1)}>
          <IconJumpBack />
        </button>
        <button className={css.persistent} onClick={() => mediaFunctions.togglePlay()}>
          {paused ? <IconPause /> : <IconPlay />}
        </button>
        <button onClick={() => mediaFunctions.skip(+1)}>
          <IconJumpForward />
        </button>
        <button onClick={() => toggleLoop()}>
        {loop ? <IconLoopActive /> : <IconLoop />}
        </button>
      </div>
    </div>
  );
});

const Right = React.memo((props) => {
  const { media, mediaFunctions } = React.useContext(MediaContext);
  const { size, setSize } = React.useContext(SizeContext);
  const [mute, setMute] = React.useState(true);

  const toggleMute = () => {
    setMute(!mediaFunctions.toggleMute());
  }

  const fit = () => {
    accessRef(props.timelineRef, (current) => {
      const width = (current.getBoundingClientRect().width);
      const maxWidth = media[0].duration * size;

      // const newSize = Math.floor(width * 100 / maxWidth);
      const newSizeLimited = Math.max(SIZE_MIN, Math.min(SIZE_MAX, Math.floor(width * 100 / maxWidth)));

      if (newSizeLimited !== size) setSize(newSizeLimited);
    });
  }

  return (
    <div className={css.controlsRight}>
      <button onClick={() => toggleMute()}>
        {mute ? <IconVolume /> : <IconVolumeMuted />}
      </button>
      <Divider />
      <button onClick={() => setSize(Math.max(50, size - 25))} >
        <IconZoomIn width={10} height={10}/>
      </button>
      <button onClick={() => fit()} >
        <span><Message id="button.timelineFit" /></span>
      </button>
      <button onClick={() => setSize(Math.min(250, size + 25))} >
        <IconZoomOut width={10} height={10}/>
      </button>
    </div>
  );
});

const Divider = () => {
  return (<div className={css.divider}></div>);
}
