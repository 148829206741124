import React from 'react'
import ReactDom from 'react-dom'

import css from './index.module.css'

import { IconApp } from 'shared/components/Icon'

export default function Launcher({ open, setOpen }) {
  const [stage, setStage] = React.useState(-1);
  if (open && stage !== 1) setStage(1);
  if (!open && stage === 1) { setStage(2); setTimeout(() => { setStage(-1); }, 150) }
  if (!open && stage === -1) return null;

  return ReactDom.createPortal(
    <>
      <div className={css.wrapper}>
        <div className={css.container + ((stage === 2) ? (' ' + css.closing) : '')}>
            <div className={css.header}>
                <div className={css.icon} onClick={() => setOpen(!open)}>
                    <IconApp width={20} height={20}/>
                    <div className={css.dashboard}>Dashboard</div>
                </div>
            </div>
            <div className={css.body}>
            </div>
        </div>
      </div>
    </>,
    document.getElementById('root')
  )
}
