import React from 'react'
import { useHistory } from "react-router-dom"

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Project'
import { MediaContext } from 'App/pages/Project/Media'

import { Button, ConfirmButton, OptionButtonWrapper, OptionButtonGroup, OptionButton, Box, LabeledIcon, Toggle } from 'shared/components/Button'
import { IconBarcodeScan, IconHighlighter, IconTrash, IconUndo } from 'shared/components/Icon'
import { Listener } from 'shared/components/Spinner'

import { SheetTabBody, SheetTitle } from '../../Sheet'

import { callHighlightDetection, callRenderProject, isPending } from 'shared/hooks/Api'

export default function SheetHighlight(props) {
  const { project, projectFunctions, options } = React.useContext(ProjectContext);
  const { media } = React.useContext(MediaContext);
  const [creating, setCreating] = React.useState(isPending("callHighlightDetection"));

  const history = useHistory();

  const startRender = () => {
    const highlight = project.highlight;

    const data = {
        audio: {
            normalize: true,
            volume: 1
        },
        subtitles: {
            burn: false
        },
        logo: {
            burn: false
        },
        resolutions: {
            lq: false,
            sq: false,
            hq: false
        },
        highlight: {
          start: (highlight && project.highlight) ? project.highlight.start : -1,
          end: (highlight && project.highlight) ? project.highlight.end : -1
        },
        thumbnails: [],
        options: {
          email: false
        }
      }

      callRenderProject(project.name, data, () => {
          setTimeout(() => {
              history.push('/download/' + project.name ?? '');
          }, 100);
      });
  }

  const create = () => {
    if (media == null || media.length <= 0) {
      return;
    }

    setCreating(true);

    callHighlightDetection(project.name, (data) => {
      projectFunctions.createHighlightFrame(data.from, data.to);
      setCreating(false);

      media[0].currentTime = data.from;
      media[0].play();
    });
  }

  if (creating) {
    return (
        <>
            <SheetTabBody>
                <div className={css.placeholder}>
                    <Listener idle={false} height={50} width={50} />
                    <span><Message id="status.highlight.process" /></span>
                    <span><Message id="status.highlight.process.description" /></span>
                </div>
            </SheetTabBody>
        </>
    );
  }

  var highlight = project.highlight;
  if (highlight == null) highlight = null;
  if (highlight.start == null || highlight.end == null) highlight =  null;
  if (highlight.end <= highlight.start) highlight =  null;

  if (highlight == null) {
    return (
        <>
            <SheetTabBody>
              <OptionButtonWrapper>
              <OptionButtonGroup>
                  <OptionButton optionStyle='recommended' handleClick={() => create() }>
                      <IconBarcodeScan width={50} height={50} />
                      <span><Message id="box.highlightCreate"/></span>
                      <span><Message id="box.highlightCreate.description"/></span>
                  </OptionButton>
              </OptionButtonGroup>
                <OptionButtonGroup>
                    <OptionButton optionStyle='recommended' handleClick={() => projectFunctions.createHighlight(media[0]) }>
                        <IconHighlighter width={50} height={50} />
                        <span><Message id="box.highlightGenerate"/></span>
                        <span><Message id="box.highlightGenerate.description"/></span>
                    </OptionButton>
                </OptionButtonGroup>
              </OptionButtonWrapper>
            </SheetTabBody>
        </>
    );
  }

    return (
        <>
          <SheetTabBody>
            <ConfirmButton text={<Message id="button.deleteHighlight"/>} handleClick={() => { projectFunctions.deleteHighlight() }}>
                <IconTrash />
            </ConfirmButton>
            <Button buttonStyle="secondary" text={<Message id="button.createHighlight"/>} handleClick={() => { startRender() }}>
                <Message id="button.createHighlight" />
                <IconHighlighter />
            </Button>
            <SheetTitle><span><Message id="menu.highlight"/></span></SheetTitle>
            <Box>
                <LabeledIcon iconStyle="gray" icon={<IconUndo />}>
                    <span><Message id="toggle.loopHighlight" /></span>
                </LabeledIcon>
                <Toggle disabled={false} active={options.loopHighlight} handleClick={() => { projectFunctions.setOptions({loopHighlight: !options.loopHighlight}) }}></Toggle>
            </Box>
          </SheetTabBody>
        </>
    );
}
