import React from 'react'
import ReactDom from 'react-dom'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'

import { IconNotificationDanger, IconNotificationInfo, IconNotificationWarning, IconNotificationSuccess } from 'shared/components/Icon'

// Set to a function that can be handled by the notification component
var callback = null;

export const addNotification = (message = '', type = 'info') => {
  if (message == null) return;
  callback(message, type)
}

export const generateNotificationId = () => {
    return Math.random().toString(16).slice(-12)
}

export default class Notifications extends React.Component {
    constructor(props) {
        super(props);

        // State of the project
        this.state = {
            notifications: []
        }

        this.deleteNotification = (uuid) => {
            const notifications = [...this.state.notifications];

            for (let i = notifications.length - 1; i >= 0; i--) {
                if (notifications[i].uuid === uuid) {
                    clearTimeout(notifications[i].timeout);
                    notifications.splice(i, 1);
                }
            }

            this.setState({notifications: notifications});
        }

        this.addNotification = (message, type = 'info') => {
            const notifications = [...this.state.notifications];

            for (let i = notifications.length - 1; i >= 0; i--) {
                if (notifications[i].type === type && notifications[i].message === message) {
                    clearTimeout(notifications[i].timeout);
                    notifications[i].timeout = setTimeout(() => {
                        this.deleteNotification(notifications[i].uuid);
                    }, 5000);
                    this.setState({notifications: notifications});
                    return null;
                }
            }

            const uuid = generateNotificationId();

            const timeout = setTimeout(() => {
                this.deleteNotification(uuid);
            }, 5000);

            notifications.push({
                type: type,
                message: message,
                uuid: uuid,
                timeout: timeout
            });

            this.setState({notifications: notifications});

            return uuid;
        }
    }

    componentDidMount() {
        callback = (message, type) => {
            const uuid = this.addNotification(message, type);

            if (uuid != null) {
                setTimeout(() => {
                    this.deleteNotification(uuid);
                }, 5000);
            }
        }
    }

    render() {
        const list = this.state.notifications.map((notification, index) => {
            switch (notification.type) {
                case 'success':
                    return <Success key={notification.uuid} {...notification} index={index} deleteNotification={this.deleteNotification}/>;
                case 'warning':
                    return <Warning key={notification.uuid} {...notification} index={index} deleteNotification={this.deleteNotification}/>;
                case 'danger':
                    return <Danger key={notification.uuid} {...notification} index={index} deleteNotification={this.deleteNotification}/>;
                default:
                    return <Info key={notification.uuid} {...notification} index={index} deleteNotification={this.deleteNotification}/>;
            }
        })

        return ReactDom.createPortal(
            <>
                <div className={css.notifications}>
                    {list}
                </div>
            </>,
            document.getElementById('root')
        )
    }
}

export const Info = React.memo((props) => {
    return (
        <div className={css.toast + ' ' + css.toastInfo} style={{top: props.index * 50}}>
            <div className={css.toastContainer}>
                <IconNotificationInfo />
            </div>
            <div className={css.toastMessage}><Message id={props.message} /></div>
            <div className={css.toastButton} onClick={() => props.deleteNotification(props.uuid)}><Message id="button.close" /></div>
        </div>
    );
});

export const Success = React.memo((props) => {
    return (
        <div className={css.toast + ' ' + css.toastSuccess} style={{top: props.index * 50}}>
            <div className={css.toastContainer}>
                <IconNotificationSuccess />
            </div>
            <div className={css.toastMessage}><Message id={props.message} /></div>
            <div className={css.toastButton} onClick={() => props.deleteNotification(props.uuid)}><Message id="button.close" /></div>
        </div>
    );
});


export const Warning = React.memo((props) => {
    return (
        <div className={css.toast + ' ' + css.toastWarning} style={{top: props.index * 50}}>
            <div className={css.toastContainer}>
                <IconNotificationWarning />
            </div>
            <div className={css.toastMessage}><Message id={props.message} /></div>
            <div className={css.toastButton} onClick={() => props.deleteNotification(props.uuid)}><Message id="button.close" /></div>
        </div>
    );
});

export const Danger = React.memo((props) => {
    return (
        <div className={css.toast + ' ' + css.toastDanger} style={{top: props.index * 50}}>
            <div className={css.toastContainer}>
                <IconNotificationDanger />
            </div>
            <div className={css.toastMessage}><Message id={props.message} /></div>
            <div className={css.toastButton} onClick={() => props.deleteNotification(props.uuid)}><Message id="button.close" /></div>
        </div>
    );
});
