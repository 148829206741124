import React from 'react'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'

import { Collapse } from 'shared/components/Button';
import { IconPause, IconPlay, IconDownload, IconLink, IconNotificationSuccess } from 'shared/components/Icon'
import { addNotification } from 'shared/components/Notification'

import Lightroom from 'shared/components/Lightroom'

import config from 'shared/constants/config'

export const SidebarLink = (props) => {
    const [lightroom, setLightroom] = React.useState(false);
    const params = props.url.split('/');
    const name = params.length > 0 ? params[params.length - 1] : props.url;
    const url = config.urls.cdn + "/load/download/" + name;
    const active = (props.playback && props.playback === name);

    const copyUrl = () => {
        let textArea = document.createElement("textarea");
        textArea.value = url;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        addNotification("notification.linkCopy");
        return new Promise((res, rej) => {
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }

    const downloadVideo = () => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function() {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement('a');
            tag.href = imageUrl;
            tag.target = '_blank';
            tag.download = name + '.' + (props.loadVideo ? 'mp4' : 'jpg');
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        };
        xhr.onerror = err => {
            addNotification("notification.downloadError");
        };
        xhr.send();
    }

    const marked = props.downloadLinks.includes(url);
    const markDownloadLink = () => {
        const urls = [...props.downloadLinks];

        if (marked) {
            urls.splice(urls.indexOf(url), 1);
        } else {
            urls.push(url);
        }

        props.setDownloadLinks(urls);
    }

    return (
        <>
            <Lightroom src={url} open={lightroom} setOpen={setLightroom}/>
            <div className={css.link + ' ' + (marked ? css.marked : css.unmarked)}>
                <div className={css.linkInfo}>
                    {props.loadVideo ?
                        <div className={css.linkPlay + (props.project.status === "RENDERED" ? (' ' + css.active) : '')} onClick={() => props.loadVideo(props.url)}>
                            {active ? <IconPlay width={15} height={15} /> : <IconPause width={15} height={15} />}
                        </div>
                        :
                        <div className={css.linkImage} style={{ backgroundImage: ("url(" + url + ")") }} onClick={() => setLightroom(true) }></div>
                    }
                    <div className={css.linkDetails}>
                        <div className={css.linkName}>
                            <Badge name={name}/>
                            <span>{name}</span>
                        </div>
                    </div>
                </div>
                <Collapse active={false} title={<div className={css.markWrapper} onClick={markDownloadLink}><div className={css.mark}><IconNotificationSuccess width={10} height={10} /></div><span><Message id={props.loadVideo ? "format.video" : "format.image"} /></span></div>}>
                    <div className={css.linkButtons}>
                        <button className="secondary" onClick={downloadVideo}>
                            <span><Message id="button.download" /></span>
                            <IconDownload />
                        </button>
                        <button className="secondary" onClick={copyUrl}>
                            <span><Message id="button.copyLink" /></span>
                            <IconLink />
                        </button>
                    </div>
                </Collapse>
            </div>
        </>
    );
}

export function Badge({ name }) {
    if (name.indexOf('_lq_') !== -1) return (<div className={css.badge}><span>426x240</span><span>LQ</span></div>);
    if (name.indexOf('_sd_') !== -1) return (<div className={css.badge}><span>640x360</span><span>SD</span></div>);
    if (name.indexOf('_hd_') !== -1) return (<div className={css.badge}><span>1280x720</span><span>HD</span></div>);
    if (name.indexOf('_sm_') !== -1) return (<div className={css.badge}><span>1920x1080</span><span>HD</span><span>LOGO</span></div>);
    if (name.indexOf('_st_') !== -1) return (<div className={css.badge}><span>1920x1080</span><span>HD</span><span>LOGO</span><span>SUBTITLE</span></div>);
    return (<div className={css.badge}></div>);
}
