import React from 'react'

import css from './index.module.css'

import { Message } from 'shared/hooks/Translation'
import { ProjectContext } from 'App/pages/Project'
import { MediaContext } from 'App/pages/Project/Media'

import { IconTrash, IconPlus } from 'shared/components/Icon'

import { SheetTabBody } from '../../Sheet'

export default function SheetThumbnails(props) {
    const { projectFunctions } = React.useContext(ProjectContext);

    return (
        <>
            <SheetTabBody>
                <div className={css.thumbnails}>
                    <Thumbnails {...props} />
                </div>
                <button className="secondary" onClick={() => projectFunctions.addThumbnail()}>
                    <span><Message id="button.thumbnailCreate" /></span>
                    <IconPlus />
                </button>
            </SheetTabBody>
        </>
    );
}

export const Thumbnails = React.memo((props) => {
    const { project } = React.useContext(ProjectContext);

    const thumbnails = project.thumbnails.map((thumbnail, index) =>
        thumbnail ? <Thumbnail key={index} index={index} thumbnail={thumbnail} /> : null
    );

    return (
        <>
            {thumbnails}
        </>
    );
});

export const Thumbnail = (props) => {
    const { projectFunctions } = React.useContext(ProjectContext);
    const { media } = React.useContext(MediaContext);

    const click = (time) => {
        if (media && media.length > 0) {
            media[0].pause();
            media[0].currentTime = props.thumbnail.time;
        }
    }

    return (
        <div className={css.thumbnail}>
            <div className={css.thumbnailGroup}>
                <div onClick={click} className={css.thumbnailImage} style={{ backgroundImage: ("url(" + props.thumbnail.image + ")")}}></div>
                <input type="number" step="0.1" value={props.thumbnail.time} onChange={(e) => { projectFunctions.modifyThumbnail(props.index, {time: parseFloat(e.target.value) }) }}></input>
            </div>
            <div className={css.thumbnailGroup}>
                <button onClick={() => projectFunctions.removeThumbnail(props.index)}>
                    <IconTrash />
                </button>
            </div>
        </div>
    )
}
