import React from 'react'

import css from './index.module.css'

import { ProjectContext } from 'App/pages/Project'
import { MediaContext } from 'App/pages/Project/Media'
import { SizeContext, WidthContext } from 'App/pages/Project/Timeline'

import { ELEMENTS } from 'shared/constants/nav'

const TimelineIndicator = React.memo((props) => {
    const { size } = React.useContext(SizeContext);
    const { width } = React.useContext(WidthContext);
    const { project, options } = React.useContext(ProjectContext);
    const { media, mediaFunctions } = React.useContext(MediaContext);

    const clickTimeline = (e) => {
        if (options.loopHighlight && project.highlight && project.highlight.start != null && project.highlight.end != null && project.highlight.end > project.highlight.start && media[0] && !media[0].paused) {
            mediaFunctions.time(Math.min(project.highlight.end, Math.max(project.highlight.start, ((e.clientX - (e.target.getBoundingClientRect().left)) / size))));
        } else {
            mediaFunctions.time(((e.clientX - (e.target.getBoundingClientRect().left)) / size));
        }
    }

    const list = [];

    if (media && media.length > 0) {
        for (let i = 0; i < media[0].duration; i++) list.push(i);
    }

    var steps = 10;

    if (size >= 175) {
        steps = 1;
    } else if (size >= 125) {
        steps = 2;
    } else if (size >= 50) {
        steps = 5;
    }

    const indicatorList = list.map((t, index) =>
        <div className={css.indicator} key={index} style={{ width: (size + "px") }}><span>{((index + 1 + steps)  % steps) === 0 ? (index + 1) : ('')}</span></div>
    );

    return (
        <div className={css.indicatorsWrapper} style={{ width: width + "px" }} onClick={clickTimeline}>
            <div className={css.indicators}>
                {indicatorList}
            </div>
            <Highlight navigation={props.navigation} setNavigation={props.setNavigation} />
        </div>
    );
});

const Highlight = React.memo((props) => {
  const { project, options } = React.useContext(ProjectContext);
  const { size } = React.useContext(SizeContext);

  const highlight = project.highlight;
  if (highlight == null) return null;
  if (options.mode === 'DOCUMENT') return null;

  return(
    <div className={css.highlight + (props.navigation === ELEMENTS.HIGHLIGHT ? (' ' + css.highlightActive) : (''))} style={{ left: ((highlight.start) * size + "px"), width: ((highlight.end - highlight.start) * size + "px") }}></div>
  );
});

export default TimelineIndicator;
